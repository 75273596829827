const JiraTaskIcon = ({size = 32, className = 'flex item-center w-5'}) => (
    <svg
        className={className}
        fill="none"
        viewBox={`0 0 ${size} ${size}`}
        focusable="false"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill="#357DE8"
            d="M7.967 21.323H5.748C2.401 21.323 0 19.273 0 16.271h11.933c.618 0 1.018.44 1.018 1.062V29.34c-2.983 0-4.984-2.416-4.984-5.784zm5.894-5.967h-2.22c-3.346 0-5.747-2.013-5.747-5.015h11.932c.618 0 1.055.402 1.055 1.025v12.007c-2.983 0-5.02-2.416-5.02-5.784zm5.93-5.93h-2.22c-3.347 0-5.748-2.05-5.748-5.052h11.933c.618 0 1.019.439 1.019 1.025v12.007c-2.983 0-4.984-2.416-4.984-5.784z"
        ></path>
    </svg>
);

export default JiraTaskIcon;
