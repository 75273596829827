import {get, post, remove, put, upload} from './api';
import {
    Project,
    CreateWorkspaceForm,
    Field,
    Bucket,
    BucketFormEdit,
    BucketFormCreate,
    Task,
    TaskForm,
    GridColumn,
    ProjectSetting,
} from './NetTypes';

export const getProjects = async (): Promise<Project[]> => {
    const response = await get({path: `/workspaces`});
    return response.data;
};

export const getMembers = async (projectId: string): Promise<ProjectSetting[]> => {
    const response = await get({path: `/workspaces/${projectId}/members`});
    return response.data;
};

export const getProject = async (projectId: string): Promise<any> => {
    const response = await get({path: `/workspaces/${projectId}`});
    return response.data;
};

export const deleteProject = async (projectId: string): Promise<any> => {
    const response = await remove({path: `/workspaces/${projectId}`});
    return response.data;
};

export const createProject = async (project: CreateWorkspaceForm): Promise<Project> => {
    const response = await post({path: `/workspaces`, data: project});
    return response.data;
};

export const updateProject = async (project: any): Promise<Project> => {
    console.log('project', project);
    const response = await put({path: `/workspaces/${project.id}`, data: project});
    return response.data;
};

export const getBuckets = async (projectId: string, type: string): Promise<any> => {
    const response = await get({path: `/workspaces/${projectId}/buckets?type=${type}`});
    return {buckets: response.data, type, projectId};
};

export const getFields = async (projectId: string): Promise<Field[]> => {
    const response = await get({path: `/workspaces/${projectId}/fields`});
    return response.data;
};

export const saveField = (projectId: string, field: any): Promise<any> => {
    return post({path: `/workspaces/${projectId}/fields`, data: field});
};

export const deleteField = async (projectId: string, fieldId: string): Promise<Field> => {
    const response = await remove({path: `/workspaces/${projectId}/fields/${fieldId}`});
    return response.data;
};

export const search = async ({
    projectId = 'all',
    page = 0,
    pageSize = 50,
    filters,
    view = 'GRID',
    types = ['SALE'],
    fields = [],
    sorts = [],
}: any): Promise<any> => {
    const data = {
        projectId,
        page,
        pageSize,
        filters,
        view,
        types,
        fields,
        sorts,
    };
    const response = await post({
        path: `/search`,
        data,
    });
    return response.data;
};

export const saveBucket = async (bucket: BucketFormCreate | BucketFormEdit): Promise<any> => {
    if (bucket.setUp) {
        return Promise.resolve({
            id: Math.random().toString(36).substring(7),
            ...bucket,
        });
    }
    const response = await post({path: `/buckets`, data: bucket});
    return response.data;
};

export const deleteBucket = async (
    bucketId: string,
    type: string,
    setUp?: boolean
): Promise<any> => {
    if (setUp) {
        return Promise.resolve({bucketId, type, setUp});
    }
    await remove({path: `/buckets/${bucketId}`});

    return Promise.resolve({bucketId, type, setUp});
};

export const changeBucketOrder = async ({
    projectId,
    bucketId,
    direction,
    type,
    setUp,
}): Promise<any> => {
    if (setUp) {
        return Promise.resolve({projectId, bucketId, direction, type, setUp});
    }
    await put({
        path: `/${projectId}/buckets/${bucketId}/${direction}?type=${type}`,
    });
    return Promise.resolve({projectId, bucketId, direction, type, setUp});
};

export const createItem = async (task: TaskForm): Promise<Task> => {
    const response = await post({path: `/items`, data: task});
    return response.data;
};

export const getItem = async (taskId: string): Promise<Task> => {
    const response = await get({path: `/items/${taskId}`});
    return response.data;
};

export const searchActivities = async ({
    itemId,
    scope,
    pageSize = 50,
    status = '',
    sortBy = 'event.createdAt',
    sortDirection = 'DESC',
    eventActions = '',
    days = '',
    users = '',
}: any): Promise<any> => {
    const response = await get({
        path: `/items/${itemId}/search-activities?scope=${scope}&pageSize=${pageSize}&status=${status}&sortBy=${sortBy}&sortDirection=${sortDirection}&eventActions=${eventActions}&days=${days}&users=${users}`,
    });
    return response.data;
};

export const countActivities = async ({
    itemId,
    scope,
    status = '',
    eventActions = '',
    days = '',
    users = '',
}: any): Promise<any> => {
    const response = await get({
        path: `/items/${itemId}/count-activities?scope=${scope}&status=${status}&eventActions=${eventActions}&days=${days}&users=${users}`,
    });
    return response.data;
};

export const deleteItemLog = async (taskId: string, logId: string): Promise<any> => {
    return remove({path: `/items/${taskId}/logs/${logId}`});
};

export const createItemLogActivity = async (taskId: string, data): Promise<any> => {
    const response = await post({path: `/items/${taskId}/activities/${data.action}`, data});
    return response.data;
};

export const markAsDoneActivity = async (
    taskId: string,
    activityId: string,
    status
): Promise<any> => {
    return put({path: `/items/${taskId}/activities/${activityId}/mark-as-done/${status}`});
};

export const uploadFileLogActivity = async (taskId: string, file): Promise<any> => {
    const uploadUrl = `/items/${taskId}/uploadFile`;
    const data = new FormData();
    data.append('file', file);
    const response = await upload({path: uploadUrl, data});
    return response.data;
};

export const downloadFileLogActivity = async (taskId: string, fileName: string): Promise<any> => {
    const downloadUrl = `/items/${taskId}/downloadFile/${fileName}`;
    const response = await get({path: downloadUrl});
    return response.data;
};

export const updateTaskField = async (
    itemId: string,
    fieldId: string,
    fieldValue: any,
    itemType?: string,
    view?: string
): Promise<any> => {
    const data = {
        value: fieldValue,
    };
    let response = {data: {}};
    if (itemType == 'ACTIVITY') {
        response = await put({path: `/activities/${itemId}/${fieldId}/update-field`, data});
    } else {
        response = await put({path: `/items/${itemId}/${fieldId}/update-field`, data});
    }
    return {taskId: itemId, itemType, fieldId, fieldValue, view, item: response.data};
};

export const deleteTask = async (taskId: string): Promise<any> => {
    const response = await remove({path: `/items/${taskId}`});
    return response.data;
};

export const bulkDeleteItems = async ({itemIds, type}): Promise<any> => {
    const response = await remove({path: `/items/bulk-delete`, data: {itemIds, type}});
    return response.data;
};

export async function searchItems(projectId: string, query = '', type: string) {
    const {data = []} = await get({
        path: `/workspaces/${projectId}/items/search?query=${query}&type=${type}`,
    });
    return Promise.resolve(data);
}

export async function searchIssues(query = '', currentJQL) {
    const {data = {}} = await get({
        path: `/jira/issues?query=${query}&currentJQL=${currentJQL}`,
    });
    return Promise.resolve(data.sections);
}
