import {ActionTypes} from '../constants/action-types';
import {createAction} from 'redux-actions';
import {
    createProject,
    getProjects,
    getProject,
    getFields,
    updateProject,
    getMembers,
    deleteProject,
} from '../api/ProjectApi';

const api = {
    [ActionTypes.createProject]: (project) => createProject(project),
    [ActionTypes.deleteProject]: (projectId) => deleteProject(projectId),
    [ActionTypes.updateProject]: (project) => updateProject(project),
    [ActionTypes.updateProjectField]: (fieldId, fieldValue) => ({fieldId, fieldValue}),
    [ActionTypes.getProjects]: () => getProjects(),
    [ActionTypes.getProject]: (projectId) => getProject(projectId),
    [ActionTypes.getFields]: (projectId) => getFields(projectId),
    [ActionTypes.getMembers]: (projectId) => getMembers(projectId),
};

const actions: any = {...api};

Object.keys(actions).forEach((type) => {
    actions[type] = createAction(type, actions[type]);
});

export default actions;
