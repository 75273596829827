import {get, post} from './api';

export async function saveDashboard(workspaceId: string, dashboard: any) {
    const {data = {}} = await post({
        path: `/workspaces/${workspaceId}/dashboard`,
        data: dashboard,
    });
    return data;
}

export async function getDashboard(workspaceId: string) {
    const {data = {}} = await get({path: `/workspaces/${workspaceId}/dashboard`});
    return data;
}

export async function getWorkspaceIds(issueKey: string) {
    const {data = []} = await get({path: `/workspaces/issue/${issueKey}`});
    return data;
}
