import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import store from '../store/store';
import '../../tailwind.css';
import WebsiteAppLayout from './index';

const root = createRoot(document.getElementById('app-container')!);
root.render(
    <Provider store={store}>
        <WebsiteAppLayout />
    </Provider>
);
