import {ActionTypes, DialogTypes, ContentTypes} from '../constants/action-types';
import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';

export const initialState = fromJS({
    pending: true,
    showGridDialog: {
        type: DialogTypes.createCustomFieldDialog,
        show: false,
        id: null,
    },
    newTasKId: '',
    editColumn: {},
    viewSetting: {
        [ContentTypes.TASK]: {
            columns: [],
        },
        [ContentTypes.SALE]: {
            columns: [],
        },
        [ContentTypes.CONTACT]: {
            columns: [],
        },
        [ContentTypes.COMPANY]: {
            columns: [],
        },
        [ContentTypes.TRACKING]: {
            columns: [],
        },
        [ContentTypes.ACTIVITY]: {
            columns: [],
        },
    },
});

const handleResponse = (state, {payload, error, pending}) => {
    if (error) {
        return state.merge({error, pending});
    }
    if (pending) {
        return state.merge({pending});
    }
    if (payload == null) {
        return state.merge({pending, error});
    }
    const {viewSetting} = payload;

    const newState = {
        ...state.toJS(),
        viewSetting: {
            ...state.get('viewSetting').toJS(),
            ...viewSetting,
        },
    };
    return fromJS(newState);
};

export const actions = {
    [ActionTypes.getProject]: {
        next(state, {payload, error, pending}) {
            return handleResponse(state, {payload, error, pending});
        },
    },
    [ActionTypes.changeColumnOrder]: {
        next(state, {payload}) {
            const {columns, contentType} = payload;
            const newColumns = state.setIn(['viewSetting', contentType, 'columns'], columns);
            const newState = {
                ...newColumns.toJS(),
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.setColumn]: {
        next(state, {payload}) {
            const {column, contentType} = payload;
            const columnIndex = state
                .getIn(['viewSetting', contentType, 'columns'])
                .findIndex((col) => col.get('selector') === column.selector);
            const gridViewSetting = state
                .getIn(['viewSetting'])
                .setIn([contentType, 'columns', columnIndex], fromJS(column));
            const newState = {
                ...state.toJS(),
                viewSetting: gridViewSetting.toJS(),
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.showGridDialog]: {
        next(state, {payload}) {
            const {id, dialogType, contentType} = payload;
            const editColumn = state
                .getIn(['viewSetting', contentType, 'columns'])
                .toJS()
                .find((column) => column.selector === id && column.formType === contentType) || {
                formType: contentType,
            };
            const newState = {
                ...state.toJS(),
                showGridDialog: {
                    show: true,
                    type: dialogType,
                    id: id,
                },
                editColumn,
            };
            return fromJS(newState);
        },
    },

    [ActionTypes.hideTaskDialog]: {
        next(state, {dialogType}) {
            const newState = {
                ...state.toJS(),
                showGridDialog: {
                    type: dialogType,
                    show: false,
                },
            };
            return fromJS(newState);
        },
    },

    [ActionTypes.setNewTaskId]: {
        next(state, {payload}) {
            const {id} = payload;
            const newState = {
                ...state.toJS(),
                newTasKId: id,
            };
            return fromJS(newState);
        },
    },
};

export const grid = handleActions(actions, initialState);
