import {ActionTypes, ActivityTypes} from '../constants/action-types';
import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';
import {getUserTimeZone} from '../api/api';
import {format} from 'date-fns';
import {buildTimeValues} from '../activities/DayView';
const today = new Date();
const todayDateString = format(today, 'yyyy-MM-dd');
const timelines = fromJS(buildTimeValues()).sortBy((item) => item.get('distanceToNow'));
const sortedTimelines = timelines.filter((item) => item.get('distanceToNow') >= 0);

const defaultActivityData = {
    activityType: ActivityTypes.meeting,
    assignees: '',
    summary: 'Meeting',
    attendees: '',
    organizer: '',
    location: '',
    description: '',
    privateDescription: '',
    calendarId: 'primary',
    start: todayDateString,
    startTime: sortedTimelines.getIn([0, 'value']),
    end: todayDateString,
    endTime: sortedTimelines.getIn([2, 'value']),
    timeZone: getUserTimeZone(),
    reminderEnable: false,
    hasGoogleCalendar: false,
    hasGmail: false,
    tracking: true,
    emailReminderBeforeInMinutes: 30,
    popupReminderBeforeInMinutes: 10,
    item: {},
};

const initialState = fromJS({
    show: false,
    activities: [],
    myOverDueActivityNum: 0,
    data: defaultActivityData,
    activityChangedData: Date.now(),
});

export const actions = {
    [ActionTypes.searchActivities]: {
        next(state, data) {
            const {payload, error, pending} = data;
            if (error) {
                return state.merge({error, pending});
            }
            if (pending) {
                return state.merge({pending});
            }
            if (payload === null) {
                return state;
            }
            const newState = {
                ...state.toJS(),
                activities: payload,
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.countMyDueOverActivities]: {
        next(state, data) {
            const {payload, error, pending} = data;
            if (error) {
                return state.merge({error, pending});
            }
            if (pending) {
                return state.merge({pending});
            }
            if (payload === null) {
                return state;
            }
            const newState = {
                ...state.toJS(),
                myOverDueActivityNum: payload,
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.createActivity]: {
        next(state, data) {
            const {payload, error, pending} = data;
            if (error) {
                return state.merge({error, pending});
            }
            if (pending) {
                return state.merge({pending});
            }
            if (payload === null) {
                return state;
            }
            const newState = {
                ...state.toJS(),
                activityChangedData: Date.now(),
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.changeActivityData]: {
        next(state, {payload}) {
            const {fieldId, fieldValue} = payload;
            const newState = {
                ...state.toJS(),
                data: {
                    ...state.get('data').toJS(),
                    [fieldId]: fieldValue,
                },
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.showActivityDialog]: {
        next(state, {payload}) {
            const {data} = payload;
            const newState = {
                ...state.toJS(),
                data: {
                    ...state.get('data').toJS(),
                    ...data,
                },
                show: true,
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.hideActivityDialog]: {
        next(state, {}) {
            const newState = {
                ...state.toJS(),
                show: false,
                data: defaultActivityData,
            };
            return fromJS(newState);
        },
    },
};

export const activityReducer = handleActions(actions, initialState);
