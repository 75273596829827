import styled, {css} from 'styled-components';
import {useSelector, useDispatch} from 'react-redux';
import {useState} from 'react';
import CustomSelect from './CustomSelect';
import {formatISO} from 'date-fns';
import {fromJS} from 'immutable';
import SecondaryButton from '../../../components/SecondaryButton';
import PrimaryButton from '../../../components/PrimaryButton';
import {useCurrentSize} from '../../../CurrentSize';
import LabelPicker from './LabelPicker';
import {XMarkIcon} from '@heroicons/react/24/outline';
import AssignTo from '../AssignTo';

const InputWrapper = styled.div`
    width: 100%;
`;

const TitleInput = styled.div`
    font-size: 14px;
    font-weight: 600;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #323130;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${({required}: any) =>
        required &&
        css`
            &::before {
                content: '*';
                color: red;
                margin-right: 2px;
            }
        `}
`;

const BulkEditForm = (props: any) => {
    const {
        onSave = () => {},
        onClose = () => {},
        projectId,
        defaultItemData = {},
        contentType,
    } = props;

    const selectionIds = useSelector((state: any) => state.taskReducer.getIn(['selectionIds']));
    const isOpen = selectionIds.size > 0;

    const [itemData, setItemData] = useState(
        fromJS({
            ...defaultItemData,
            start: {
                value: formatISO(new Date()),
            },
        })
    );

    const dispatch = useDispatch();

    const saleStatus = useSelector((state: any) => state.ruleReducer.getIn(['status'])).toJS();
    const saleSources = useSelector((state: any) => state.ruleReducer.getIn(['source'])).toJS();
    const saleStages = useSelector((state: any) => state.ruleReducer.getIn(['stage'])).toJS();
    const industries = useSelector((state: any) => state.ruleReducer.getIn(['industry'])).toJS();
    const salutations = useSelector((state: any) => state.ruleReducer.getIn(['salutation'])).toJS();
    const buckets = useSelector((state: any) => state.bucketReducer.get(contentType)) || fromJS([]);

    const bucketOptions = buckets.map((bucket: any) => ({
        value: bucket.get('id'),
        label: bucket.get('name'),
    }));
    const project = useSelector((state: any) => state.projectReducer.getIn(['project']));

    const handleUpdateField = async (fieldId: string, fieldValue: any) => {
        const newItemData = {
            ...itemData.toJS(),
            [fieldId]: {
                value: fieldValue,
            },
        };
        setItemData(fromJS(newItemData));
    };

    const handleCreateNewItem = async () => {
        const newItemData = {
            data: itemData,
            projectId: project.get('id'),
            treeId: project.get('treeId'),
            type: contentType,
        };

        setItemData(fromJS({}));
        onSave();
    };

    const getTaskFieldValue = (fieldId: string) => {
        return itemData.getIn([fieldId, 'value']);
    };

    const renderSaleFieldContext = () => {
        return (
            <>
                <div className={'w-full flex'}>
                    <div className={'w-full text-lg'}>{`Bulk edit ${selectionIds.size} items`}</div>
                    <XMarkIcon className={'w-6 justify-end cursor-pointer'} onClick={onClose} />
                </div>
                <InputWrapper>
                    <TitleInput>Owners</TitleInput>
                    <AssignTo
                        assignees={getTaskFieldValue('assignees')}
                        sizeIcon={20}
                        label={true}
                        border={true}
                        onChange={(assignees: any) => {
                            if (assignees) {
                                const assigneeIds = assignees.map(
                                    (assignee: any) => assignee.accountId
                                );
                                handleUpdateField('assignees', assigneeIds.join(','));
                            }
                        }}
                    />
                </InputWrapper>
                <InputWrapper>
                    <TitleInput>Labels</TitleInput>
                    <LabelPicker
                        type={contentType}
                        projectId={projectId}
                        labels={itemData.getIn(['data', 'labels', 'value'])}
                        onChange={(labels) => {
                            handleUpdateField('labels', labels);
                        }}
                    />
                </InputWrapper>
                <InputWrapper>
                    <TitleInput>Status</TitleInput>
                    <CustomSelect
                        options={saleStatus}
                        value={getTaskFieldValue('status')}
                        onChange={(value) => {
                            handleUpdateField('status', value);
                        }}
                    />
                </InputWrapper>
                <InputWrapper>
                    <TitleInput>Stage</TitleInput>
                    <CustomSelect
                        options={saleStages}
                        value={getTaskFieldValue('stage')}
                        onChange={(value) => {
                            handleUpdateField('stage', value);
                        }}
                    />
                </InputWrapper>

                <InputWrapper>
                    <TitleInput>Sale funnels</TitleInput>
                    <CustomSelect
                        options={bucketOptions}
                        value={getTaskFieldValue('bucketId')}
                        onChange={(value) => handleUpdateField('bucketId', value)}
                    />
                </InputWrapper>
            </>
        );
    };

    const renderActions = () => {
        return (
            <div className={'flex space-x-2 justify-start pt-4'}>
                <PrimaryButton label={'Next'} onClick={handleCreateNewItem} />
                <SecondaryButton label={'Cancel'} onClick={onClose} />
            </div>
        );
    };
    const currentSize = useCurrentSize();

    return (
        <div className={'w-full'}>
            <div className={'overflow-y-auto'} style={{maxHeight: currentSize.height - 100}}>
                {renderSaleFieldContext()}
            </div>
            {renderActions()}
        </div>
    );
};

export default BulkEditForm;
