import {ActionTypes} from '../constants/action-types';
import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';
import {simpleReducer} from './utils';

export const defaultGoal = {
    entityType: 'SALE',
    type: 'ADDED_SALE',
    scope: {
        type: 'Team',
        value: {
            value: '',
            label: '',
        },
    },
    buckets: [],
    frequency: 'Monthly',
    trackingMetric: 'Count',
    trackingFrom: '',
    trackingTo: '',
    trackingGoal: {
        key: '',
        value: 0.0,
    },
    subGoalEnabled: false,
    subGoals: [],
};

const initialState = fromJS({
    goals: [],
    pending: true,
    frequencies: [
        {
            value: 'Daily',
            label: 'Daily',
        },
        {
            value: 'Weekly',
            label: 'Weekly',
        },
        {
            value: 'Monthly',
            label: 'Monthly',
        },
        {
            value: 'Quarterly',
            label: 'Quarterly',
        },
        {
            value: 'Yearly',
            label: 'Yearly',
        },
    ],
    goalTypes: [
        {
            value: 'ADDED_SALE',
            label: 'Added',
            description: 'Based on the number or value of new deals',
        },
        {
            value: 'PROGRESSED_SALE',
            label: 'Progressed',
            description: 'Based on the number or value of deals entering a certain stage',
        },
        {
            value: 'WON_SALE',
            label: 'Won',
            description: 'Based on the number or value of won deals',
        },
    ],
    scopeTypes: [
        {
            value: 'User',
            label: 'User',
        },
        {
            value: 'Team',
            label: 'Team',
        },
        {
            value: 'Account',
            label: 'Account',
        },
    ],
    trackingMetrics: [
        {
            value: 'Value',
            label: 'Value',
        },
        {
            value: 'Count',
            label: 'Count',
        },
    ],
    goal: defaultGoal,
});

export const actions = {
    [ActionTypes.saveGoal]: {
        next(state, {payload, error, pending}) {
            if (error) {
                return state.merge({error, pending});
            }
            if (pending) {
                return state.merge({pending});
            }
            if (payload === null) {
                return state;
            }
            const index = state.get('goals').findIndex((item) => item.get('id') == payload.id);
            if (index >= 0) {
                const goalList = state.get('goals').setIn(index, fromJS(payload));
                const newState = {...state.toJS(), goals: goalList.toJS()};
                return fromJS(newState);
            } else {
                const goalList = [...state.get('goals'), payload];
                const newState = {...state.toJS(), goals: goalList};
                return fromJS(newState);
            }
        },
    },
    [ActionTypes.getGoal]: simpleReducer('goal'),
    [ActionTypes.resetGoalForm]: {
        next(state) {
            const newState = {...state.toJS(), goal: defaultGoal};
            return fromJS(newState);
        },
    },
    [ActionTypes.setCurrentGoal]: {
        next(state, {payload}) {
            const newState = {...state.toJS(), goal: payload};
            return fromJS(newState);
        },
    },
    [ActionTypes.getGoals]: {
        next(state, {payload, error, pending}) {
            if (error) {
                return state.merge({error, pending});
            }
            if (pending) {
                return state.merge({pending});
            }
            if (payload === null) {
                return state;
            }
            const newState = {...state.toJS(), goals: payload};
            return fromJS(newState);
        },
    },
};

export const goal = handleActions(actions, initialState);
