import {put} from './api';
import {GridColumn} from './NetTypes';

export const setGridColumn = async (projectId: string, column: GridColumn): Promise<GridColumn> => {
    const response = await put({path: `/workspaces/${projectId}/grid/column`, data: column});
    return response.data;
};

export const setGridColumnOrder = async (projectId: string, columns: any): Promise<any> => {
    const response = await put({
        path: `/workspaces/${projectId}/grid/columns`,
        data: columns,
    });
    return response.data;
};
