import {ActionTypes} from '../constants/action-types';
import {createAction} from 'redux-actions';
import {
    getUser,
    getCurrentUser,
    activeMember,
    enableAdmin,
    removeMember,
    updateCurrentUserSetting,
} from '../api/UserApi';
import {loginWithJwt} from '../api/SessionApi';

const api = {
    [ActionTypes.loginWithJwt]: (jwt) => loginWithJwt(jwt),
    [ActionTypes.getUser]: (accountId) => getUser(accountId),
    [ActionTypes.updateCurrentUserSetting]: (userSetting) => updateCurrentUserSetting(userSetting),
    [ActionTypes.getCurrentUser]: () => getCurrentUser(),
    [ActionTypes.activeMember]: (accountId) => activeMember(accountId),
    [ActionTypes.enableAdmin]: (accountId) => enableAdmin(accountId),
    [ActionTypes.removeMember]: (accountId) => removeMember(accountId),
    [ActionTypes.getCurrentUser]: () => getCurrentUser(),
    [ActionTypes.setCurrentUser]: (user) => user,
};

const actions: any = {...api};

Object.keys(actions).forEach((type) => {
    actions[type] = createAction(type, actions[type]);
});

export default actions;
