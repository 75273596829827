import React, {useEffect, useState} from 'react';
import 'react-day-picker/dist/style.css';
import styled from 'styled-components';
import {addDays, format, isValid, parseISO} from 'date-fns';
import DropdownLayout from '../../components/DropdownLayout';
import {CalendarDaysIcon, ChevronDownIcon} from '@heroicons/react/24/outline';
import DatePickerField from '../../board/components/TaskDetail/DatePicker';
import {FilterButton} from './FilterPicker';
import PrimaryButton from '../../components/PrimaryButton';
import SecondaryButton from '../../components/SecondaryButton';

type DateRangeProps = {
    onChange: (value: any) => void;
    value: any;
};

const StyledContainer = styled.div<any>`
    .rdp-day {
        padding-bottom: 2px;
    }
    .rdp-day_selected {
        color: white;
        background-color: #bfdbfe;
    }

    .rdp-day_range_end,
    .rdp-day_range_start {
        border-radius: 0% !important;
        background-color: #2563eb;
    }

    .rdp-day_range_middle {
        background-color: #bfdbfe;
        color: #2563eb;
    }
`;

export const getDefaultDateRange = () => {
    const today = Date.now();
    const to = addDays(today, 0);
    const from = addDays(to, -30);
    return {from, to, duration: 0};
};

export const defaultStartFilter = () => {
    const {from, to, duration} = getDefaultDateRange();
    if (duration == 0) {
        return {};
    }
    return {
        start: {
            operator: 'dateRange',
            values: [
                `${format(from, 'yyyy-MM-dd')}T00:00:00`,
                `${format(to, 'yyyy-MM-dd')}T23:59:59`,
                duration,
            ],
        },
    };
};

export const predefinedDates = [
    {value: 0, label: 'All dates'},
    {value: -14, label: 'Last week'},
    {value: -30, label: 'Last month'},
    {value: -90, label: 'Last quarter'},
    {value: -60, label: 'Last 60 days'},
    {value: -120, label: 'Last 120 days'},
    {value: -180, label: 'Last 180 days'},
    {value: 'custom', label: 'Custom date range'},
];

const DateRangePicker = (props: DateRangeProps) => {
    const {onChange, value} = props;

    const [selectedDay, setSelectedDay] = useState<any>({});
    const [show, setShow] = useState<any>(false);

    useEffect(() => {
        setSelectedDay(extractDateRangeFromValue());
    }, [value]);

    const extractDateRangeFromValue = () => {
        const {values = [], duration} = value || {};
        if (duration) {
            const today = new Date();
            return {from: addDays(today, duration), to: today, duration};
        } else if (values.length == 2) {
            const from = new Date(values[0]);
            const to = new Date(values[1]);
            if (isValid(from) && isValid(to)) {
                return {from, to, duration: 0};
            }
        } else {
            return getDefaultDateRange();
        }
    };

    const renderStartDate = () => {
        if (isValid(selectedDay.from) && selectedDay && selectedDay.from) {
            return format(selectedDay.from, 'dd MMM');
        }
        return 'Today';
    };

    const renderEndDate = () => {
        if (isValid(selectedDay.to) && selectedDay && selectedDay.to) {
            return format(selectedDay.to, 'dd MMM yy');
        }
        return '...';
    };

    const handleOnApply = () => {
        setShow(false);
        if (selectedDay.duration == 0) {
            onChange({
                from: '',
                to: '',
                duration: 0,
            });
        } else if (selectedDay.to && selectedDay.from) {
            onChange({
                from: `${format(selectedDay.from, 'yyyy-MM-dd')}T00:00:00`,
                to: `${format(selectedDay.to, 'yyyy-MM-dd')}T23:59:59`,
                duration: selectedDay.duration,
            });
        }
    };

    const handleReset = () => {
        onChange({
            from: '',
            to: '',
            duration: 0,
        });
    };

    const handleSelectPredefinedDate = (durationValue) => {
        if (durationValue == 'custom') {
            setSelectedDay({
                from: undefined,
                to: undefined,
                duration: durationValue,
            });
        } else {
            const today = new Date();
            setSelectedDay({
                from: addDays(today, durationValue),
                to: today,
                duration: durationValue,
            });
        }
    };

    const renderPredefinedDates = () => {
        const duration = selectedDay ? selectedDay.duration : 0;
        return (
            <div className="mt-2 space-y-2">
                {predefinedDates.map((dateItem) => (
                    <div key={dateItem.value} className="flex items-center">
                        <input
                            onClick={() => {
                                handleSelectPredefinedDate(dateItem.value);
                            }}
                            id={dateItem.label}
                            type="radio"
                            checked={dateItem.value === duration}
                            defaultChecked={dateItem.value === 0}
                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                        <label
                            htmlFor={dateItem.label}
                            className="ml-3 block leading-6 text-gray-900"
                        >
                            {dateItem.label}
                        </label>
                    </div>
                ))}
            </div>
        );
    };

    const renderFooter = () => {
        return (
            <div className={'flex w-full justify-end space-x-2 p-2'}>
                <SecondaryButton
                    label="Reset"
                    onClick={handleReset}
                    className={'w-24'}
                    tabIndex={1}
                />
                <PrimaryButton label="Apply" className={'w-24'} onClick={handleOnApply} />
            </div>
        );
    };

    const renderDisplayDate = () => {
        if (value) {
            const {duration = 0} = value;
            if (duration == 'custom') {
                return (
                    <div className={'truncate ...'}>
                        {renderStartDate()} - {renderEndDate()}
                    </div>
                );
            }
        }
        const {duration} = value || {duration: 0};
        const dateData = predefinedDates.find((item) => item.value == duration);
        if (dateData) {
            return <div className={'truncate ...'}>{dateData.label}</div>;
        }
    };

    const renderCustomDateRange = () => {
        const {duration, from, to} = selectedDay || {};
        if (duration == 'custom') {
            return (
                <div className={'p-2 space-x-2 flex w-full min-w-72'}>
                    <DatePickerField
                        placeholder={'YYYY-MM-DD'}
                        selected={from}
                        onChange={(from) => {
                            setSelectedDay({
                                ...selectedDay,
                                from: parseISO(from),
                            });
                        }}
                    />
                    <DatePickerField
                        placeholder={'YYYY-MM-DD'}
                        selected={to}
                        onChange={(to) => {
                            setSelectedDay({
                                ...selectedDay,
                                to: parseISO(to),
                            });
                        }}
                    />
                </div>
            );
        }
        return null;
    };

    const act = (
        <FilterButton
            onClick={() => {
                setShow(true);
            }}
        >
            <>
                <CalendarDaysIcon className={'w-6'} />
                {renderDisplayDate()}
                <ChevronDownIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
            </>
        </FilterButton>
    );
    return (
        <DropdownLayout
            activator={act}
            fullWidth={false}
            open={show}
            positionClassName={'left-0 origin-top-left w-full min-w-72'}
        >
            <StyledContainer mainColor={'#2563eb'} className="flex w-full min-w-72">
                <div className="bg-white w-full">
                    <div className={'space-x-2 px-2 pt-3 flex border-gray-400 border-b-1'}>
                        {renderPredefinedDates()}
                    </div>
                    {renderCustomDateRange()}
                    {renderFooter()}
                </div>
            </StyledContainer>
        </DropdownLayout>
    );
};

export default DateRangePicker;
