import {ActionTypes} from '../constants/action-types';
import {createAction} from 'redux-actions';
import {setGridColumnOrder, setGridColumn} from '../api/LayoutApi';

const api = {
    [ActionTypes.changeColumnOrder]: ({projectId, columns, contentType}) => {
        setGridColumnOrder(projectId, columns);
        return {projectId, columns, contentType};
    },
    [ActionTypes.showHideColumn]: (projectId, column, contentType) => {
        return {projectId, column, contentType};
    },
    [ActionTypes.setColumn]: (projectId, column, contentType) => {
        if (column.width <= 0) {
            column.width = 10;
        }
        if (!column.isResizing) {
            setGridColumn(projectId, column);
        }
        return {projectId, column, contentType};
    },

    [ActionTypes.showGridDialog]: (id, dialogType, contentType) => {
        return {id, dialogType, contentType};
    },
    [ActionTypes.hideTaskDialog]: (dialogType) => ({dialogType}),
    [ActionTypes.setNewTaskId]: (id) => {
        return {id};
    },
};

const actions: any = {...api};

Object.keys(actions).forEach((type) => {
    actions[type] = createAction(type, actions[type]);
});

export default actions;
