import styled from 'styled-components';
import AddFilterIcon from '../icons/AddFilterIcon';
import React from 'react';

export const InfoIconContainer = styled.div`
    .icon-action {
        display: none;
    }
    &:hover .icon-action {
        display: block;
    }
`;

const ActionableField = ({children, showAddFilter = false, onClickAddFilter = () => {}}) => {
    return (
        <InfoIconContainer className={'flex items-center w-full space-x-1 px-2'}>
            {children}
            {showAddFilter && (
                <AddFilterIcon
                    className={'icon-action w-4 text-gray-400 hover:text-blue-600 cursor-pointer'}
                    onClick={onClickAddFilter}
                />
            )}
        </InfoIconContainer>
    );
};

export default ActionableField;
