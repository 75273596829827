import createEmojiRegex from 'emoji-regex';

const emojiRegex = createEmojiRegex();

export const normalizeInput = (input: string): string => {
    return decodeURIComponent(input)
        .replace(emojiRegex, ' ')
        .replaceAll(/(?<=\s|^)([.\-_a-z])\s?(?=[.\-_a-z](?:\s|$))/gu, '$1')
        .replaceAll(/\s+at\s+/gu, '@')
        .replaceAll(/\s+dot\s+/gu, '.')
        .replaceAll(/\s*<at>\s*/gu, '@')
        .replaceAll(/\s*<dot>\s*/gu, '.')
        .replaceAll(/\s*\(at\)\s*/gu, '@')
        .replaceAll(/\s*\(dot\)\s*/gu, '.')
        .replaceAll(/\s*\[at\]\s*/gu, '@')
        .replaceAll(/\s*\[dot\]\s*/gu, '.')

        .replaceAll(/[^ -~]/gu, ' ')
        .trim()
        .toLowerCase();
};
