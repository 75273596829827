import keyMirror from 'keymirror';
import {changeBucketOrder} from '../api/ProjectApi';

export const ActionTypes = keyMirror({
    API_FAIL: null,

    createProject: null,
    deleteProject: null,
    updateProject: null,
    updateProjectField: null,
    getProjects: null,
    getProject: null,
    updateProjectState: null,
    changeAppState: null,

    getBuckets: null,
    saveBucket: null,
    setUpBuckets: null,
    deleteBucket: null,
    changeBucketOrder: null,

    getLabels: null,
    saveLabel: null,
    deleteLabel: null,
    updateLabel: null,

    getMyFilters: null,
    updateMyFilterSettings: null,
    saveFilter: null,
    deleteFilter: null,

    getFields: null,

    // User
    getUser: null,
    loginWithJwt: null,
    updateCurrentUserSetting: null,
    getCurrentUser: null,
    setCurrentUser: null,
    setCurrentUserJWT: null,
    searchItems: null,

    // Activities
    searchActivities: null,
    countMyDueOverActivities: null,

    // Items
    updateTask: null,
    createItem: null,
    selectItem: null,
    selectAllItems: null,
    getItem: null,
    deleteTask: null,
    bulkDeleteItems: null,
    changeTaskFilter: null,
    changeFilterString: null,

    // Item scoring
    getEstimatedProbability: null,
    getConnectionStrength: null,

    // Messages
    selectInboxApp: null,
    searchMessages: null,
    getMessageThread: null,
    showMessageView: null,
    hideMessageView: null,

    groupBy: null,
    showTaskDialog: null,
    createActivity: null,
    showActivityDialog: null,
    changeActivityData: null,
    hideActivityDialog: null,
    showFilterDialog: null,
    hideFilterDialog: null,
    hideTaskDialog: null,

    // Grid view
    changeColumnOrder: null,
    showHideColumn: null,
    setColumn: null,
    setColumnAggregatingFunc: null,
    showGridDialog: null,
    setNewTaskId: null,

    // Chart view
    addWidget: null,
    deleteWidget: null,
    showWidgetSetting: null,
    updateWidgetSetting: null,
    updateWidgetLayout: null,
    hideWidgetSetting: null,
    getDashboard: null,
    saveDashboard: null,

    // layout
    setShortMenu: null,
    setNotification: null,
    countUnreadNotification: null,
    hoverDataItem: null,

    // Rule
    getRules: null,
    getAppLinks: null,
    getRule: null,
    saveRule: null,
    deleteRule: null,
    enableRule: null,
    setCurrentRule: null,

    // Goal
    getGoals: null,
    getGoal: null,
    resetGoalForm: null,
    saveGoal: null,
    deleteGoal: null,
    enableGoal: null,
    setCurrentGoal: null,

    // Admin
    getMembers: null,
    activeMember: null,
    enableAdmin: null,
    removeMember: null,

    // Info
    getBuildInfo: null,

    // Contact
    getContactInfoByIssue: null,

    // Admin
    getTenants: null,
});

export const DialogTypes = keyMirror({
    mainDialog: null,
    sideDialog: null,
    sideMyInboxDialog: null,
    sideMyInboxMessageDialog: null,
    sideBulkEditDialog: null,
    activityDialog: null,
    mapEventDialog: null,
    createCustomFieldDialog: null,
    updateCustomFieldDialog: null,
    confirmDialog: null,
    confirmBucketDialog: null,
});

export const ActivityTypes = keyMirror({
    meeting: null,
    task: null,
    call: null,
    deadline: null,
    email: null,
    logEmail: null,
    lunch: null,
});

export const ContentTypes = keyMirror({
    TASK: null,
    ISSUE: null,
    ACTIVITY: null,
    SALE: null,
    CONTACT: null,
    COMPANY: null,
    WORK_LOG: null,
    EXPENSE: null,
    EVENT_MAP: null,
    TRACKING: null,
});

export const RuleOperators = keyMirror({
    UPDATED_FIELD: null,
    SEND_TO_SLACK_CHANNEL: null,
    MAILCHIMP_SYNC_EMAILS: null,
    SET_FIELD: null,
    NEW_SALE: null,
    ISSUE_2_NEW_SALE: null,
    CONTACT_2_NEW_SALE: null,
    JIRA_CUSTOMER_2_NEW_CONTACT: null,
    UPDATED_CONTACT: null,
    HUBSPOT_2_NEW_SALE: null,
    NEW_ISSUE: null,
    NEW_CONTACT: null,
    UPDATED_ISSUE: null,
});

export const RuleItemTypes = keyMirror({
    EVENT: null,
    ACTION: null,
});
