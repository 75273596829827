import {get} from './api';

const siteId = 'jsoftware.io';

export async function getPosts() {
    const {data} = await get({path: `/wordpress/${siteId}/posts`});
    return data;
}

export async function getPost(postId: string) {
    const {data} = await get({path: `/wordpress/${siteId}/posts/${postId}`});
    return data;
}

export async function getPrivacy() {
    return getPost('410');
}

export async function getSecurity() {
    return getPost('414');
}

export async function getEULA() {
    return getPost('408');
}
