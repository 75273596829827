import {useEffect, useState} from 'react';
import {getPosts} from '../api/WordpressApi';
import Blog from './Blog';
import {useParams} from 'react-router-dom';
import Loading, {SmallLoading} from '../main/Loading';

export default function BlogPosts() {
    const [posts, setPosts] = useState<any>([]);
    const {blogId}: any = useParams();
    useEffect(() => {
        getPosts().then((data) => {
            setPosts(data.posts);
        });
    }, []);

    const renderList = () => {
        return (
            <div className="mt-16 space-y-20 lg:mt-20 lg:space-y-20">
                {posts.map((post) => (
                    <Blog post={post} key={post.id} showDetail={false} />
                ))}
            </div>
        );
    };

    const renderBlog = () => {
        const post = posts.find((item: any) => item.ID == blogId);
        if (!post) {
            return <div className="mt-16 space-y-20 lg:mt-20 lg:space-y-20">Not Found</div>;
        }
        return (
            <div className="mt-16 space-y-20 lg:mt-20 lg:space-y-20">
                <Blog post={post} key={post.id} showDetail={true} />
            </div>
        );
    };

    const renderContent = () => {
        if (!posts || posts.length == 0) {
            return (
                <div className="mt-16 space-y-20 lg:mt-20 lg:space-y-20 flex justify-center">
                    <SmallLoading />
                </div>
            );
        }
        if (blogId) {
            return renderBlog();
        }
        return renderList();
    };
    return (
        <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:max-w-4xl">
                    <a
                        href={'/blogs'}
                        className="text-3xl font-semibold tracking-tight text-pretty text-gray-900 sm:text-2xl"
                    >
                        Use cases
                    </a>
                    <p className="mt-2 text-lg/8 text-gray-600">
                        Learn how to grow your business with Type CRM.
                    </p>
                    {renderContent()}
                </div>
            </div>
        </div>
    );
}
