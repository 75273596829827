import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {DialogTypes} from '../constants/action-types';
import {XMarkIcon} from '@heroicons/react/24/outline';
import {useNavigate, useParams} from 'react-router-dom';
import {useQuery} from '../AppLayout';
import DayView from '../activities/DayView';
import {useCurrentSize} from '../CurrentSize';
import React, {useState} from 'react';
import BulkEditForm from '../board/components/TaskDetail/BulkEditForm';
import {SmallLoading} from '../main/Loading';
import MessageView from '../inbox/MessageView';
import ActivityAction from '../actions/ActivityAction';
import {toActivityEventData} from '../activities/ItemActivityLogs';

const RightPanelContainer = styled.div`
    position: fixed;
    right: 0;
    height: 100%;
    top: 0;
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 22%) 0px 25.6px 57.6px 0px, rgb(0 0 0 / 18%) 0px 4.8px 14.4px 0px;
    pointer-events: auto;
    display: flex;
    flex-direction: column;
    inset: 0px 0px 0px auto;
    animation: 0s ease 0s 1 normal none running none;
    z-index: 30;
`;

interface ModalCardDetailPropsType {
    onClose: () => void;
}

const RightPanelDialog = (props: ModalCardDetailPropsType) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const show = useSelector((state: any) => state.taskReducer.getIn(['showTaskDialog', 'show']));
    const currentUser = useSelector((state: any) => state.userReducer.get('currentUser'));
    const currentSize = useCurrentSize();
    const [timeViewFilter, setTimeViewFilter] = useState('To-do');
    const {projectId}: any = useParams();
    const contentType = useQuery().get('type');
    const dialogType = useSelector((state: any) =>
        state.taskReducer.getIn(['showTaskDialog', 'type'])
    );

    const handleViewLog = (activityItem: any) => {
        const data = toActivityEventData(activityItem);
        dispatch(ActivityAction.showActivityDialog(data));
    };

    const isSideDialog = () => {
        return (
            dialogType == DialogTypes.sideMyInboxDialog ||
            dialogType == DialogTypes.sideDialog ||
            dialogType == DialogTypes.sideMyInboxMessageDialog ||
            dialogType == DialogTypes.sideBulkEditDialog
        );
    };

    const {onClose} = props;
    const display = show && isSideDialog() ? 'block' : 'none';

    const filterColor = (filter) => {
        return filter == timeViewFilter
            ? filter == 'Overdue'
                ? 'bg-blue-100 text-red-700'
                : 'bg-blue-100 text-blue-700'
            : filter == 'Overdue'
            ? 'hover:bg-gray-100 text-red-600'
            : 'hover:bg-gray-100 text-gray-600';
    };

    const getWidth = () => {
        if (dialogType == DialogTypes.sideMyInboxMessageDialog) {
            return 400;
        }
        return 600;
    };

    const renderHeader = () => {
        if (dialogType == DialogTypes.sideMyInboxDialog) {
            const filters = ['To-do', 'Overdue', 'Done', 'Today', 'Tomorrow'];
            return (
                <div className={'w-full flex space-x-2 p-4'}>
                    <div className={'w-24 text-lg'}>My work</div>
                    <div className={'w-full pl-6 flex space-x-2 align-middle items-center'}>
                        {filters.map((filter) => {
                            const color = filterColor(filter);
                            return (
                                <span
                                    key={filter}
                                    onClick={() => {
                                        setTimeViewFilter(filter);
                                    }}
                                    className={`${color} inline-flex items-center cursor-pointer rounded-md  px-2 py-1 text-xs font-medium`}
                                >
                                    {filter}
                                </span>
                            );
                        })}
                    </div>
                    <XMarkIcon className={'w-6 justify-end cursor-pointer'} onClick={onClose} />
                </div>
            );
        }
        if (dialogType == DialogTypes.sideDialog) {
            return (
                <div className={'w-full flex'}>
                    <div className={'w-full text-lg'}>Create</div>
                    <XMarkIcon className={'w-6 justify-end cursor-pointer'} onClick={onClose} />
                </div>
            );
        }
        return null;
    };
    const renderContent = () => {
        if (dialogType == DialogTypes.sideBulkEditDialog) {
            return (
                <BulkEditForm onClose={onClose} contentType={contentType} projectId={projectId} />
            );
        }
        if (dialogType == DialogTypes.sideMyInboxDialog) {
            return (
                <DayView
                    users={currentUser.get('accountId')}
                    itemId={projectId}
                    height={currentSize.height - 60}
                    scope={'project'}
                    eventData={{}}
                    viewType={'list'}
                    filter={timeViewFilter}
                    onClickEvent={(eventItem) => {
                        handleViewLog(eventItem);
                    }}
                    onClickItem={(item) => {
                        if (item.value) {
                            navigate(
                                `/workspaces/${projectId}/items/${item.value}?type=${item.type}`
                            );
                        }
                    }}
                />
            );
        }
        if (dialogType == DialogTypes.sideMyInboxMessageDialog) {
            return <MessageView width={getWidth()} onClose={onClose} />;
        }
        return <SmallLoading />;
    };
    return (
        <RightPanelContainer
            style={{
                display,
                width: getWidth(),
            }}
        >
            {renderHeader()}
            {renderContent()}
        </RightPanelContainer>
    );
};

export default RightPanelDialog;
