import {combineReducers} from 'redux';
import optimist from 'redux-optimist';
import {projectReducer} from './projectReducer';
import {bucketReducer} from './bucketReducer';
import {taskReducer} from './taskReducer';
import {userReducer} from './userReducer';
import {errorReducer} from './errorReducer';
import {grid} from './gridReducer';
import {chart} from './chartReducer';
import {layoutReducer} from './layoutReducer';
import {setting} from './settingReducer';
import {ruleReducer} from './ruleReducer';
import {notification} from './notificationReducer';
import {tenant} from './tenantReducer';
import {contact} from './contactReducer';
import {goal} from './goalReducer';
import {admin} from './adminReducer';
import {labelReducer} from './labelReducer';
import boardReducer from '../board/reducers/BoardReducer';
import {activityReducer} from './activityReducer';
import {filterReducer} from './filterReducer';
import {inboxReducer} from './inboxReducer';
import {scoring} from './itemScoringReducer';

export default optimist(
    combineReducers({
        projectReducer,
        bucketReducer,
        taskReducer,
        grid,
        chart,
        errorReducer,
        layoutReducer,
        setting,
        userReducer,
        ruleReducer,
        notification,
        tenant,
        contact,
        goal,
        boardReducer,
        admin,
        labelReducer,
        activityReducer,
        filterReducer,
        inboxReducer,
        scoring,
    })
);
