const StorageKey = 'salescrm';

export async function currentSession<T extends unknown>(): Promise<T> {
    const value = sessionStorage.getItem(StorageKey);
    const val = value ? JSON.parse(value) : {};
    return Promise.resolve(val);
}

export async function loginWithJwt(jwt) {
    sessionStorage.setItem(StorageKey, JSON.stringify({jwt}));
}

export function logout() {
    sessionStorage.removeItem(StorageKey);
}
