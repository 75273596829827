import {ActionTypes} from '../constants/action-types';
import {createAction} from 'redux-actions';
import {searchMessages, getMessageThread, getAppData} from '../api/InboxApi';

const api = {
    [ActionTypes.selectInboxApp]: (app) => getAppData(app),
    [ActionTypes.searchMessages]: (query) => searchMessages(query),
    [ActionTypes.getMessageThread]: (threadId: string) => getMessageThread(threadId),
    [ActionTypes.showMessageView]: (message) => message,
    [ActionTypes.hideMessageView]: () => {},
};

const actions: any = {...api};

Object.keys(actions).forEach((type) => {
    actions[type] = createAction(type, actions[type]);
});

export default actions;
