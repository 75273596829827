import {ActionTypes} from '../constants/action-types';
import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';

const initialState = fromJS({
    tenants: [],
    tenantTotal: 0,
    jobStats: {},
});

export const actions = {
    [ActionTypes.getTenants]: {
        next(state, {payload, error, pending}) {
            if (error) {
                return state.merge({error, pending});
            }
            if (pending) {
                return state.merge({pending});
            }
            if (payload == null || payload === undefined) {
                return state.merge({pending, error});
            }
            const newState = {
                ...state.toJS(),
                pending: false,
                ...payload,
            };
            return fromJS(newState);
        },
    },
};

export const admin = handleActions(actions, initialState);
