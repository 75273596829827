import {ActionTypes} from '../constants/action-types';
import {createAction} from 'redux-actions';
import {
    search,
    createItem,
    deleteTask,
    updateTaskField,
    getItem,
    bulkDeleteItems,
} from '../api/ProjectApi';
import {showTaskDialog} from '../api/DialogApi';

const api = {
    [ActionTypes.searchItems]: async ({projectId, page, pageSize, filters, view, types, sorts}) => {
        const data = await search({
            projectId,
            filters,
            page,
            pageSize,
            view,
            types,
            sorts,
        });
        return {data, view};
    },
    [ActionTypes.updateTask]: (taskId, fieldId, fieldValue, itemType, view) =>
        updateTaskField(taskId, fieldId, fieldValue, itemType, view),
    [ActionTypes.createItem]: (task) => createItem(task),
    [ActionTypes.selectAllItems]: ({view, selected}) => ({view, selected}),
    [ActionTypes.selectItem]: ({view, itemId, itemIndex, selected}) => ({
        itemIndex,
        view,
        itemId,
        selected,
    }),
    [ActionTypes.deleteTask]: (taskId) => deleteTask(taskId),
    [ActionTypes.bulkDeleteItems]: ({itemIds, type}) => bulkDeleteItems({itemIds, type}),
    [ActionTypes.getItem]: (taskId) => getItem(taskId),
    [ActionTypes.showTaskDialog]: (task: any, dialogType) => showTaskDialog(task, dialogType),
    [ActionTypes.changeTaskFilter]: (filters) => ({filters}),
    [ActionTypes.changeFilterString]: (filterString, searchMode) => ({filterString, searchMode}),
    [ActionTypes.hideTaskDialog]: (dialogType) => ({dialogType}),
};

const actions: any = {...api};

Object.keys(actions).forEach((type) => {
    actions[type] = createAction(type, actions[type]);
});

export default actions;
