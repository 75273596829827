import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Responsive, WidthProvider} from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import ChartWidget from './ChartWidget';
import WidgetSetting, {getGridWidgetProps} from './WidgetSetting';
import ChartAction from '../actions/ChartAction';
import EllipsisVertical from '../icons/EllipsisVertical';
import DropdownLayout from '../components/DropdownLayout';
import {Cog8ToothIcon, TrashIcon} from '@heroicons/react/24/outline';
import styled from 'styled-components';
import InputInline from '../components/InputInline';
import GridWidget from './GridWidget';
import GoalWidget from './GoalWidget';
import {useCurrentSize} from '../CurrentSize';
import DashboardEmptyState from './DashboardEmptyState';
const ResponsiveReactGridLayout = WidthProvider(Responsive);
export const chartTemplates = [
    {
        value: 'chart',
        label: 'Chart',
        description: 'Create a Chart Widget to visualize data from your pipeline.',
    },
    {
        value: 'grid',
        label: 'Grid',
        description:
            'Create a Grid Widget to show an overview of deals, contacts, and other fields in a Grid view, from your pipeline.',
    },
    {
        value: 'goal',
        label: 'Goal',
        description:
            'Goals are a great way to track progress and ensure that you and your team consistently meet the targets that keep your business running.',
    },
];

const StyledContainer = styled.div`
    width: 100%;
    height: 100%;
    .react-grid-placeholder {
        background-color: #93c5fd !important;
    }
    .recharts-surface {
        cursor: grab;
    }
    .react-grid-item {
        border: 1px solid #d1d5db;
        border-radius: 6px;
    }
`;

const DashboardView = ({projectId, widgets, readonly = false}) => {
    const needToUpdate = useSelector((state) => state.chart.get('needToUpdate'));
    const showWidgetSetting = useSelector((state) => state.chart.get('showWidgetSetting'));

    const dispatch = useDispatch();
    const currentSize = useCurrentSize();

    const handleShowWidget = (widget) => {
        dispatch(ChartAction.showWidgetSetting({widget}));
    };

    const handleSaveDashboard = () => {
        const dashboard = {
            projectId,
            tenantId: '',
            items: widgets.toJS(),
        };
        dispatch(ChartAction.saveDashboard(projectId, dashboard));
    };

    useEffect(() => {
        if (needToUpdate && !readonly) {
            handleSaveDashboard();
        }
    }, [needToUpdate, readonly, handleSaveDashboard]);

    const renderWidget = (widget) => {
        const widgetType = widget.get('widgetType');
        if (widgetType == 'chart') {
            return <ChartWidget widget={widget} projectId={projectId} />;
        } else if (widgetType == 'goal') {
            return <GoalWidget widget={widget} projectId={projectId} />;
        } else if (widgetType == 'grid') {
            return <GridWidget {...getGridWidgetProps(widget)} projectId={projectId} />;
        }
        return <div></div>;
    };

    const widgetActions = (widget) => {
        return (
            <DropdownLayout
                fullWidth={false}
                activator={<EllipsisVertical className={'w-6 float-right'} />}
            >
                <div>
                    <a
                        href="#"
                        onClick={() => {
                            handleShowWidget(widget.toJS());
                        }}
                        className={'group flex items-center px-4 py-2 text-sm'}
                    >
                        <Cog8ToothIcon
                            className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                        />
                        Settings
                    </a>
                </div>
                <div>
                    <a
                        href="#"
                        onClick={() => {
                            dispatch(ChartAction.deleteWidget(widget.get('widgetId')));
                        }}
                        className={'group flex items-center px-4 py-2 text-sm'}
                    >
                        <TrashIcon
                            className="mr-3 h-5 w-5 text-gray-400 group-hover:text-red-500"
                            aria-hidden="true"
                        />
                        Delete
                    </a>
                </div>
            </DropdownLayout>
        );
    };

    const renderTitle = (widget) => {
        if (widget.get('widgetType') == 'goal') {
            return (
                <>
                    <div className={'w-full h-12'}></div>
                    {widgetActions(widget)}
                </>
            );
        }
        if (readonly) {
            return (
                <div className="font-semibold w-full border-b border-gray-200 bg-white h-8 text-gray-600">
                    {widget.getIn(['data', 'title'])}
                </div>
            );
        }
        return (
            <>
                <div className="font-semibold text-gray-900 w-full border-b border-gray-200 bg-white h-12">
                    <InputInline
                        placeholder={'e.g Sale in this quarter'}
                        value={widget.getIn(['data', 'title'])}
                        onFinish={(title) => {
                            if (!readonly) {
                                if (title != widget.getIn(['data', 'title'])) {
                                    const updatedWidget = widget.setIn(['data', 'title'], title);
                                    dispatch(ChartAction.updateWidgetSetting(updatedWidget.toJS()));
                                }
                            }
                        }}
                    />
                </div>
                {widgetActions(widget)}
            </>
        );
    };

    const renderWidgets = () => {
        return widgets.map((widget) => {
            const wConfig = {
                ...widget.toJS(),
            };
            return (
                <div
                    key={widget.get('widgetId')}
                    data-grid={wConfig}
                    className={'border-1 border-gray-200 cursor-grab bg-white'}
                >
                    <div className="px-2 pt-1.5 flex align-middle justify-center">
                        {renderTitle(widget)}
                    </div>
                    <div className={'w-full flex bottom-0'} style={{height: '90%'}}>
                        {renderWidget(widget)}
                    </div>
                </div>
            );
        });
    };

    const defaultProps = {
        className: 'layout',
        rowHeight: 30,
        cols: {lg: 12, md: 10, sm: 6, xs: 4, xxs: 2},
        preventCollision: true,
        compactType: 'horizontal',
    };

    if (readonly) {
        return <div className={'w-full px-2 space-y-2'}>{renderWidgets()}</div>;
    }

    const renderDashboard = () => {
        if (widgets.size == 0) {
            return <DashboardEmptyState />;
        }
        return (
            <StyledContainer>
                <ResponsiveReactGridLayout
                    {...defaultProps}
                    className="layout overflow-y-auto"
                    style={{height: currentSize.height - 40, width: currentSize.width - 80}}
                    measureBeforeMount={false}
                    onLayoutChange={(layouts) => {
                        dispatch(ChartAction.updateWidgetLayout(layouts));
                    }}
                >
                    {renderWidgets()}
                </ResponsiveReactGridLayout>
            </StyledContainer>
        );
    };

    if (showWidgetSetting) {
        return (
            <StyledContainer>
                <WidgetSetting
                    projectId={projectId}
                    onSave={(widgetData) => {
                        dispatch(ChartAction.updateWidgetSetting(widgetData));
                    }}
                    onClose={() => {
                        dispatch(ChartAction.hideWidgetSetting());
                    }}
                />
            </StyledContainer>
        );
    }

    return renderDashboard();
};

export default DashboardView;
