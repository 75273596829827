import {ActionTypes} from '../constants/action-types';
import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';

const initialState = fromJS({
    features: {
        enableJqlMode: false,
        enableAddNewField: true,
        enableGridItemDragDrop: true,
        enableBoardView: true,
        enableTimelineView: true,
    },
    pending: true,
});

export const actions = {
    [ActionTypes.getProject]: {
        next(state, {payload, error, pending}) {
            if (error) {
                return state.merge({error, pending});
            }
            if (pending) {
                return state.merge({pending});
            }
            if (payload == null || payload === undefined) {
                return state.merge({pending, error});
            }
            const {features} = payload;
            const newState = {
                ...state.toJS(),
                features,
            };
            return fromJS(newState);
        },
    },
};

export const setting = handleActions(actions, initialState);
