import {ActionTypes} from '../constants/action-types';
import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';

const initialState = fromJS({
    currentUser: {},
    logged: false,
    pending: true,
});

export const actions = {
    [ActionTypes.setCurrentUser]: {
        next(state, {payload}) {
            const newState = {
                ...state.toJS(),
                currentUser: payload,
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.getCurrentUser]: {
        next(state, data) {
            const {payload, error, pending} = data;
            if (error) {
                return state.merge({error, pending, logged: false});
            }
            if (pending) {
                return state.merge({pending});
            }
            if (payload === null) {
                return state.merge({logged: false});
            }
            const newState = {
                ...state.toJS(),
                logged: true,
                currentUser: payload,
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.updateCurrentUserSetting]: {
        next(state, data) {
            const {payload, error, pending} = data;
            if (error) {
                return state.merge({error, pending, logged: false});
            }
            if (pending) {
                return state.merge({pending});
            }
            if (payload === null) {
                return state.merge({logged: false});
            }
            const newState = {
                ...state.toJS(),
                logged: true,
                currentUser: payload,
            };
            return fromJS(newState);
        },
    },
};

export const userReducer = handleActions(actions, initialState);
