import styled from 'styled-components';
import ChevronDownIcon from '../../../icons/ChevronDown';
import ChevronUpIcon from '../../../icons/ChevronUp';
import InputInline from '../../../components/InputInline';

const InputContainer = styled.div`
    position: relative;
    width: 100%;

    &:hover {
        .group-btn {
            display: block;
        }
    }
`;

const GroupBtn = styled.div`
    position: absolute;
    top: 50%;
    right: 1px;
    transform: translateY(-50%);
    width: 24px;
    display: none;
`;

const BtnChevron = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background-color: rgb(244, 244, 244);
    }
`;

interface PercentFieldProps {
    value: number;
    placeholder?: string;
    size?: string;

    onChange: (text: number) => void;
}
const PercentField = (props: PercentFieldProps) => {
    const {value = 0, onChange, placeholder} = props;

    const plusInput = () => {
        if (value < 100) {
            const newVal = value + 25;
            onChange(newVal);
        }
    };

    const minusInput = () => {
        if (value > 25) {
            onChange(value - 25);
        }
    };

    return (
        <InputContainer>
            <InputInline
                value={`${value}`}
                border={true}
                placeholder={placeholder}
                onChange={(newValue) => {
                    onChange(parseFloat(newValue));
                }}
            />
            <GroupBtn className="group-btn">
                <BtnChevron onClick={plusInput}>
                    <ChevronUpIcon />
                </BtnChevron>
                <BtnChevron onClick={minusInput}>
                    <ChevronDownIcon />
                </BtnChevron>
            </GroupBtn>
        </InputContainer>
    );
};

export default PercentField;
