import styled from 'styled-components';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ProjectPicker from './ProjectPicker';
import ProjectAction from '../../actions/ProjectAction';
import SelectUser from './SelectUser';
import CurrencyPicker from './CurrencyPicker';
import BucketList from './BucketList';
import {RuleList} from '../../rules/component/RuleList';
import {fromJS} from 'immutable';
import InputInline from '../../components/InputInline';
import {deleteProject} from '../../api/ProjectApi';

export const ItemWrapper = styled.div`
    margin-top: 8px;
`;
export const Label = styled.div`
    padding: 0 0 8px 0;
`;

export const TextError = styled.span`
    padding: 0 0 8px 0;
    color: red;
`;

const ProjectForm = ({onSave, onDelete, setStep, step, maxStep}: any) => {
    const project = useSelector((state: any) => state.projectReducer.get('project'));
    const currentUser = useSelector((state: any) => state.userReducer.get('currentUser'));
    const [projectData, setProjectData] = useState(
        fromJS({projects: [], members: [], currency: 'USD'})
    );

    const dispatch = useDispatch();

    const projectManager = projectData.get('projectManager') || currentUser.get('accountId');
    const currency = projectData.get('currency');

    const handleUpdateField = (fieldId: string, fieldValue: any) => {
        const newProject = {
            ...projectData.toJS(),
            members: project.get('members').toJS(),
            [fieldId]: fieldValue,
        };
        setProjectData(fromJS(newProject));
    };

    const validate = () => {
        if (!projectData.get('name')) {
            return false;
        } else if (!projectManager) {
            return false;
        } else if (!currency) {
            return false;
        }
        return true;
    };

    const handleOnSave = () => {
        if (validate()) {
            if (step === maxStep) {
                const newProject = {
                    ...projectData.toJS(),
                    setup: true,
                    type: 'CRM',
                };
                dispatch(ProjectAction.updateProject(newProject));
            }

            const nextStep = step + 1;
            onSave(nextStep);
        }
    };

    const handleDelete = () => {
        deleteProject(project.get('id')).then(() => {
            onDelete();
        });
    };

    const handleOnPrevious = () => {
        if (step > 1) {
            const nextStep = step - 1;
            setStep(nextStep);
        }
    };

    const submitActionLabel = () => {
        if (step == 3) {
            return 'Finish';
        }
        return 'Next';
    };

    const renderProjectInfo = () => {
        if (step == 1) {
            return (
                <>
                    <ItemWrapper>
                        <InputInline
                            border={true}
                            value={projectData.get('name')}
                            placeholder="e.g Sale Department"
                            onFinish={(value) => {
                                handleUpdateField('name', value);
                            }}
                        />
                    </ItemWrapper>
                    {!projectData.get('name') && <TextError>Name is required</TextError>}
                    <ItemWrapper>
                        <div className={'py-2'}>Project lead</div>
                        <SelectUser
                            onChange={(user) => {
                                const userId = user && user !== null ? user.value : '';
                                handleUpdateField('projectManager', userId);
                            }}
                            userId={projectManager}
                        />
                        {!projectManager && <TextError>Project Lead is required</TextError>}
                    </ItemWrapper>
                    <ItemWrapper>
                        <CurrencyPicker
                            currencyCode={currency}
                            onChange={(currency: string) => {
                                handleUpdateField('currency', currency);
                            }}
                        />
                        {!currency && <TextError>Currency is required</TextError>}
                    </ItemWrapper>
                    <ItemWrapper>
                        <ProjectPicker
                            title="Linked projects"
                            projects={projectData.get('projects')}
                            onChange={(projects) => {
                                handleUpdateField('projects', projects);
                            }}
                        />
                    </ItemWrapper>
                </>
            );
        }
    };

    const renderSaleFunnels = () => {
        if (step == 2) {
            return (
                <ItemWrapper>
                    <BucketList title="Sale Funnel" type={'SALE'} projectId={project.get('id')} />
                </ItemWrapper>
            );
        }
    };

    const renderAutomations = () => {
        if (step == 3) {
            return (
                <div className={'flex justify-center w-full'}>
                    <RuleList setup={true} />
                </div>
            );
        }
    };

    const isValid = validate();

    const submitCSS = isValid ? 'text-white' : 'text-gray-400';

    return (
        <div className={'h-full w-full'}>
            {renderProjectInfo()}
            {renderSaleFunnels()}
            {renderAutomations()}
            <div className={'flex space-x-3 justify-end h-full bottom-0 pt-8'}>
                <button
                    className={
                        'pointer-events-auto rounded-md px-3 py-2 bg-red-600 text-white leading-5 w-32'
                    }
                    onClick={handleDelete}
                >
                    Delete
                </button>
                {step > 1 && (
                    <button
                        className={
                            'pointer-events-auto rounded-md px-4 py-2 text-center font-medium shadow-xs ring-1 ring-slate-700/10 hover:bg-slate-50 w-32'
                        }
                        onClick={handleOnPrevious}
                    >
                        Back
                    </button>
                )}
                <button
                    className={`pointer-events-auto rounded-md bg-blue-600 px-3 py-2 text-[0.8125rem] font-semibold leading-5 w-32 ${submitCSS}`}
                    onClick={handleOnSave}
                >
                    {submitActionLabel()}
                </button>
            </div>
        </div>
    );
};

export default ProjectForm;
