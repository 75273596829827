import {ActionTypes} from '../constants/action-types';
import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';
import uuidv1 from 'uuid/v1';

const chartTypes = [
    {
        value: 'bar',
        label: 'Bar',
    },
    {
        value: 'pie',
        label: 'Pie',
    },
    {
        value: 'line',
        label: 'Line',
    },
];

const gridTypes = [
    {
        value: 'SALE',
        label: 'Sale',
    },
];

const widgetTemplates = {
    chart: {
        w: 4,
        h: 9,
        x: 0,
        y: 0,
        data: {
            title: '',
            chartType: 'bar',
        },
    },
    grid: {
        w: 4,
        h: 9,
        x: 0,
        y: 0,
        data: {
            title: '',
            gridType: 'SALE',
        },
    },
    goal: {
        w: 4,
        h: 9,
        x: 0,
        y: 0,
        data: {
            title: '',
            goalId: '',
        },
    },
    counter: {
        w: 4,
        h: 9,
        x: 4,
        y: 0,
    },
    battery: {
        w: 4,
        h: 9,
        x: 8,
        y: 0,
    },
};

export const initialState = fromJS({
    widgets: [],
    chartTypes,
    gridTypes,
    widget: {
        ...widgetTemplates['chart'],
        widgetType: 'chart',
    },
    showWidgetSetting: false,
    needToUpdate: false,
    layouts: [],
});

export const actions = {
    [ActionTypes.getDashboard]: {
        next(state, {payload, error, pending}) {
            if (error) {
                return state.merge({error, pending});
            }
            if (pending) {
                return state.merge({pending});
            }
            if (payload == null || payload === undefined) {
                return state.merge({pending, error});
            }
            const {items} = payload;
            const newState = {
                ...state.toJS(),
                pending: false,
                widgets: items,
                needToUpdate: false,
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.saveDashboard]: {
        next(state, {payload, error, pending}) {
            if (error) {
                return state.merge({error, pending});
            }
            if (pending) {
                return state.merge({pending});
            }
            if (payload == null || payload === undefined) {
                return state.merge({pending, error});
            }
            const newState = {
                ...state.toJS(),
                pending: false,
                needToUpdate: false,
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.addWidget]: {
        next(state, {payload}) {
            const widgetType = payload;
            const widget = widgetTemplates[widgetType];
            const widgets = state.get('widgets');
            const widgetId = uuidv1();
            const newWidget = {...widget, widgetType, widgetId};
            const newList = widgets.push(newWidget);
            const newState = {
                ...state.toJS(),
                widgets: newList.toJS(),
                widget: newWidget,
                showWidgetSetting: true,
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.deleteWidget]: {
        next(state, {payload}) {
            const {widgetId} = payload;
            const newList = state.get('widgets').filter((item) => item.get('widgetId') != widgetId);
            const newState = {
                ...state.toJS(),
                widgets: newList.toJS(),
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.updateWidgetSetting]: {
        next(state, {payload}) {
            const widget = payload;
            const index = state
                .get('widgets')
                .findIndex((item) => item.get('widgetId') == widget.widgetId);
            const newList = state.get('widgets').setIn([index], widget);
            if (index >= 0) {
                const newState = {
                    ...state.toJS(),
                    widget,
                    widgets: newList.toJS(),
                    needToUpdate: true,
                };
                return fromJS(newState);
            }
            return state;
        },
    },
    [ActionTypes.updateWidgetLayout]: {
        next(state, {payload}) {
            const layouts = ([] = payload);
            let newList = state.get('widgets');
            const currentLayouts = state.get('layouts');
            layouts.forEach((layout) => {
                const {h, w, x, y} = layout;
                const index = state
                    .get('widgets')
                    .findIndex((item) => item.get('widgetId') == layout.i);
                if (index >= 0) {
                    const widget = state.get('widgets').getIn([index]);
                    if (widget) {
                        const newWidgetLayout = {
                            ...widget.toJS(),
                            w,
                            h,
                            x,
                            y,
                        };
                        newList = newList.setIn([index], newWidgetLayout);
                    }
                }
            });
            const newState = {
                ...state.toJS(),
                widgets: newList.toJS(),
                needToUpdate: currentLayouts.size > 0,
                layouts,
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.showWidgetSetting]: {
        next(state, {payload}) {
            const {widget} = payload;
            const newState = {
                ...state.toJS(),
                widget,
                showWidgetSetting: true,
            };
            return fromJS(newState);
        },
    },

    [ActionTypes.hideWidgetSetting]: {
        next(state) {
            const newState = {
                ...state.toJS(),
                showWidgetSetting: false,
            };
            return fromJS(newState);
        },
    },
};

export const chart = handleActions(actions, initialState);
