import {useMemo} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import NotificationPopup from './dialog/NotificationPopup';
import AppNav from './AppNav';
import TopNav from './main/component/TopNav';
import {useDispatch, useSelector} from 'react-redux';
import ActivityDialog from './dialog/ActivityDialog';
import TaskAction from './actions/TaskAction';
import {ContentTypes, DialogTypes} from './constants/action-types';
import RightPanelDialog from './dialog/RightPanelDialog';
import FilterDialog from './dialog/FilterDialog';
import InformationInlineDialog from './dialog/InformationInlineDialog';
import styled from 'styled-components';
import ItemDialog from './dialog/ItemDialog';

export function useQuery() {
    const {search} = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

const StyledLayout = styled.div`
    .information-tooltip {
        padding: 0;
    }

    .react-tooltip-arrow {
        width: 0;
    }
`;

export default function AppLayout({children}) {
    const {projectId = '', itemId = ''}: any = useParams();
    const dispatch = useDispatch();
    const project = useSelector((state: any) => state.projectReducer.get('project'));
    window.workspaceId = projectId;

    const showNav = project.get('id') == projectId;
    return (
        <StyledLayout className={'text-sm h-full w-full'}>
            {showNav && <TopNav project={project} itemId={itemId} />}
            <div className={'flex h-full'}>
                {showNav && <AppNav />}
                {children}
            </div>
            <NotificationPopup />
            <ActivityDialog />
            <FilterDialog projectId={projectId} />
            <RightPanelDialog
                onClose={() => {
                    dispatch(TaskAction.hideTaskDialog(DialogTypes.sideDialog));
                }}
            />
            <ItemDialog
                contentType={ContentTypes.SALE}
                onClose={() => {
                    dispatch(TaskAction.hideTaskDialog(ContentTypes.SALE));
                }}
            />
            <ItemDialog
                contentType={ContentTypes.CONTACT}
                onClose={() => {
                    dispatch(TaskAction.hideTaskDialog(ContentTypes.CONTACT));
                }}
            />
            <ItemDialog
                contentType={ContentTypes.COMPANY}
                onClose={() => {
                    dispatch(TaskAction.hideTaskDialog(ContentTypes.COMPANY));
                }}
            />
            <InformationInlineDialog />
        </StyledLayout>
    );
}
