import {ActionTypes} from '../constants/action-types';
import {createAction} from 'redux-actions';
import {countUnreadNotifications} from '../api/NotificationApi';

const api = {
    [ActionTypes.setShortMenu]: (action) => action,
    [ActionTypes.setNotification]: (data) => data,
    [ActionTypes.countUnreadNotification]: (userId: string) => countUnreadNotifications(userId),
    [ActionTypes.hoverDataItem]: (data) => data,
};

const actions: any = {...api};

Object.keys(actions).forEach((type) => {
    actions[type] = createAction(type, actions[type]);
});

export default actions;
