import {StarIcon, UserIcon, FunnelIcon} from '@heroicons/react/24/outline';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

const tabs = [
    {name: 'Favorites', href: '#', icon: StarIcon, current: false},
    {name: 'Owners', href: '#', icon: UserIcon, current: false},
    {name: 'Filters', href: '#', icon: FunnelIcon, current: true},
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function FilterTab({onChange, value}) {
    const {t} = useTranslation();
    const [activeTab, setActiveTab] = useState(value);
    const isCurrentTab = (tab) => {
        return activeTab == tab.name;
    };
    return (
        <div className="border-b border-gray-200">
            <nav aria-label="Tabs" className="-mb-px flex space-x-8">
                {tabs.map((tab) => (
                    <a
                        key={tab.name}
                        href={tab.href}
                        onClick={() => {
                            setActiveTab(tab.name);
                            onChange(tab.name);
                        }}
                        aria-current={isCurrentTab(tab) ? 'page' : undefined}
                        className={classNames(
                            isCurrentTab(tab)
                                ? 'border-indigo-500 text-blue-600'
                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                            'group inline-flex items-center border-b-2 px-1 py-4 text-sm font-medium'
                        )}
                    >
                        <tab.icon
                            aria-hidden="true"
                            className={classNames(
                                isCurrentTab(tab)
                                    ? 'text-blue-500'
                                    : 'text-gray-400 group-hover:text-gray-500',
                                '-ml-0.5 mr-2 h-5 w-5'
                            )}
                        />
                        <span>{t(tab.name)}</span>
                    </a>
                ))}
            </nav>
        </div>
    );
}
