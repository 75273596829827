import {get, isJiraContext, put} from './api';

export async function searchUsers(query: string) {
    if (isJiraContext()) {
        const {data = {}} = await get({path: `/jira/users?query=${query}`});
        return data
            .filter((item: {accountType: string}) => item.accountType != 'app')
            .map((user: any) => ({
                accountId: user.accountId,
                avatarUrl: user.avatarUrls['48x48'],
                displayName: user.displayName,
            }));
    }
    const {data = []} = await get({path: `/users/search?query=${query}`});
    return data;
}

export async function searchProjects() {
    if (isJiraContext()) {
        const {data = {values: []}} = await get({path: `/jira/projects`});
        return data.values.map((project: any) => ({
            id: project.id,
            key: project.key,
            displayName: project.name,
            data: project,
        }));
    }

    return [];
}

export async function getUser(accountId: string) {
    return get({path: `/users/${accountId}`});
}

export async function updateCurrentUserSetting(userData) {
    const response = await put({path: `/users/me/settings`, data: userData});
    return response.data;
}

export async function updateCurrentUserOnboardingSteps(onboardingStep: string) {
    const response = await put({
        path: `/users/me/onboarding-settings`,
        data: {
            onboardingStep,
        },
    });
    return response.data;
}

export async function getCurrentUser() {
    const response = await get({path: `/users/me`});
    return response.data;
}

export async function activeMember(accountId: string) {
    await get({path: `/users/${accountId}/active`});
}

export async function enableAdmin(accountId: string) {
    await get({path: `/users/${accountId}/enable-admin`});
}

export async function removeMember(accountId: string) {
    await get({path: `/users/${accountId}/remove`});
}
