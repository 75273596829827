import Select from 'react-select';
import currencies from '../../constants/CurrencyData';
import {useTranslation} from 'react-i18next';

const currencyOpts = Object.values(currencies).map((item) => ({
    label: item.name,
    value: item.code,
}));

const CurrencyPicker = (props: any) => {
    const {onChange, currencyCode} = props;
    const {t} = useTranslation();

    const selected = currencyOpts.filter((item) => item.value === currencyCode);
    return (
        <>
            <div className={'py-2'}>{t('Currency')}</div>
            <Select
                menuPlacement={'auto'}
                value={selected}
                options={currencyOpts}
                onChange={(item: any) => {
                    onChange(item.value);
                }}
            />
        </>
    );
};

export default CurrencyPicker;
