import styled, {css} from 'styled-components';
import {useSelector, useDispatch} from 'react-redux';
import React, {useEffect, useState} from 'react';
import {fromJS, isImmutable} from 'immutable';
import TaskAction from '../../../actions/TaskAction';
import DatePicker from './DatePicker';
import CustomSelect from './CustomSelect';
import InputField from './InputField';
import PercentField from './PercentField';
import AssignTo from '../AssignTo';
import AutoTextArea from './AutoTextArea';
import {createItem} from '../../../api/ProjectApi';

import CustomField from '../../../main/CustomField';
import LinkItemPicker from '../../../main/component/LinkItemPicker';
import CountryPicker from '../../../main/component/CountryPicker';
import StatePicker from '../../../main/component/StatePicker';
import CityPicker from '../../../main/component/CityPicker';
import LabelPicker from './LabelPicker';
import BucketAction from '../../../actions/BucketAction';
import LabelAction from '../../../actions/LabelAction';
import {
    BuildingOfficeIcon,
    CurrencyDollarIcon,
    UserIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline';
import {Switch} from '@headlessui/react';
import {ContentTypes} from '../../../constants/action-types';
import DomainsField from './DomainsField';
import {isJiraContext} from '../../../api/api';

export const TitleInput = styled.div`
    font-size: 14px;
    font-weight: 600;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #323130;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${({required}: any) =>
        required &&
        css`
            &::before {
                content: '*';
                color: red;
                margin-right: 2px;
            }
        `}
`;

const CompanyRequiredFieldErrors = {
    name: false,
};

const ContactRequiredFieldErrors = {
    name: false,
    email: false,
};

const SaleRequiredFieldErrors = {
    name: false,
};

const DealForm = (props: any) => {
    const {
        onSave = () => {},
        onClose = () => {},
        projectId = '',
        defaultItemData = {},
        contentType,
        projectView,
        editMode = false,
        showTitle = false,
        showWorkspacePicker = false,
        workspaces = [],
        onChangeWorkspace = () => {},
        maxHeight = 600,
        listStyles = 'grid grid-cols-1 lg:grid-cols-2 gap-2',
    } = props;
    const [requiredFieldErrors, setRequiredFieldErrors] = useState<{[key: string]: boolean}>({});

    const [itemData, setItemData] = useState(
        fromJS({
            ...defaultItemData,
        })
    );

    const dispatch = useDispatch();

    const saleStatus = useSelector((state: any) => state.ruleReducer.getIn(['status'])).toJS();
    const saleSources = useSelector((state: any) => state.ruleReducer.getIn(['source'])).toJS();
    const saleStages = useSelector((state: any) => state.ruleReducer.getIn(['stage'])).toJS();
    const industries = useSelector((state: any) => state.ruleReducer.getIn(['industry'])).toJS();
    const salutations = useSelector((state: any) => state.ruleReducer.getIn(['salutation'])).toJS();
    const buckets = useSelector((state: any) => state.bucketReducer.get(contentType)) || fromJS([]);
    const [createMore, setCreateMore] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const bucketOptions = buckets.map((bucket: any) => ({
        value: bucket.get('id'),
        label: bucket.get('name'),
    }));

    const fields = useSelector((state: any) => state.projectReducer.getIn(['project', 'fields']));
    const project = useSelector((state: any) => state.projectReducer.getIn(['project']));
    const task =
        useSelector((state: any) => state.taskReducer.getIn([contentType, 'task'])) || fromJS({});

    const pagination = useSelector((state: any) => state.taskReducer.getIn(['pagination']));

    const taskFilters = useSelector((state: any) => state.taskReducer.getIn(['filters']));

    const itemId = task.get('id');

    const isEditing = itemId != undefined;

    const handleUpdateField = (fieldId: string, fieldValue: any) => {
        if (itemId) {
            dispatch(TaskAction.updateTask(itemId, fieldId, fieldValue));
        } else {
            const newItemData = {
                ...itemData.toJS(),
                [fieldId]: {
                    value: fieldValue,
                },
            };
            setItemData(fromJS(newItemData));
        }
    };

    const handleCheckRequiredField = (fieldId: string, error: boolean) => {
        setRequiredFieldErrors({...requiredFieldErrors, [fieldId]: error});
    };

    const validateForm = () => {
        let isValid = true;
        let errors = requiredFieldErrors;
        Object.keys(requiredFieldErrors).forEach((fieldId) => {
            const value = itemData.getIn([fieldId, 'value']) || '';
            if (value === '') {
                isValid = false;
                errors = {
                    ...errors,
                    [fieldId]: true,
                };
            }
        });
        setRequiredFieldErrors(errors);
        return isValid;
    };

    useEffect(() => {
        if (contentType === ContentTypes.COMPANY) {
            setRequiredFieldErrors(CompanyRequiredFieldErrors);
        }
        if (contentType === ContentTypes.CONTACT) {
            setRequiredFieldErrors(ContactRequiredFieldErrors);
        }
        if (contentType === ContentTypes.SALE) {
            setRequiredFieldErrors(SaleRequiredFieldErrors);
        }
    }, [contentType]);

    useEffect(() => {
        if (contentType && projectId != '') {
            dispatch(BucketAction.getBuckets(projectId, contentType));
            dispatch(LabelAction.getLabels(projectId, contentType));
        }
    }, [contentType, projectId]);

    useEffect(() => {
        if (task.get('data')) {
            setItemData(
                fromJS({
                    ...itemData.toJS(),
                    ...task.get('data').toJS(),
                })
            );
        }
    }, [task]);

    const reloadItems = () => {
        const page = pagination.getIn(['number']);
        const pageSize = pagination.getIn(['size']);
        const filters = contentType === 'SALE' ? taskFilters.toJS() : {};
        const types = [contentType];
        dispatch(
            TaskAction.searchItems({
                projectId: project.get('id'),
                page,
                pageSize,
                filters,
                types,
                view: projectView,
            })
        );
    };

    const handleCreateNewItem = async () => {
        const isValid = validateForm();
        if (isValid) {
            setSubmitting(true);
            const newItemData = {
                data: itemData,
                projectId: project.get('id'),
                treeId: project.get('treeId'),
                type: contentType,
            };

            await createItem(newItemData);
            reloadItems();
            setItemData(fromJS({}));
            onSave({createMore});
            setSubmitting(false);
        }
    };

    const getTaskFieldValue = (fieldId: string) => {
        if (isEditing) {
            return task.getIn(['data', fieldId, 'value']);
        }
        return itemData.getIn([fieldId, 'value']);
    };

    const renderCustomFields = () => {
        const customFields =
            fields &&
            fields.filter(
                (field: any) => field.get('isCustomField') && field.get('formType') == contentType
            );

        if (customFields && customFields.size > 0) {
            return customFields.map((customField: any) => {
                const fieldData = {
                    ...customField.toJS(),
                    value: getTaskFieldValue(customField.get('id')),
                };
                return (
                    <div className={'w-full'} key={`${customField.get('id')}`}>
                        <TitleInput>{customField.get('label')}</TitleInput>
                        <CustomField
                            field={fieldData}
                            onChange={(value: any) => {
                                handleUpdateField(customField.get('id'), value);
                            }}
                        />
                    </div>
                );
            });
        }
        return null;
    };

    const renderContactForm = () => {
        return (
            <>
                <div className={'w-full'}>
                    <TitleInput>Contact name</TitleInput>
                    <InputField
                        required
                        error={requiredFieldErrors['name']}
                        placeholder={'Name'}
                        value={getTaskFieldValue('name')}
                        onChange={(value: string, error: boolean) => {
                            handleCheckRequiredField('name', error);
                            handleUpdateField('name', value);
                        }}
                    />
                </div>
                <div>
                    <TitleInput>Primary account</TitleInput>
                    <LinkItemPicker
                        projectId={projectId}
                        maxWidth={'100%'}
                        type="COMPANY"
                        menuPlacement={'top'}
                        items={getTaskFieldValue('account')}
                        onChange={(newAccount) => {
                            handleUpdateField('account', newAccount);
                        }}
                    />
                </div>
                <div className={'w-full'}>
                    <TitleInput>Description</TitleInput>
                    <AutoTextArea
                        key={`notes_${task.get('id')}`}
                        rows={3}
                        placeholder="Add a description..."
                        value={getTaskFieldValue('description')}
                        onChange={(value) => {
                            handleUpdateField('description', value);
                        }}
                    />
                </div>
                <div className={listStyles}>
                    <div>
                        <TitleInput>Email</TitleInput>
                        <InputField
                            required
                            error={requiredFieldErrors['email']}
                            value={getTaskFieldValue('email')}
                            onChange={(value: string) => {
                                handleUpdateField('email', value);
                            }}
                        />
                    </div>
                    <div>
                        <TitleInput>Phone number</TitleInput>
                        <InputField
                            value={getTaskFieldValue('phone')}
                            onChange={(value: string) => {
                                handleUpdateField('phone', value);
                            }}
                        />
                    </div>
                    <div>
                        <TitleInput>Job title</TitleInput>
                        <InputField
                            value={getTaskFieldValue('jobTitle')}
                            onChange={(value: string) => {
                                handleUpdateField('jobTitle', value);
                            }}
                        />
                    </div>
                    <div>
                        <TitleInput>Labels</TitleInput>
                        <LabelPicker
                            type={contentType}
                            projectId={projectId}
                            labels={task.getIn(['data', 'labels', 'value'])}
                            onChange={(labels) => {
                                handleUpdateField('labels', labels);
                            }}
                        />
                    </div>
                    <div>
                        <TitleInput>Owners</TitleInput>
                        <AssignTo
                            assignees={getTaskFieldValue('assignees')}
                            sizeIcon={20}
                            label={true}
                            border={true}
                            onChange={(assignees: any) => {
                                if (assignees) {
                                    const assigneeIds = assignees.map(
                                        (assignee: any) => assignee.accountId
                                    );
                                    handleUpdateField('assignees', assigneeIds.join(','));
                                }
                            }}
                        />
                    </div>
                    <div>
                        <TitleInput>Salutation</TitleInput>
                        <CustomSelect
                            options={salutations}
                            value={getTaskFieldValue('salutation')}
                            onChange={(value: string) => {
                                handleUpdateField('salutation', value);
                            }}
                        />
                    </div>
                    <div>
                        <TitleInput>Birthday</TitleInput>
                        <InputField
                            value={getTaskFieldValue('birthday')}
                            placeholder={'yyyy-MM-dd'}
                            onChange={(value: string) => {
                                handleUpdateField('birthday', value);
                            }}
                        />
                    </div>

                    <div>
                        <TitleInput>Group</TitleInput>
                        <CustomSelect
                            options={bucketOptions}
                            value={getTaskFieldValue('bucketId')}
                            onChange={(value) => handleUpdateField('bucketId', value)}
                        />
                    </div>
                </div>
                {renderCustomFields()}
            </>
        );
    };

    const renderSaleFieldContext = () => {
        return (
            <>
                <div>
                    <TitleInput>Estimated probability (%)</TitleInput>
                    <PercentField
                        value={getTaskFieldValue('estimatedProbability')}
                        onChange={(percentValue: number) => {
                            const isValid = percentValue >= 0 && percentValue <= 100;
                            handleCheckRequiredField('estimatedProbability', !isValid);
                            if (isValid) {
                                handleUpdateField('estimatedProbability', percentValue);
                            }
                        }}
                    />
                </div>
                <div>
                    <TitleInput>Status</TitleInput>
                    <CustomSelect
                        options={saleStatus}
                        value={getTaskFieldValue('status')}
                        onChange={(value) => {
                            handleUpdateField('status', value);
                        }}
                    />
                </div>
                <div>
                    <TitleInput>Stage</TitleInput>
                    <CustomSelect
                        options={saleStages}
                        value={getTaskFieldValue('stage')}
                        onChange={(value) => {
                            handleUpdateField('stage', value);
                        }}
                    />
                </div>

                <div>
                    <TitleInput>Sale funnels</TitleInput>
                    <CustomSelect
                        options={bucketOptions}
                        value={getTaskFieldValue('bucketId')}
                        onChange={(value) => handleUpdateField('bucketId', value)}
                    />
                </div>
                <div>
                    <TitleInput>Source</TitleInput>
                    <CustomSelect
                        options={saleSources}
                        value={getTaskFieldValue('source')}
                        onChange={(value) => {
                            handleUpdateField('source', value);
                        }}
                    />
                </div>
                {renderCustomFields()}
            </>
        );
    };

    const renderSaleForm = () => {
        const isJira = isJiraContext();
        return (
            <>
                <div className={'w-full'}>
                    <TitleInput>Summary</TitleInput>
                    <InputField
                        required
                        error={requiredFieldErrors['name']}
                        placeholder={'Summary'}
                        value={getTaskFieldValue('name')}
                        onChange={(value: string, error: boolean) => {
                            handleCheckRequiredField('name', error);
                            handleUpdateField('name', value);
                        }}
                    />
                </div>
                <div className={listStyles}>
                    {isJira && (
                        <div>
                            <TitleInput>Jira Issue</TitleInput>
                            <LinkItemPicker
                                type="TASK"
                                maxWidth={'100%'}
                                projectId={projectId}
                                items={getTaskFieldValue('issue')}
                                onChange={(newIssue) => {
                                    handleUpdateField('issue', newIssue);
                                }}
                            />
                        </div>
                    )}
                    <div>
                        <TitleInput>Close Date</TitleInput>
                        <DatePicker
                            selected={getTaskFieldValue('finish')}
                            onChange={(value: string) => {
                                handleUpdateField('finish', value);
                            }}
                        />
                    </div>
                    <div>
                        <TitleInput>Labels</TitleInput>
                        <LabelPicker
                            type={contentType}
                            projectId={projectId}
                            labels={task.getIn(['data', 'labels', 'value'])}
                            onChange={(labels) => {
                                handleUpdateField('labels', labels);
                            }}
                        />
                    </div>
                    <div>
                        <TitleInput>Assignees</TitleInput>
                        <AssignTo
                            assignees={getTaskFieldValue('assignees')}
                            sizeIcon={20}
                            label={true}
                            border={true}
                            onChange={(assignees: any) => {
                                if (assignees) {
                                    const assigneeIds = assignees.map(
                                        (assignee: any) => assignee.accountId
                                    );
                                    handleUpdateField('assignees', assigneeIds.join(','));
                                }
                            }}
                        />
                    </div>
                    <div>
                        <TitleInput>Primary account</TitleInput>
                        <LinkItemPicker
                            maxWidth={'100%'}
                            type="COMPANY"
                            projectId={projectId}
                            items={getTaskFieldValue('account')}
                            onChange={(newAccount) => {
                                handleUpdateField('account', newAccount);
                            }}
                        />
                    </div>
                    <div>
                        <TitleInput>Primary contact</TitleInput>
                        <LinkItemPicker
                            maxWidth={'100%'}
                            type="CONTACT"
                            projectId={projectId}
                            items={getTaskFieldValue('contact')}
                            onChange={(newContact) => {
                                handleUpdateField('contact', newContact);
                            }}
                        />
                    </div>

                    <div>
                        <TitleInput>Estimated revenue ({project.get('currency')})</TitleInput>
                        <InputField
                            value={getTaskFieldValue('estimatedRevenue')}
                            onChange={(value: string, error: boolean) => {
                                handleUpdateField('estimatedRevenue', value);
                            }}
                        />
                    </div>

                    {renderSaleFieldContext()}
                </div>
            </>
        );
    };

    const renderCompanyForm = () => {
        return (
            <>
                <div className={'w-full'}>
                    <TitleInput>Account name</TitleInput>
                    <InputField
                        placeholder={'Name'}
                        required
                        error={requiredFieldErrors['name']}
                        value={getTaskFieldValue('name')}
                        onChange={(value: string, error: boolean) => {
                            handleCheckRequiredField('name', error);
                            handleUpdateField('name', value);
                        }}
                    />
                </div>
                <div className={'w-full'}>
                    <TitleInput>Domains</TitleInput>
                    <DomainsField
                        handleUpdateField={handleUpdateField}
                        domains={getTaskFieldValue('domains')}
                    />
                </div>
                <div className={'w-full'}>
                    <TitleInput>Description</TitleInput>
                    <AutoTextArea
                        key={`notes_${task.get('id')}`}
                        rows={3}
                        placeholder="Add a description..."
                        value={getTaskFieldValue('description')}
                        onChange={(value) => {
                            handleUpdateField('description', value);
                        }}
                    />
                </div>
                <div className={listStyles}>
                    <div>
                        <TitleInput>Primary contact</TitleInput>
                        <LinkItemPicker
                            projectId={projectId}
                            maxWidth={'100%'}
                            type="CONTACT"
                            menuPlacement={'auto'}
                            items={getTaskFieldValue('contact')}
                            onChange={(newContact) => {
                                handleUpdateField('contact', newContact);
                            }}
                        />
                    </div>
                    <div>
                        <TitleInput>Labels</TitleInput>
                        <LabelPicker
                            type={contentType}
                            projectId={projectId}
                            labels={task.getIn(['data', 'labels', 'value'])}
                            onChange={(labelIds) => {
                                handleUpdateField('labels', labelIds);
                            }}
                        />
                    </div>
                    <div>
                        <TitleInput>Owners</TitleInput>
                        <AssignTo
                            assignees={getTaskFieldValue('assignees')}
                            sizeIcon={20}
                            label={true}
                            border={true}
                            onChange={(assignees: any) => {
                                if (assignees) {
                                    const assigneeIds = assignees.map(
                                        (assignee: any) => assignee.accountId
                                    );
                                    handleUpdateField('assignees', assigneeIds.join(','));
                                }
                            }}
                        />
                    </div>
                    <div>
                        <TitleInput>Website</TitleInput>
                        <InputField
                            value={getTaskFieldValue('website')}
                            onChange={(value: string, error: boolean) => {
                                handleUpdateField('website', value);
                            }}
                        />
                    </div>
                    <div>
                        <TitleInput>Email</TitleInput>
                        <InputField
                            value={getTaskFieldValue('email')}
                            onChange={(value: string, error: boolean) => {
                                handleUpdateField('email', value);
                            }}
                        />
                    </div>
                    <div>
                        <TitleInput>Phone number</TitleInput>
                        <InputField
                            value={getTaskFieldValue('phone')}
                            onChange={(value: string, error: boolean) => {
                                handleCheckRequiredField('phone', error);
                                handleUpdateField('phone', value);
                            }}
                        />
                    </div>
                    <div>
                        <TitleInput>Street</TitleInput>
                        <InputField
                            value={getTaskFieldValue('street')}
                            onChange={(value: string, error: boolean) => {
                                handleUpdateField('street', value);
                            }}
                        />
                    </div>

                    <div>
                        <TitleInput>Country</TitleInput>
                        <CountryPicker
                            item={getTaskFieldValue('country')}
                            onChange={(country) => handleUpdateField('country', country)}
                        ></CountryPicker>
                    </div>
                    <div>
                        <TitleInput>State</TitleInput>
                        <StatePicker
                            country={getTaskFieldValue('country')}
                            item={getTaskFieldValue('state')}
                            onChange={(state) => handleUpdateField('state', state)}
                        />
                    </div>
                    <div>
                        <TitleInput>City</TitleInput>
                        <CityPicker
                            country={getTaskFieldValue('country')}
                            state={getTaskFieldValue('state')}
                            item={getTaskFieldValue('city')}
                            onChange={(city) => handleUpdateField('city', city)}
                        />
                    </div>
                    <div>
                        <TitleInput>Postal code</TitleInput>
                        <InputField
                            onChange={(value: string) => {
                                handleUpdateField('postcode', value);
                            }}
                        />
                    </div>
                    <div>
                        <TitleInput>Group</TitleInput>
                        <CustomSelect
                            options={bucketOptions}
                            value={getTaskFieldValue('bucketId')}
                            onChange={(value) => handleUpdateField('bucketId', value)}
                        />
                    </div>
                    <div>
                        <TitleInput>Industry</TitleInput>
                        <CustomSelect
                            options={industries}
                            value={getTaskFieldValue('industry')}
                            onChange={(value) => handleUpdateField('industry', value)}
                        />
                    </div>
                </div>
                {renderCustomFields()}
            </>
        );
    };

    const renderForm = () => {
        if (contentType === 'CONTACT') {
            return renderContactForm();
        }
        if (contentType === 'COMPANY') {
            return renderCompanyForm();
        }
        if (contentType === 'SALE') {
            return renderSaleForm();
        }
        return null;
    };

    if (editMode) {
        return renderForm();
    }

    const title = () => {
        if (contentType == 'SALE') {
            return (
                <div className={'flex space-x-2'}>
                    <CurrencyDollarIcon className={'w-5'} />
                    <div>Deal</div>
                </div>
            );
        }
        if (contentType == 'CONTACT') {
            return (
                <div className={'flex space-x-2'}>
                    <UserIcon className={'w-5'} />
                    <div>Contact</div>
                </div>
            );
        }
        if (contentType == 'COMPANY') {
            return (
                <div className={'flex space-x-2'}>
                    <BuildingOfficeIcon className={'w-5'} />
                    <div>Account</div>
                </div>
            );
        }
    };

    const submittingStyle = submitting ? 'opacity-50 cursor-not-allowed' : '';

    return (
        <div className={'w-full h-full'}>
            {showTitle && (
                <div className={'flex relative divide-gray-900/5 bg-gray-50 h-12 items-center'}>
                    <div className={'pl-2'}>{title()}</div>
                    <div className={'absolute right-2'}>
                        <XMarkIcon
                            onClick={onClose}
                            className={'w-5 cursor-pointer hover:text-blue-600'}
                        />
                    </div>
                </div>
            )}

            <div className={'overflow-y-auto p-2'} style={{maxHeight}}>
                {showWorkspacePicker && (
                    <div className={'w-full'}>
                        <TitleInput>Workspaces</TitleInput>
                        <CustomSelect
                            options={workspaces}
                            value={projectId}
                            onChange={onChangeWorkspace}
                        />
                    </div>
                )}
                {renderForm()}
            </div>
            <div className={'w-full divide-gray-900/5 bg-gray-50'}>
                <div className={'flex space-x-2 justify-end w-full p-3'}>
                    <div className="flex items-center">
                        <Switch
                            checked={createMore}
                            onChange={setCreateMore}
                            className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 data-[checked]:bg-blue-600"
                        >
                            <span
                                aria-hidden="true"
                                className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                            />
                        </Switch>
                        <span className="text-gray-900 ml-3 text-sm">Create more</span>
                    </div>
                    <button
                        onClick={async () => {
                            if (!submitting) {
                                await handleCreateNewItem();
                            }
                        }}
                        type="button"
                        className={`rounded-sm bg-blue-600 px-4 py-2 text-xs font-semibold text-white shadow-xs hover:bg-blue-500 focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-blue-600 ${submittingStyle}`}
                    >
                        Create record
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DealForm;
