import DropdownLayout from './DropdownLayout';
import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
    div {
        border: #2254ff;
        border-radius: 0.375rem;
        padding: 0.5rem;
        height: fit-content;
    }
`;

export const LongTextViewer = ({html}) => {
    return (
        <DropdownLayout
            bodyClassName={'-mt-8'}
            activator={
                <div className={'w-full truncate ...'} dangerouslySetInnerHTML={{__html: html}} />
            }
        >
            <StyledContainer
                className={'w-full h-fit rounded-md border border-blue-600 p-2'}
                dangerouslySetInnerHTML={{__html: html}}
            />
        </DropdownLayout>
    );
};

export default LongTextViewer;
