import styled from 'styled-components';
const Container = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
`;

const ProgressWrapper = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

const Label = styled.div`
    margin-left: 5px;
    display: flex;
    width: 20%;
`;

const ProgressField = (props: any) => {
    const progressValue = parseInt(props.value) || 0;
    return (
        <Container>
            <ProgressWrapper>
                <Label>
                    {progressValue}
                    {'%'}
                </Label>
            </ProgressWrapper>
        </Container>
    );
};

export default ProgressField;
