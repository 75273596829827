const Blog = ({post, showDetail}) => {
    const content = showDetail ? post.content : post.excerpt;
    const containerCss = showDetail ? 'w-full' : 'flex flex-col gap-8 lg:flex-row';
    const thumbnailCss = showDetail
        ? 'w-full'
        : 'sm:aspect-2/1 lg:aspect-square lg:w-64 lg:shrink-0';

    const renderCategories = () => {
        const categories = Object.values(post.categories);
        return categories.map((category: any) => (
            <a
                key={`${post.ID}_${category.ID}`}
                href={'#'}
                className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
            >
                {category.name}
            </a>
        ));
    };
    return (
        <article key={post.ID} className={`relative isolate ${containerCss}`}>
            <div className={`relative aspect-video ${thumbnailCss}`}>
                <img
                    alt=""
                    src={post.featured_image}
                    className="absolute inset-0 size-full rounded-2xl bg-gray-50 object-cover w-full"
                />
                <div className="absolute inset-0 rounded-2xl ring-1 ring-gray-900/10 ring-inset" />
            </div>
            <div className={'w-full'}>
                <div className="flex items-center gap-x-4 text-xs">
                    <time dateTime={post.modified} className="text-gray-500">
                        {post.modified}
                    </time>
                    {renderCategories()}
                </div>
                <div className="group relative w-full">
                    <h3 className="mt-3 text-lg/6 font-semibold text-gray-900 group-hover:text-gray-600">
                        <a href={`/blogs/${post.ID}`}>
                            <span className="absolute inset-0" />
                            {post.title}
                        </a>
                    </h3>
                    <div
                        dangerouslySetInnerHTML={{__html: content}}
                        className="mt-5 text-sm/6 text-gray-600"
                    />
                </div>
                <div className="mt-6 flex border-t border-gray-900/5 pt-6">
                    <div className="relative flex items-center gap-x-4">
                        <img
                            alt=""
                            src={post.author.avatar_URL}
                            className="size-10 rounded-full bg-gray-50"
                        />
                        <div className="text-sm/6">
                            <p className="font-semibold text-gray-900">
                                <a href={post.author.profile_URL}>
                                    <span className="absolute inset-0" />
                                    {post.author.nice_name}
                                </a>
                            </p>
                            <p className="text-gray-600">Co-Founder/ Head of Sales</p>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    );
};

export default Blog;
