import {ActionTypes} from '../constants/action-types';
import {createAction} from 'redux-actions';
import {deleteRule, getRules, saveRule, enableRule, getRule} from '../api/RuleApi';
import {getAppLinks} from '../api/AppLinkApi';

const api = {
    [ActionTypes.getRules]: (projectId) => getRules(projectId),
    [ActionTypes.getAppLinks]: () => getAppLinks(),
    [ActionTypes.saveRule]: (rule) => saveRule(rule),
    [ActionTypes.deleteRule]: (ruleId) => deleteRule(ruleId),
    [ActionTypes.getRule]: (ruleId) => getRule(ruleId),
    [ActionTypes.enableRule]: (ruleId) => enableRule(ruleId),
    [ActionTypes.setCurrentRule]: (rule) => rule,
};

const actions: any = {...api};

Object.keys(actions).forEach((type) => {
    actions[type] = createAction(type, actions[type]);
});

export default actions;
