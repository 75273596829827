import {get, post} from './api';

export async function connectApp(projectId: string, app: string, postUrl: string) {
    const {data} = await post({path: `/apps/${app}/connect`, data: {postUrl}});
    return data;
}

export async function saveAppSettings(app: string, settings: any) {
    const {data} = await post({path: `/apps/${app}/settings`, data: settings});
    return data;
}

export async function getAppLinks() {
    const {data} = await get({path: `/apps`});
    return data;
}

export async function getAppLink(appId) {
    const {data} = await get({path: `/apps/${appId}`});
    return data;
}

export async function getHubspotField(objectType) {
    const {data} = await get({path: `/apps/hubspot/fields/${objectType}`});
    return data;
}

export async function getJiraFields() {
    const {data} = await get({path: `/jira/fields`});
    return data;
}

export async function getSlackChannels() {
    const {data} = await get({path: `/slack/channels`});
    return data;
}

export async function pingMailchimp() {
    const {data} = await get({path: `/apps/mailchimp/ping`});
    return data;
}

export async function getMailchimpAudienceList() {
    const {data} = await get({path: `/apps/mailchimp/lists`});
    return data;
}

export async function getCurrentGoogleOwner() {
    const {data} = await get({path: `/google/owner`});
    return data;
}

export async function getCurrentAzureOwner() {
    const {data} = await get({path: `/azure/owner`});
    return data;
}

export async function signIn(app = '') {
    const {data} = await get({path: `/${app}/login`});
    return data;
}

export async function getGoogleTenant(email: string) {
    const {data} = await post({path: `/google/tenant`, data: {email}});
    return data;
}

export function openLink(url) {
    const a = document.createElement('a');
    a.href = url;
    a.setAttribute('target', '_parent');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}
