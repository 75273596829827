import {ActionTypes} from '../constants/action-types';
import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';
import {everyoneFilter, getSystemFilters, userFilter} from './FilterPreset';

const defaultFilter = {
    name: '',
    data: {
        filter: '',
    },
    visibility: 'Private',
};

const initialState = fromJS({
    pending: true,
    myFilterLoading: true,
    show: false,
    contentType: 'SALE',
    systemFilters: getSystemFilters(),
    allFilters: [],
    ownerFilters: [],
    customFilters: [],
    favoriteFilters: [],
    activeFilter: {},
    defaultFilter,
});

export const buildUserFilterState = (state, payload) => {
    const {
        favoriteFilters = [],
        currentFilter = 'me',
        currentContactFilter = '',
        currentAccountFilter = '',
        currentActivityFilter = '',
    } = payload;
    const ownerFilters = state.get('ownerFilters').toJS() || [
        everyoneFilter(),
        userFilter(payload),
    ];
    const customFilters = state.get('customFilters').toJS();
    const allFilters = ownerFilters.concat(getSystemFilters()).concat(customFilters);
    const contentType = state.get('contentType') || 'SALE';
    let activeFilter = currentFilter;
    if (contentType == 'CONTACT') {
        activeFilter = currentContactFilter;
    }
    if (contentType == 'COMPANY') {
        activeFilter = currentAccountFilter;
    }
    if (contentType == 'ACTIVITY') {
        activeFilter = currentActivityFilter;
    }
    const newState = {
        ...state.toJS(),
        favoriteFilters,
        currentFilter,
        currentContactFilter,
        currentAccountFilter,
        currentActivityFilter,
        allFilters,
        ownerFilters,
        activeFilter: allFilters.find((item) => item.id == activeFilter) || everyoneFilter(),
        pending: false,
    };
    return fromJS(newState);
};

const getActiveFilter = (state, contentType) => {
    if (contentType == 'SALE') {
        return state.get('currentFilter');
    }
    if (contentType == 'CONTACT') {
        return state.get('currentContactFilter');
    }
    if (contentType == 'COMPANY') {
        return state.get('currentAccountFilter');
    }
    if (contentType == 'ACTIVITY') {
        return state.get('currentActivityFilter');
    }
    return state.get('currentFilter');
};

export const actions = {
    [ActionTypes.getMyFilters]: {
        next(state, data) {
            const {payload, error, pending} = data;
            if (error) {
                return state.merge({error, pending, myFilterLoading: true});
            }
            if (pending) {
                return state.merge({pending, myFilterLoading: true});
            }
            if (payload === null || payload == undefined) {
                return state;
            }
            const {filters = [], contentType} = payload;
            const ownerFilters = state.get('ownerFilters').toJS() || [
                everyoneFilter(),
                userFilter(payload),
            ];
            const allFilters = ownerFilters.concat(getSystemFilters()).concat(filters);
            const activeFilter = getActiveFilter(state, contentType);
            const newState = {
                ...state.toJS(),
                customFilters: filters,
                myFilterLoading: false,
                contentType,
                activeFilter:
                    allFilters.find((item) => item.id == activeFilter) || everyoneFilter(),
                allFilters,
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.getCurrentUser]: {
        next(state, data) {
            const {payload, error, pending} = data;
            if (error || payload == null) {
                return state.merge({error, pending});
            }
            if (pending) {
                return state.merge({pending});
            }

            return buildUserFilterState(state, payload);
        },
    },
    [ActionTypes.getProject]: {
        next(state, data) {
            const {payload, error, pending} = data;
            if (error || payload === null) {
                return state.merge({error, pending});
            }
            if (pending) {
                return state.merge({pending});
            }
            const {members = []} = payload;
            const memberFilters = members.map((member) => userFilter(member));
            const newState = {
                ...state.toJS(),
                ownerFilters: [everyoneFilter(), ...memberFilters],
                pending: false,
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.updateMyFilterSettings]: {
        next(state, data) {
            const {payload, error, pending} = data;
            if (error || payload === null) {
                return state.merge({error, pending});
            }
            if (pending) {
                return state.merge({pending});
            }
            return buildUserFilterState(state, payload);
        },
    },
    [ActionTypes.showFilterDialog]: {
        next(state, {payload}) {
            const {data, contentType} = payload;
            const newState = {
                ...state.toJS(),
                defaultFilter: data,
                contentType,
                show: true,
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.hideFilterDialog]: {
        next(state, {}) {
            const newState = {
                ...state.toJS(),
                show: false,
                data: {},
            };
            return fromJS(newState);
        },
    },
};

export const filterReducer = handleActions(actions, initialState);
