import {ActionTypes} from '../constants/action-types';
import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';

const initialState = fromJS({
    pending: true,
    bucket: {},
    TASK: [],
    SALE: [],
    COMPANY: [],
    CONTACT: [],
    TRACKING: [],
    ACTIVITY: [],
});

export const actions = {
    [ActionTypes.setUpBuckets]: {
        next(state, {payload}) {
            const {type, buckets} = payload;
            const newState = {
                ...state.toJS(),
                [type]: buckets,
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.getBuckets]: {
        next(state, data) {
            const {payload, error, pending} = data;
            if (error) {
                return state.merge({error, pending});
            }
            if (pending) {
                return state.merge({pending});
            }
            if (payload === null) {
                return state;
            }
            const {buckets, type} = payload;
            const sortedBuckets = buckets.sort((a, b) => a.order - b.order);
            const newState = {
                ...state.toJS(),
                [type]: sortedBuckets,
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.saveBucket]: {
        next(state, data) {
            const {payload, error, pending} = data;
            if (error) {
                return state.merge({error, pending});
            }
            if (pending) {
                return state.merge({pending});
            }
            if (payload === null) {
                return state;
            }
            const {id, type, order} = payload;
            let newList = fromJS([]);
            if (id) {
                newList = state.getIn([type]).set(order, payload);
            } else {
                newList = state.getIn([type]).push(payload);
            }
            const newState = {
                ...state.toJS(),
                [type]: newList.toJS(),
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.deleteBucket]: {
        next(state, data) {
            const {payload, error, pending} = data;
            if (error) {
                return state.merge({error, pending});
            }
            if (pending) {
                return state.merge({pending});
            }
            if (payload === null) {
                return state;
            }
            const {bucketId, type} = payload;
            const buckets = state.getIn([type]);
            const newList = buckets.filter((b) => b.get('id') !== bucketId);
            const newState = {
                ...state.toJS(),
                [type]: newList.toJS(),
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.changeBucketOrder]: {
        next(state, data) {
            const {payload, error, pending} = data;
            if (error) {
                return state.merge({error, pending});
            }
            if (pending) {
                return state.merge({pending});
            }
            if (payload === null) {
                return state;
            }
            const {bucketId, direction, type} = payload;
            const buckets = state.getIn([type]);
            const bucket = buckets.find((b) => b.get('id') === bucketId);
            let newList = buckets.filter((b) => b.get('id') !== bucketId);
            const index = bucket.get('order');
            if (direction === 'up') {
                bucket.set('order', index - 1);
                newList = newList.splice(index - 1, 0, bucket);
            } else {
                bucket.set('order', index + 1);
                newList = newList.splice(index + 1, 0, bucket);
            }
            const newState = {
                ...state.toJS(),
                [type]: newList.toJS(),
            };
            return fromJS(newState);
        },
    },
};

export const bucketReducer = handleActions(actions, initialState);
