import {BEGIN, COMMIT, REVERT} from 'redux-optimist';
import {ActionTypes} from '../constants/action-types';

function isPromise(val) {
    return val && typeof val.then === 'function';
}

let nextTransactionID = 0;
export default function promiseMiddleware(params) {
    const {dispatch} = params;
    return (next) => (action) => {
        const extendedAction = {
            ...action,
            rootReducer: params.getState(),
        };
        if (action.pending) {
            return next(extendedAction);
        }

        if (isPromise(action.payload)) {
            // eslint-disable-next-line
            const transactionID = nextTransactionID++;
            dispatch({
                type: action.type,
                payload: null,
                error: null,
                pending: true,
                meta: action.meta,
                optimist: {type: BEGIN, id: transactionID},
            });

            return action.payload
                .then((result) =>
                    dispatch({
                        ...action,
                        payload: result,
                        pending: false,
                        optimist: {type: COMMIT, id: transactionID},
                    })
                )
                .catch((error) => {
                    const {response = {}} = error;
                    const index = -1;
                    if (index === -1) {
                        dispatch({
                            type: ActionTypes.API_FAIL,
                            error: response.data,
                            optimist: {type: REVERT, id: transactionID},
                        });
                    }
                    return dispatch({
                        ...action,
                        payload: null,
                        error: response.data,
                        pending: false,
                        meta: action.meta,
                    });
                });
        }
        return next(extendedAction);
    };
}
