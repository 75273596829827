import {ActionTypes} from '../constants/action-types';
import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';

const initialState = fromJS({
    estimatedProbability: {},
    connectionStrength: {},
});

export const actions = {
    [ActionTypes.getEstimatedProbability]: {
        next(state, data) {
            const {payload, error, pending} = data;
            if (error) {
                return state.merge({error, pending});
            }
            if (pending) {
                return state.merge({pending});
            }
            if (payload === null) {
                return state;
            }
            const newState = {
                ...state.toJS(),
                pending: false,
                estimatedProbability: payload,
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.getConnectionStrength]: {
        next(state, data) {
            const {payload, error, pending} = data;
            if (error) {
                return state.merge({error, pending});
            }
            if (pending) {
                return state.merge({pending});
            }
            if (payload === null) {
                return state;
            }
            const newState = {
                ...state.toJS(),
                pending: false,
                connectionStrength: payload,
            };
            return fromJS(newState);
        },
    },
};

export const scoring = handleActions(actions, initialState);
