import {ActionTypes} from '../constants/action-types';
import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';
import {simpleReducer} from './utils';

const initialState = fromJS({
    error: {},
});

export const actions = {
    [ActionTypes.API_FAIL]: simpleReducer('error'),
};

export const errorReducer = handleActions(actions, initialState);
