import {CheckIcon} from '@heroicons/react/20/solid';
import {Disclosure, DisclosureButton, DisclosurePanel} from '@headlessui/react';
import {
    ArrowPathIcon,
    ChartBarSquareIcon,
    FingerPrintIcon,
    MinusIcon,
    PlusIcon,
} from '@heroicons/react/24/outline';
import JiraTaskIcon from '../JiraTaskIcon';

const features = [
    {
        name: 'Build your CRM directly in Jira',
        description:
            'Type CRM allows your teams to build one or multiple funnels in Jira: Track contacts, companies, and deals via your pipeline Capture activities & interaction Visualize opportunities and deals directly within Jira issues.',
        icon: JiraTaskIcon,
    },
    {
        name: 'Easily automate your CRM’s workflows',
        description:
            'Increase your team’s efficiency through automation and integrations. Create automation rules based on triggers and conditions. Integrate to Slack, Google, and more! Sync Jira Service Management customers & organizations.',
        icon: ArrowPathIcon,
    },
    {
        name: 'Realtime reports and dashboards',
        description:
            'Create a dashboard and visualize your most important stats.Quickly set up powerful charts to visualize metrics, and get snapshots of your sales & goals Stay up-to-date with the latest information through real-time data.',
        icon: ChartBarSquareIcon,
    },
    {
        name: 'JQL integration',
        description:
            'Because Type CRM links issues to customers, contacts, and sales, Custom JQL makes it easy to query Jira based on these relationships. Whether you’re searching for support tickets tied to high-value clients or tracking issues related to a specific sales stage, Custom JQL provides a direct way to surface relevant work items. This integration enhances your ability to manage tasks more effectively by incorporating your CRM data into Jira searches and filters.',
        icon: FingerPrintIcon,
    },
];
const tiers = [
    {
        name: 'Starter',
        id: 'tier-starter',
        href: '/app/login',
        priceMonthly: '$1',
        description: 'The essentials to provide your best work for clients.',
        features: ['CRM', 'Jira & JSM', 'Google & Outlook'],
        mostPopular: false,
        callus: false,
    },
    {
        name: 'Professional',
        id: 'tier-professional',
        href: '/app/login',
        priceMonthly: '$4.99',
        description: 'A plan that scales with your rapidly growing business.',
        features: [
            'Starter plan features',
            'Automations',
            'Data enrichments',
            'Chrome extensions',
            'Mobile app',
            'Advancing data report',
        ],
        mostPopular: true,
        callus: false,
    },
    {
        name: 'Enterprise',
        id: 'tier-enterprise',
        href: '/app/login',
        priceMonthly: 'Contact us',
        description: 'Dedicated support and infrastructure for your company.',
        features: [
            'Professional plan features',
            'SSO',
            'Custom features',
            '1-hour, dedicated support response time',
            'Advanced reporting',
        ],
        mostPopular: false,
        callus: true,
    },
];
const faqs = [
    {
        id: 1,
        question: 'How does cloud app pricing work?',
        answer:
            "Cloud apps are sold as a monthly or annual subscription. You are eligible for support and automatic version updates as long as your subscription is active. When your subscription renews each month, you are automatically billed for apps based on the number of users in your instance. If app pricing changes after your initial purchase, there\'s a 60-day grandfathering period during which you can renew based on the old pricing.",
    },
    {
        id: 2,
        question: 'Does TypeCRM have a free trial?',
        answer:
            "If app pricing changes after your initial purchase, there's a 60-day grandfathering period during which you can renew based on the old pricing. For cloud apps, you cannot extend your free evaluation period. All cloud apps are immediately subscribed by a user, and we provide a free evaluation period. This is a minimum of 30 days and ends on the second billing cycle after you first subscribe to the app.",
    },
    {
        id: 3,
        question: 'Is TypeCRM GDPR complaint?',
        answer:
            "Maintaining GDPR standards.",
    },
    {
        id: 4,
        question: 'Do you offer academic, community, or open-source licenses for cloud apps?',
        answer:
            "Yes. Academic, community, and open source licenses are available to qualifying organizations. See our Purchasing & Licensing FAQ for more information.",
    },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const Home = () => {
    return (
        <>
            {/* Hero section */}
            <div className="relative pt-14" id={'home'}>
                <div
                    aria-hidden="true"
                    className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                >
                    <div
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                        className="relative left-[calc(50%-11rem)] aspect-1155/678 w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-linear-to-tr from-[#2b7fff] to-[#155dfc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                    />
                </div>
                <div className="py-24 sm:py-32 lg:pb-40">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <div className="mx-auto max-w-2xl text-center">
                            <h1 className="text-5xl font-semibold tracking-tight text-balance text-gray-900 sm:text-7xl">
                                The CRM that scales your business.
                            </h1>
                            <p className="mt-8 text-lg font-medium text-pretty text-gray-500 sm:text-xl/8">
                                TypeCRM gives you complete control and flexibility to build the
                                perfect CRM that drives revenue forward.
                            </p>
                            <div className="mt-10 flex items-center justify-center gap-x-6">
                                <a
                                    href="https://marketplace.atlassian.com/apps/1232791?tab=overview&hosting=cloud"
                                    className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-xs hover:bg-blue-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Start for free
                                </a>
                                <a href="#" className="text-sm/6 font-semibold text-gray-900">
                                    Learn more <span aria-hidden="true">→</span>
                                </a>
                            </div>
                        </div>
                        <div className="mt-16 flow-root sm:mt-24">
                            <div className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-gray-900/10 ring-inset lg:-m-4 lg:rounded-2xl lg:p-4">
                                <img
                                    alt="App screenshot"
                                    src="/static/images/landing/landing_deals.png"
                                    width={2432}
                                    height={1442}
                                    className="rounded-md ring-1 shadow-2xl ring-gray-900/10"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    aria-hidden="true"
                    className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                >
                    <div
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                        className="relative left-[calc(50%+3rem)] aspect-1155/678 w-[36.125rem] -translate-x-1/2 bg-linear-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                    />
                </div>
            </div>

            {/* Logo cloud */}
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-5">
                    <img
                        alt="datamart"
                        src="/static/images/landing/clients/datamart.png"
                        width={158}
                        height={48}
                        className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                    />
                    <img
                        alt="shipshap"
                        src="/static/images/landing/clients/shipshap.png"
                        width={158}
                        height={48}
                        className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                    />
                    <img
                        alt="simpli"
                        src="/static/images/landing/clients/simpli-fi.webp"
                        width={158}
                        height={48}
                        className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                    />
                    <img
                        alt="EverIt"
                        src="/static/images/landing/clients/everit.png"
                        width={158}
                        height={48}
                        className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
                    />
                    <img
                        alt="dealersgpt"
                        src="/static/images/landing/clients/dealersgpt.png"
                        width={158}
                        height={48}
                        className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
                    />
                    <img
                        alt="surelockkey"
                        src="/static/images/landing/clients/surelockkey.png"
                        width={158}
                        height={48}
                        className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
                    />
                </div>
            </div>

            {/* Feature section */}
            <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-56 lg:px-8" id={'features'}>
                <div className="mx-auto max-w-2xl lg:text-center">
                    <p className="mt-2 text-4xl font-semibold tracking-tight text-pretty text-gray-900 sm:text-5xl lg:text-balance">
                        The tools for all your needs
                    </p>
                    <p className="mt-6 text-lg/8 text-pretty text-gray-600">
                        Powerful, flexible and data-driven, TypeCRM makes it easy to build the exact
                        CRM you need.
                    </p>
                </div>
                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                        {features.map((feature) => (
                            <div key={feature.name} className="relative pl-16">
                                <dt className="text-base/7 font-semibold text-gray-900">
                                    <div className="absolute top-0 left-0 flex size-10 items-center justify-center rounded-lg bg-gray-100">
                                        <feature.icon
                                            aria-hidden="true"
                                            className="size-6 text-blue-600"
                                        />
                                    </div>
                                    {feature.name}
                                </dt>
                                <dd className="mt-2 text-base/7 text-gray-600">
                                    {feature.description}
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>

            {/* Testimonial section */}
            <div className="mx-auto mt-32 max-w-7xl sm:mt-56 sm:px-6 lg:px-8" id={'testimonials'}>
                <div className="relative overflow-hidden bg-gray-900 px-6 py-20 shadow-xl sm:rounded-3xl sm:px-10 sm:py-24 md:px-12 lg:px-20">
                    <img
                        alt=""
                        src="/static/images/landing/clients/everit.png"
                        className="absolute inset-0 size-full object-cover brightness-150 saturate-0"
                    />
                    <div className="absolute inset-0 bg-gray-900/90 mix-blend-multiply" />
                    <div
                        aria-hidden="true"
                        className="absolute -top-56 -left-80 transform-gpu blur-3xl"
                    >
                        <div
                            style={{
                                clipPath:
                                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                            }}
                            className="aspect-1097/845 w-[68.5625rem] bg-linear-to-r from-[#ffff] to-[#edebfe] opacity-[0.45]"
                        />
                    </div>
                    <div
                        aria-hidden="true"
                        className="hidden md:absolute md:bottom-16 md:left-[50rem] md:block md:transform-gpu md:blur-3xl"
                    >
                        <div
                            style={{
                                clipPath:
                                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                            }}
                            className="aspect-1097/845 w-[68.5625rem] bg-linear-to-r from-[#ffff] to-[#2b7fff] opacity-25"
                        />
                    </div>
                    <div className="relative mx-auto max-w-2xl lg:mx-0">
                        <img
                            alt=""
                            src="/static/images/landing/clients/everit.png"
                            className="h-12 w-auto"
                        />
                        <figure>
                            <blockquote className="mt-6 text-lg font-semibold text-white sm:text-xl/8">
                                <p>
                                    “The team is extremely receptive to developing new ideas! We’ve
                                    been working together to implement our use case of connecting
                                    Hubspot and Mailchimp to Jira to automatically follow up with
                                    demo leads. The CRM is only getting better so worth looking
                                    into.”
                                </p>
                            </blockquote>
                            <figcaption className="mt-6 text-base text-white">
                                <div className="font-semibold">Daniel B</div>
                                <div className="mt-1">Marketing Manager of EverIT</div>
                            </figcaption>
                        </figure>
                    </div>
                </div>
            </div>

            {/* Pricing section */}
            <div className="py-24 sm:pt-48" id={'pricing'}>
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:text-center">
                        <h2 className="text-base/7 font-semibold text-indigo-600">Pricing</h2>
                        <p className="mt-2 text-4xl font-semibold tracking-tight text-pretty text-gray-900 sm:text-5xl lg:text-balance">
                            Pricing that grows with you
                        </p>
                        <p className="mt-6 text-lg/8 text-pretty text-gray-600">
                            Designed for every stage of your journey. Start today, free for 10
                            users.
                        </p>
                    </div>
                    <div className="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        {tiers.map((tier, tierIdx) => (
                            <div
                                key={tier.id}
                                className={classNames(
                                    tier.mostPopular ? 'lg:z-10 lg:rounded-b-none' : 'lg:mt-8',
                                    tierIdx === 0 ? 'lg:rounded-r-none' : '',
                                    tierIdx === tiers.length - 1 ? 'lg:rounded-l-none' : '',
                                    'flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10'
                                )}
                            >
                                <div>
                                    <div className="flex items-center justify-between gap-x-4">
                                        <h3
                                            id={tier.id}
                                            className={classNames(
                                                tier.mostPopular
                                                    ? 'text-indigo-600'
                                                    : 'text-gray-900',
                                                'text-lg/8 font-semibold'
                                            )}
                                        >
                                            {tier.name}
                                        </h3>
                                        {tier.mostPopular ? (
                                            <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs/5 font-semibold text-indigo-600">
                                                Most popular
                                            </p>
                                        ) : null}
                                    </div>
                                    <p className="mt-4 text-sm/6 text-gray-600">
                                        {tier.description}
                                    </p>
                                    <p className="mt-6 flex items-baseline gap-x-1">
                                        <span className="text-4xl font-semibold tracking-tight text-gray-900">
                                            {tier.priceMonthly}
                                        </span>
                                        {!tier.callus && (
                                            <span className="text-sm/6 font-semibold text-gray-600">
                                                /seat/month
                                            </span>
                                        )}
                                    </p>
                                    <ul
                                        role="list"
                                        className="mt-8 space-y-3 text-sm/6 text-gray-600"
                                    >
                                        {tier.features.map((feature) => (
                                            <li key={feature} className="flex gap-x-3">
                                                <CheckIcon
                                                    aria-hidden="true"
                                                    className="h-6 w-5 flex-none text-indigo-600"
                                                />
                                                {feature}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <a
                                    href={tier.href}
                                    aria-describedby={tier.id}
                                    className={classNames(
                                        tier.mostPopular
                                            ? 'bg-indigo-600 text-white shadow-xs hover:bg-indigo-500'
                                            : 'text-indigo-600 ring-1 ring-indigo-200 ring-inset hover:ring-indigo-300',
                                        'mt-8 block rounded-md px-3 py-2 text-center text-sm/6 font-semibold focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                                    )}
                                >
                                    Buy plan
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* FAQs */}
            <div
                className="mx-auto max-w-2xl px-6 pb-8 sm:pt-12 sm:pb-24 lg:max-w-7xl lg:px-8 lg:pb-32"
                id={'FAQs'}
            >
                <h2 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                    Frequently asked questions
                </h2>
                <dl className="mt-16 divide-y divide-gray-900/10">
                    {faqs.map((faq) => (
                        <Disclosure
                            key={faq.question}
                            as="div"
                            className="py-6 first:pt-0 last:pb-0"
                        >
                            <dt>
                                <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                                    <span className="text-base/7 font-semibold">
                                        {faq.question}
                                    </span>
                                    <span className="ml-6 flex h-7 items-center">
                                        <PlusIcon
                                            aria-hidden="true"
                                            className="size-6 group-data-open:hidden"
                                        />
                                        <MinusIcon
                                            aria-hidden="true"
                                            className="size-6 group-not-data-open:hidden"
                                        />
                                    </span>
                                </DisclosureButton>
                            </dt>
                            <DisclosurePanel as="dd" className="mt-2 pr-12">
                                <p className="text-base/7 text-gray-600">{faq.answer}</p>
                            </DisclosurePanel>
                        </Disclosure>
                    ))}
                </dl>
            </div>

            {/* CTA section */}
            <div className="relative -z-10 mt-32 px-6 lg:px-8">
                <div
                    aria-hidden="true"
                    className="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-1/2 transform-gpu justify-center overflow-hidden blur-3xl sm:top-auto sm:right-[calc(50%-6rem)] sm:bottom-0 sm:translate-y-0 sm:transform-gpu sm:justify-end"
                >
                    <div
                        style={{
                            clipPath:
                                'polygon(73.6% 48.6%, 91.7% 88.5%, 100% 53.9%, 97.4% 18.1%, 92.5% 15.4%, 75.7% 36.3%, 55.3% 52.8%, 46.5% 50.9%, 45% 37.4%, 50.3% 13.1%, 21.3% 36.2%, 0.1% 0.1%, 5.4% 49.1%, 21.4% 36.4%, 58.9% 100%, 73.6% 48.6%)',
                        }}
                        className="aspect-1108/632 w-[69.25rem] flex-none bg-linear-to-r from-[#2b7fff] to-[#155dfc] opacity-25"
                    />
                </div>
                <div className="mx-auto max-w-2xl text-center">
                    <h2 className="text-4xl font-semibold tracking-tight text-balance text-gray-900 sm:text-5xl">
                        Boost your productivity. Start using our app today.
                    </h2>
                    <p className="mx-auto mt-6 max-w-xl text-lg/8 text-pretty text-gray-600">
                        The first Automation-Powered CRM fully integrated in Jira. Track Contacts
                        and deals into pipelines or Jira issues and get all your customer insights
                        easily.
                    </p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <a
                            href="https://marketplace.atlassian.com/apps/1232791?tab=overview&hosting=cloud"
                            className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-xs hover:bg-indigo-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Get started
                        </a>
                        <a href="#" className="text-sm/6 font-semibold text-gray-900">
                            Learn more <span aria-hidden="true">→</span>
                        </a>
                    </div>
                </div>
                <div
                    aria-hidden="true"
                    className="absolute top-full right-0 left-1/2 -z-10 hidden -translate-y-1/2 transform-gpu overflow-hidden blur-3xl sm:block"
                >
                    <div
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                        className="aspect-1155/678 w-[72.1875rem] bg-linear-to-tr from-[#2b7fff] to-[#9089fc] opacity-30"
                    />
                </div>
            </div>
        </>
    );
};

export default Home;
