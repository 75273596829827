import axios from 'axios';
import {getMetaContent, requests} from './apiUtils';
import {currentSession} from './SessionApi';
import {isFromEU} from './EuropeUtils';

export const getUserTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getApiBaseUrl = () => {
    if (isGmailContext()) {
        return window.baseApiUrl;
    }
    if (isFromEU()) {
        return getMetaContent('baseApiEuUrl');
    }
    return getMetaContent('baseApiUrl');
};

export const getPluginKey = () => getMetaContent('pluginKey');

export const getTenantId = () => getMetaContent('tenantId') || '';

export const isGmailContext = () => {
    return !!window.inboxSdk;
};

export const isJiraContext = () => {
    if (isGmailContext()) {
        return false;
    }
    return !!(window.AP && window.AP.context);
};

export const accessToken = async () => {
    if (isJiraContext()) {
        return await window.AP.context.getToken();
    } else {
        const token = getMetaContent('atlassianConnectToken') || '';
        if (token != '') {
            return token;
        }
        const authState = await getCurrentAuthState();
        return authState.jwt;
    }
};

export async function getCurrentAuthState() {
    const session = await currentSession<Record<string, any>>();
    const {jwt = '', id = '', tenantId = getTenantId(), ecosystem} = session || {};
    return {jwt, id, tenantId, ecosystem};
}

async function getHeaders() {
    const token = await accessToken();
    if (token === 'anonymous' || token == undefined) {
        return {};
    }
    return {
        'X-Workspace-Id': `${window.workspaceId || ''}`,
        'X-User-Tz': getUserTimeZone(),
        'X-User-Language': window.navigator.language,
        NeedCheckSaleCRMLicense: window.needCheckSaleCRMLicense,
        Authorization: `JWT ${token}`,
    };
}

export async function get({path, params}: any) {
    return requests({
        method: 'GET',
        url: `${getApiBaseUrl()}${path}`,
        headers: await getHeaders(),
        params,
    });
}

export async function post({path, data}: any) {
    return requests({
        method: 'POST',
        data,
        url: `${getApiBaseUrl()}${path}`,
        headers: await getHeaders(),
    });
}

export async function upload({path, data}: any) {
    const headers = await getHeaders();
    const config = {
        headers: {
            ...headers,
        },
    };
    return axios.post(`${getApiBaseUrl()}${path}`, data, config);
}

export async function patch({path, data}: any) {
    return requests({
        method: 'PATCH',
        url: `${getApiBaseUrl()}${path}`,
        headers: await getHeaders(),
        data,
    });
}

export async function put({path, data}: any) {
    return requests({
        method: 'PUT',
        data,
        url: `${getApiBaseUrl()}${path}`,
        headers: await getHeaders(),
    });
}

export async function remove({path, data}: any) {
    return requests({
        method: 'DELETE',
        data,
        url: `${getApiBaseUrl()}${path}`,
        headers: await getHeaders(),
    });
}

export function getAppUrl() {
    if (isJiraContext()) {
        return `/plugins/servlet/ac/${getPluginKey()}`;
    }
    return '';
}

export function getAppHome() {
    if (isJiraContext()) {
        return `${getAppUrl()}/workspaces`;
    }
    return '';
}

export function getJirItemUrl({
    workspaceId,
    view = 'BOARD',
    type = 'SALE',
    suffix = '',
    itemId = '',
    key = 'Deals',
}) {
    if (itemId == '') {
        return `${getAppUrl()}/workspace?ac.workspaceId=${workspaceId}&ac.view=${view}&ac.type=${type}&ac.suffix=${suffix}&ac.key=${key}`;
    }
    return `${getAppUrl()}/item?ac.workspaceId=${workspaceId}&ac.itemId=${itemId}&ac.type=${type}&ac.key=${key}&ac.view=${view}`;
}
