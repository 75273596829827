import {ActionTypes} from '../constants/action-types';
import {createAction} from 'redux-actions';
import {saveBucket, deleteBucket, getBuckets, changeBucketOrder} from '../api/ProjectApi';

const api = {
    [ActionTypes.saveBucket]: (bucket) => saveBucket(bucket),
    [ActionTypes.setUpBuckets]: (type, buckets) => ({type, buckets}),
    [ActionTypes.changeBucketOrder]: (bucketOrderData) => changeBucketOrder(bucketOrderData),
    [ActionTypes.deleteBucket]: (bucketId, type, setUp) => deleteBucket(bucketId, type, setUp),
    [ActionTypes.getBuckets]: (projectId, type) => getBuckets(projectId, type),
};

const actions: any = {...api};

Object.keys(actions).forEach((type) => {
    actions[type] = createAction(type, actions[type]);
});

export default actions;
