import {ActionTypes} from '../constants/action-types';
import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';

const initialState = fromJS({
    suggestedContact: {},
});

export const actions = {
    [ActionTypes.getContactInfoByIssue]: {
        next(state, {payload, error, pending}) {
            if (error) {
                return state.merge({error, pending});
            }
            if (pending) {
                return state.merge({pending});
            }
            if (payload == null || payload === undefined) {
                return state.merge({pending, error});
            }
            const {fields = {}} = payload;
            const {reporter = {accountType: ''}} = fields;

            const newState = {
                ...state.toJS(),
                pending: false,
                suggestedContact: reporter,
            };
            return fromJS(newState);
        },
    },
};

export const contact = handleActions(actions, initialState);
