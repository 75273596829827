import {get, post, put, remove} from './api';

export const getLabels = async (workspaceId: string, type: string): Promise<any> => {
    const response = await get({path: `/workspaces/${workspaceId}/labels/${type}`});
    return {labels: response.data, type, workspaceId};
};

export const saveLabel = async (formData): Promise<any> => {
    if (formData.id) {
        const response = await put({path: `/labels/${formData.id}`, data: formData});
        return response.data;
    }
    const response = await post({path: `/labels`, data: formData});
    return response.data;
};

export const updateLabel = async (labelId, formData): Promise<any> => {
    const response = await put({path: `/labels/${labelId}`, data: formData});
    return response.data;
};

export const deleteLabel = async (labelId): Promise<any> => {
    const response = await remove({path: `/labels/${labelId}`});
    return response.data;
};
