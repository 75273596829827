import {get, post, put, remove} from './api';

export const getMyFilters = async (workspaceId: string, contentType): Promise<any> => {
    const {data = []} = await get({path: `/workspaces/${workspaceId}/my-filters`});
    return {filters: data, workspaceId, contentType};
};

export const saveFilter = async (formData): Promise<any> => {
    if (formData.id) {
        const response = await put({path: `/filters/${formData.id}`, data: formData});
        return response.data;
    }
    const response = await post({path: `/filters`, data: formData});
    return response.data;
};

export async function updateMyFilterSettings(myFilterSettings) {
    const response = await put({path: `/users/me/filter-settings`, data: myFilterSettings});
    return response.data;
}

export const deleteFilter = async (filterId): Promise<any> => {
    const response = await remove({path: `/filters/${filterId}`});
    return response.data;
};
