import styled from 'styled-components';
import {useEffect, useState} from 'react';

export const TabItemStyle = styled.li`
    padding-right: 2px;
    cursor: pointer;
    display: flex;
    &:hover {
        background-color: rgb(244, 244, 244);
    }
`;
export const TabStyle = styled.ul`
    list-style: none;
    display: flex;
`;

export type TabItemProps = {
    text: any;
    onClick: (text: string) => void;
    active: boolean;
    value: string;
    icon?: any;
    fontSize: number;
};

export type TabItemType = {
    key: string;
    value: any;
    icon?: any;
};

export type TabProps = {
    items: TabItemType[];
    value: string;
    icon?: any;
    fontSize?: number;
    onTabChange: (text: string) => void;
    rightAction?: any;
};

const TabItem = ({text, active, onClick, value, icon, fontSize}: TabItemProps) => {
    const onTabItemClick = () => onClick && onClick(value);
    return (
        <TabItemStyle
            style={{
                fontSize: fontSize,
                borderBottom: active ? '1.5px solid #2563eb' : 'transparent',
                fontWeight: active ? 500 : 400,
            }}
            onClick={onTabItemClick}
        >
            {icon}
            {text}
        </TabItemStyle>
    );
};

const Tab = ({items, value, onTabChange, fontSize = 16, rightAction = null, icon}: TabProps) => {
    const [activeTab, setActiveTab] = useState(value);

    useEffect(() => {
        setActiveTab(value);
    }, [value]);

    const onTabItemClick = (text: string) => {
        setActiveTab(text);
        onTabChange && onTabChange(text);
    };

    return (
        <TabStyle className={'space-x-1 flex w-full'}>
            <div className={'flex w-full'}>
                {items.map((item: TabItemType, index: number) => (
                    <TabItem
                        key={`${item.key}-${index}`}
                        text={item.value}
                        onClick={onTabItemClick}
                        value={item.key}
                        icon={item.icon}
                        active={activeTab === item.key}
                        fontSize={fontSize}
                    />
                ))}
            </div>
            <div className={'flex align-middle'}>{rightAction}</div>
        </TabStyle>
    );
};

export default Tab;
