import {ActionTypes, DialogTypes} from '../constants/action-types';
import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';
import {simpleReducer} from './utils';

const groupByDefault = 'bucketId';

export const defaultTask = {
    data: {
        name: {
            value: '',
        },
        bucketId: {
            value: '',
        },
        start: {
            value: new Date(),
        },
    },
};

export const initialState = fromJS({
    tasks: [],
    childrenOrders: [],
    pending: true,
    task: defaultTask,
    taskWorkflow: {
        workflow: {
            duration: 0,
            effortCompleted: 0,
            effortRemaining: 0,
            effortTotal: 0,
        },
    },
    isTaskReady: false,
    hoveringTask: {
        hovering: false,
    },
    groupBy: groupByDefault,
    [groupByDefault]: [],
    allAssignees: [],
    showTaskDialog: {
        type: DialogTypes.mainDialog,
        show: false,
    },
    GRID: {
        children: [],
        childrenOrders: [],
    },
    TIMELINE: {
        children: [],
        childrenOrders: [],
    },
    BOARD: {
        children: [],
        childrenOrders: [],
    },
    CHART: {
        children: [],
        childrenOrders: [],
    },
    MAP: {
        children: [],
        childrenOrders: [],
    },
    pagination: {
        first: true,
        number: 0,
        size: 50,
    },
    filters: {},
    hasFilter: false,
    pageResultIds: [],
    selectionIds: [],
    sorts: [],
});

const doGroupBy = (tasks, groupById) => {
    return tasks.groupBy((task) => task.getIn(['data', groupById, 'value'])).toJS();
};

const handleTaskFieldUpdate = (state, {taskId, fieldId, fieldValue, view, item}) => {
    const tasks = state.getIn([view, 'children']) || state.get('tasks');
    const task = tasks.find((item) => item.get('id') === taskId) || state.getIn(['task']);
    const type = task.getIn(['type']);
    const currentTask = task.setIn(['data', fieldId, 'value'], fieldValue);
    let transformedTask = currentTask.toJS();
    const isNewTask = currentTask.get('newTask');
    const childrenOrders = state.getIn([view, 'childrenOrders']) || fromJS([]);
    let newTaskList = tasks.toJS();
    if (isNewTask) {
        newTaskList.push(item);
    } else {
        const taskIndex = tasks.findIndex((item) => item.get('id') === taskId);
        newTaskList = tasks.setIn([taskIndex], item).toJS();
        transformedTask = item;
    }
    return fromJS({
        ...state.toJS(),
        isTaskReady: true,
        pending: false,
        [type]: {
            task: transformedTask,
        },
        [view]: {
            children: newTaskList,
            childrenOrders: childrenOrders.toJS(),
        },
        tasks: newTaskList,
        task: transformedTask,
    });
};

export const actions = {
    [ActionTypes.createItem]: simpleReducer('task'),
    [ActionTypes.getItem]: {
        next(state, {payload, error, pending}) {
            if (error || payload == null) {
                return state.merge({error, pending: false});
            }
            if (pending) {
                return state.merge({pending});
            }

            const newState = {
                ...state.toJS(),
                [payload.type]: {
                    task: payload,
                },
                task: payload,
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.searchItems]: {
        next(state, {payload, error, pending}) {
            if (error || payload == null) {
                return state.merge({error, pending: false});
            }
            if (pending) {
                return state.merge({pending});
            }
            const {data, view} = payload;
            const {
                childrenOrders = [],
                pageResult,
                pageResultIds = [],
                hasFilter,
                sorts = [],
            } = data;
            const {content = []} = pageResult;
            const items = content;

            const newState = {
                ...state.toJS(),
                [view]: {
                    children: items,
                    childrenOrders,
                },
                tasks: items,
                childrenOrders,
                pending: false,
                pagination: {
                    ...pageResult,
                    content: [],
                },
                pageResultIds,
                hasFilter,
                sorts,
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.groupBy]: {
        next(state, {payload}) {
            const {groupId} = payload;
            const groups = doGroupBy(state.get('tasks'), groupId);
            const newState = {...state.toJS(), groupBy: groupId, [groupId]: groups};
            return fromJS(newState);
        },
    },
    [ActionTypes.selectItem]: {
        next(state, {payload}) {
            const {itemId} = payload;
            const selectionIds = state.get('selectionIds');
            if (!selectionIds.includes(itemId)) {
                const newList = selectionIds.push(itemId);
                return state.set('selectionIds', newList);
            }
            const index = selectionIds.indexOf(itemId);
            const newList = selectionIds.delete(index);
            return state.set('selectionIds', newList);
        },
    },
    [ActionTypes.selectAllItems]: {
        next(state, {payload}) {
            const {view, selected} = payload;
            const selectionIds = state.get('selectionIds');
            if (!selected) {
                return state.set('selectionIds', selectionIds.clear());
            }
            const itemIds = state.getIn([view, 'children']).map((item) => item.get('id'));
            return state.set('selectionIds', itemIds);
        },
    },
    [ActionTypes.changeTaskFilter]: {
        next(state, {payload}) {
            const {filters} = payload;
            const newState = {...state.toJS(), filters};
            return fromJS(newState);
        },
    },
    [ActionTypes.changeFilterString]: {
        next(state, {payload}) {
            const {filterString, searchMode} = payload;
            const newState = {...state.toJS(), filterString, searchMode};
            return fromJS(newState);
        },
    },
    [ActionTypes.showTaskDialog]: {
        next(state, {payload}) {
            const {task = {newTask: true}, dialogType} = payload;
            const newState = {
                ...state.toJS(),
                task,
                showTaskDialog: {
                    type: dialogType,
                    show: true,
                },
                [dialogType]: {
                    task,
                    show: true,
                    timestamp: '',
                },
                isTaskReady: false,
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.hideTaskDialog]: {
        next(state, {payload}) {
            const {dialogType} = payload;
            const newState = {
                ...state.toJS(),
                showTaskDialog: {
                    type: dialogType,
                    show: false,
                },
                [dialogType]: {
                    task: {},
                    show: false,
                    timestamp: `${Date.now()}`,
                },
                isTaskReady: false,
                task: {},
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.updateTask]: {
        next(state, {payload, pending}) {
            if (pending) {
                return state.merge({pending});
            }
            console.log('payload', payload);
            return handleTaskFieldUpdate(state, payload);
        },
    },
    [ActionTypes.deleteTask]: simpleReducer('task'),
};

export const taskReducer = handleActions(actions, initialState);
