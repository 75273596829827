import {ActionTypes} from '../constants/action-types';
import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';
import {simpleReducer} from './utils';

export const appList = [
    {
        app: 'jsm',
        name: 'Jira & Jira Service Management',
        description:
            'Sync Jira Service Management customers, organizations, and fields to Sales CRM.',
        imageUrl: '/static/images/jsm.svg',
        published: true,
        connected: false,
        hasSetting: true,
    },
    {
        app: 'hubspot',
        name: 'Hubspot',
        description:
            'Convert Hubspot Calendar meetings into Sales CRM. e.g: Client books a meeting in Hubspot. Then the meeting  is converted into a new deal in Sales CRM.',
        imageUrl: '/static/images/hubspot.png',
        published: true,
        connected: false,
        hasSetting: true,
    },
    {
        app: 'mailchimp',
        name: 'Mailchimp',
        description:
            'Sync Sales CRM contacts to Mailchimp. E.g: automatically add contacts to marketing campaigns.',
        imageUrl: '/static/images/mailchimp.svg',
        published: true,
        connected: false,
        hasSetting: true,
    },
    {
        app: 'slack',
        name: 'Slack',
        description:
            'Send notification to Slack about important events (new sale, assigned to user, move to a sale funnel...).',
        imageUrl: '/static/images/slack.svg',
        published: true,
        connected: false,
        hasSetting: false,
    },
    {
        app: 'import_csv',
        name: 'Import CSV',
        description: 'Import csv to contacts and accounts.',
        imageUrl: '/static/images/logo.svg',
        published: true,
        connected: false,
        hasSetting: true,
    },
    {
        app: 'google',
        name: 'Google (Gmail, Calendar)',
        description:
            'Link your Google Account to Sales CRM to capture additional customer interactions. Send emails from Sales CRM, Create Task reminders to your Google Calendar.',
        imageUrl: '/static/images/google.png',
        published: true,
        connected: false,
        hasSetting: true,
    },
    {
        app: 'zapier',
        name: 'Zapier Beta',
        description:
            'Zapier lets you connect Sales CRM with thousands of the most popular apps, so you can automate your work and have more time for what matters most—no code required.',
        imageUrl: '/static/images/zapier.png',
        published: false,
        connected: false,
        hasSetting: true,
    },
    {
        app: 'mobile',
        name: 'Mobile App',
        description:
            "Sales CRM app you can access your contacts, deal history and to-do's, create tasks and take meeting notes wherever you are - all changes are instantly synced to Sales CRM web app.",
        imageUrl: '/static/images/logo.svg',
        published: false,
        connected: false,
        hasSetting: true,
    },
    {
        app: 'azure',
        name: 'Outlook',
        description: 'Send email to customers by Microsoft Outlook.',
        imageUrl: '/static/images/outlook.jpg',
        published: true,
        connected: false,
        hasSetting: true,
    },
];

const initialState = fromJS({
    rules: [],
    appLinks: appList,
    pending: true,
    rule: {
        events: [],
        actions: [],
        enabled: true,
    },
    source: [
        {
            value: 'Advertisement',
            label: 'Advertisement',
        },
        {
            value: 'Employee Referral',
            label: 'Employee Referral',
        },
        {
            value: 'External Referral',
            label: 'External Referral',
        },
        {
            value: 'Partner',
            label: 'Partner',
        },
        {
            value: 'Public Relations',
            label: 'Public Relations',
        },
        {
            value: 'Seminar Internal',
            label: 'Seminar Internal',
        },
        {
            value: 'Seminar Partner',
            label: 'Seminar Partner',
        },
        {
            value: 'Trading Show',
            label: 'Trading Show',
        },
        {
            value: 'Web',
            label: 'Web',
        },
        {
            value: 'Word of mouth',
            label: 'Word of mouth',
        },
        {
            value: 'Hubspot',
            label: 'Hubspot',
        },
        {
            value: 'Jira',
            label: 'Jira',
        },
        {
            value: 'Other',
            label: 'Other',
        },
    ],
    industry: [
        {
            value: 'Agriculture',
            label: 'Agriculture',
        },
        {
            value: 'Apparel',
            label: 'Apparel',
        },
        {
            value: 'Banking',
            label: 'Banking',
        },
        {
            value: 'Biotechnology',
            label: 'Biotechnology',
        },
        {
            value: 'Chemicals',
            label: 'Chemicals',
        },
        {
            value: 'Communications',
            label: 'Communications',
        },
        {
            value: 'Construction',
            label: 'Construction',
        },
        {
            value: 'Consulting',
            label: 'Consulting',
        },
        {
            value: 'Education',
            label: 'Education',
        },
        {
            value: 'Electrics',
            label: 'Electrics',
        },
        {
            value: 'Engineering',
            label: 'Engineering',
        },
        {
            value: 'Environmental',
            label: 'Environmental',
        },
        {
            value: 'Finance',
            label: 'Finance',
        },
        {
            value: 'Food & Beverage',
            label: 'Food & Beverage',
        },
        {
            value: 'Healthcare',
            label: 'Healthcare',
        },
        {
            value: 'Hospitality',
            label: 'Hospitality',
        },
        {
            value: 'Insurance',
            label: 'Insurance',
        },
        {
            value: 'Machinery',
            label: 'Machinery',
        },
        {
            value: 'Media',
            label: 'Media',
        },
        {
            value: 'Not for Profit',
            label: 'Not for Profit',
        },
        {
            value: 'Other',
            label: 'Other',
        },
        {
            value: 'Recreation',
            label: 'Recreation',
        },
        {
            value: 'Retail',
            label: 'Retail',
        },
        {
            value: 'Shipping',
            label: 'Shipping',
        },
        {
            value: 'Technology',
            label: 'Technology',
        },
        {
            value: 'Telecommunications',
            label: 'Telecommunications',
        },
        {
            value: 'Transportation',
            label: 'Transportation',
        },
        {
            value: 'Utilities',
            label: 'Utilities',
        },
    ],
    status: [
        {
            value: 'Open',
            label: 'Open',
        },
        {
            value: 'Closed',
            label: 'Closed',
        },
    ],
    stage: [
        {
            value: 'Won',
            label: 'Won',
        },
        {
            value: 'Unqualified',
            label: 'Unqualified',
        },
        {
            value: 'Lost',
            label: 'Lost',
        },
    ],
    start: [
        {
            value: 'NOW',
            label: 'current date',
        },
        {
            value: 'TODAY',
            label: 'today',
        },
    ],
    finish: [
        {
            value: 'NOW',
            label: 'current date',
        },
        {
            value: 'TODAY',
            label: 'today',
        },
    ],
    bucketId: [],
    assignees: [],
    salutation: [
        {
            value: 'Mr',
            label: 'Mr',
        },
        {
            value: 'Mrs',
            label: 'Mrs',
        },
        {
            value: 'Miss',
            label: 'Miss',
        },
        {
            value: 'Ms',
            label: 'Ms',
        },
        {
            value: 'Dr',
            label: 'Dr',
        },
        {
            value: 'Others',
            label: 'Others',
        },
    ],
});

const isConnected = (apps, app) => {
    const item = apps.find((item) => item.type == app);
    return item ? item.connected : false;
};

export const actions = {
    [ActionTypes.saveRule]: simpleReducer('rule'),
    [ActionTypes.getRule]: simpleReducer('rule'),
    [ActionTypes.getProject]: {
        next(state, {payload, error, pending}) {
            if (error) {
                return state.merge({error, pending});
            }
            if (pending) {
                return state.merge({pending});
            }
            if (payload == null || payload === undefined) {
                return state.merge({pending, error, assignees: []});
            }
            const {members = []} = payload;
            const assignees = members.map((item) => ({
                value: item.accountId,
                label: item.displayName || item.name,
            }));
            const newState = {
                ...state.toJS(),
                assignees,
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.getBuckets]: {
        next(state, data) {
            const {payload, error, pending} = data;
            if (error) {
                return state.merge({error, pending});
            }
            if (pending) {
                return state.merge({pending});
            }
            if (payload === null) {
                return state;
            }
            const {buckets, type} = payload;
            const groups = buckets
                .sort((a, b) => a.order - b.order)
                .map((item) => ({label: item.name, value: item.id}));
            const newState = {
                ...state.toJS(),
                bucketId: groups,
            };
            return fromJS(newState);
            return state;
        },
    },
    [ActionTypes.getRules]: {
        next(state, {payload, error, pending}) {
            if (error) {
                return state.merge({error, pending});
            }
            if (pending) {
                return state.merge({pending});
            }
            if (payload === null) {
                return state;
            }
            const newState = {...state.toJS(), rules: payload};
            return fromJS(newState);
        },
    },
    [ActionTypes.getAppLinks]: {
        next(state, {payload, error, pending}) {
            if (error) {
                return state.merge({error, pending});
            }
            if (pending) {
                return state.merge({pending});
            }
            if (payload === null) {
                return state;
            }
            const appLinks = appList.map((item) => {
                return {
                    ...item,
                    connected: isConnected(payload, item.app),
                };
            });
            const newState = {...state.toJS(), appLinks};
            return fromJS(newState);
        },
    },
    [ActionTypes.deleteRule]: simpleReducer('rule'),
    [ActionTypes.setCurrentRule]: {
        next(state, {payload}) {
            const newState = {
                ...state.toJS(),
                rule: payload,
            };
            return fromJS(newState);
        },
    },
};

export const ruleReducer = handleActions(actions, initialState);
