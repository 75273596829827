import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill-mention/dist/quill.mention.css';
import Quill from 'quill';
import 'quill-mention/autoregister';
import './editor.css';
import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import LongTextViewer from './LongTextViewer';
const Block: any = Quill.import('blots/block');
Block.tagName = 'div';
Quill.register(Block);

const Editor = ({
    placeholder = 'Start by typing @ for mentions',
    editorContent = {htmlContent: '', contents: {}},
    showToolbar = true,
    viewMode = false,
    readOnly = false,
    onChange = (param: any) => {},
}) => {
    const editorRef = useRef(null);
    const editorContainerRef = useRef(null);
    const [text, setText] = useState('');
    const members = useSelector((state: any) => state.projectReducer.getIn(['project', 'members']));

    async function suggestPeople(searchTerm: string) {
        const allPeople = members
            .map((member: any) => {
                return {
                    id: member.get('accountId'),
                    value: member.get('displayName'),
                    type: 'user',
                };
            })
            .toJS();
        if (searchTerm.length === 0) {
            return allPeople;
        }

        return allPeople.filter((person: any) => person.value.includes(searchTerm));
    }

    const handleOnChange = (content: any) => {
        const data = {
            ...content,
            mentionedMembers: getMentionedMembers(content.contents),
        };
        onChange(data);
    };

    const getMentionedMembers = (contents = {ops: []}) => {
        const mentionOps = contents.ops.filter((op: any) => op.insert.mention);
        return mentionOps.map((op: any) => op.insert.mention);
    };

    const getEditorOptions = () => {
        if (viewMode) {
            return {
                readOnly: true,
                modules: {
                    toolbar: false,
                },
            };
        }
        if (showToolbar) {
            return {
                placeholder,
                theme: 'snow',
                readOnly,
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline'],
                        [{list: 'ordered'}, {list: 'bullet'}],
                        [{indent: '-1'}, {indent: '+1'}, 'link'],
                    ],
                    mention: {
                        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                        mentionDenotationChars: ['@'],
                        source: async (searchTerm: any, renderList: any, mentionChar: string) => {
                            if (mentionChar == '@') {
                                const matchedPeople = await suggestPeople(searchTerm);
                                renderList(matchedPeople, searchTerm);
                            }
                        },
                        dataAttributes: ['id', 'value', 'type'],
                    },
                },
            };
        }
        return {
            modules: {
                readOnly,
                toolbar: false,
            },
        };
    };

    useEffect(() => {
        if (editorContainerRef.current) {
            const quill = new Quill(editorContainerRef.current, getEditorOptions());
            const delta = quill.clipboard.convert({html: editorContent.htmlContent});
            quill.setContents(delta);
            setText(quill.getText());
            quill.on('text-change', () => {
                const htmlContent = quill.getSemanticHTML();
                const delta = quill.getContents();
                handleOnChange({htmlContent, contents: delta});
            });
            editorRef.current = quill;
        }
    }, [editorContainerRef]);

    if (viewMode) {
        if (editorContent.htmlContent == '') {
            return null;
        }
        return <LongTextViewer html={editorContent.htmlContent} />;
    }

    return (
        <div
            ref={editorContainerRef}
            className={'w-full min-h-20 justify-start'}
            style={{height: 'auto', maxWidth: 800}}
        />
    );
};
export default Editor;
