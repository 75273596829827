import {ActionTypes} from '../constants/action-types';
import {createAction} from 'redux-actions';
import {deleteFilter, getMyFilters, saveFilter, updateMyFilterSettings} from '../api/FilterApi';

const api = {
    [ActionTypes.saveFilter]: (filter) => saveFilter(filter),
    [ActionTypes.showFilterDialog]: ({data, contentType}) => ({data, contentType}),
    [ActionTypes.hideFilterDialog]: () => ({}),
    [ActionTypes.updateMyFilterSettings]: (filterSettings) =>
        updateMyFilterSettings(filterSettings),
    [ActionTypes.deleteFilter]: (filterId) => deleteFilter(filterId),
    [ActionTypes.getMyFilters]: (projectId, contentType) => getMyFilters(projectId, contentType),
};

const actions: any = {...api};

Object.keys(actions).forEach((type) => {
    actions[type] = createAction(type, actions[type]);
});

export default actions;
