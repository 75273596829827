import styled, {keyframes} from 'styled-components';

const colorDot = '#1a73e8';
const dotFlashing = keyframes`
0% {
  background-color: ${colorDot};
}
50%, 100% {
  background-color: rgba(152, 128, 255, 0.2);
}
`;

const LoadingContainer = styled.div<any>`
    z-index: 9999;
`;

const DotFlashing = styled.div<any>`
    position: relative;
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    border-radius: 5px;
    background-color: ${colorDot};
    color: ${colorDot};
    animation: ${dotFlashing} 1s infinite linear alternate;
    animation-delay: 0.5s;

    &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
        left: -15px;
        width: ${(props) => props.size}px;
        height: ${(props) => props.size}px;
        border-radius: 5px;
        background-color: ${colorDot};
        color: ${colorDot};
        animation: ${dotFlashing} 1s infinite alternate;
        animation-delay: 0s;
    }
    &:after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
        left: 15px;
        width: ${(props) => props.size}px;
        height: ${(props) => props.size}px;
        border-radius: 5px;
        background-color: ${colorDot};
        color: ${colorDot};
        animation: ${dotFlashing} 1s infinite alternate;
        animation-delay: 1s;
    }
`;

export const SmallLoading = () => {
    return <DotFlashing className="dot-flashing" size={6} />;
};

const Loading = () => {
    return (
        <LoadingContainer className="loading-test w-screen h-screen bg-transparent absolute top-0 left-0 flex align-middle justify-center items-center">
            <div className="w-32 h-20 shadow-md flex justify-center items-center z-30 bg-white rounded-sm">
                <DotFlashing className="dot-flashing" size={10} />
            </div>
            <div className="absolute z-20 bg-white opacity-50 top-0 left-0 w-screen h-screen"></div>
        </LoadingContainer>
    );
};
export default Loading;
