import {ActionTypes} from '../constants/action-types';
import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';
import {simpleReducer} from './utils';

const initialState = fromJS({
    projects: [],
    settings: [],
    pending: true,
    taskTypes: [],
    editMode: false,
    project: {
        fields: [],
        types: [],
        members: [],
        projects: [],
        setup: true,
        currency: 'USD',
        importStatus: false,
    },
});

export const actions = {
    [ActionTypes.createProject]: simpleReducer('project', {editMode: false}),
    [ActionTypes.updateProject]: {
        next(state, {payload, error, pending}) {
            if (error) {
                return state.merge({error, pending});
            }
            if (pending) {
                return state.merge({pending});
            }
            if (payload == null || payload === undefined) {
                return state.merge({pending, error});
            }
            const newState = {
                ...state.toJS(),
                importStatus: false,
                pending: false,
                project: payload,
                editMode: false,
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.updateProjectField]: {
        next(state, {payload}) {
            const {fieldId, fieldValue} = payload;
            const newState = {
                ...state.toJS(),
                project: {
                    ...state.get('project').toJS(),
                    [fieldId]: fieldValue,
                },
                editMode: true,
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.getProject]: {
        next(state, {payload, error, pending}) {
            if (error) {
                return state.merge({error, pending});
            }
            if (pending) {
                return state.merge({pending});
            }
            if (payload == null || payload === undefined) {
                return state.merge({pending, error});
            }
            const newState = {
                ...state.toJS(),
                project: payload,
                editingProject: payload,
                pending: false,
                editMode: false,
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.getProjects]: simpleReducer('projects', {editMode: false}),
    [ActionTypes.getMembers]: simpleReducer('members'),
    [ActionTypes.getFields]: simpleReducer('fields'),
};

export const projectReducer = handleActions(actions, initialState);
