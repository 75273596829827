import {ActionTypes} from '../constants/action-types';
import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';

const initialState = fromJS({
    shortMenu: false,
    notification: {
        title: '',
        message: '',
        action: '',
        type: 'success',
    },
});

export const actions = {
    [ActionTypes.setShortMenu]: {
        next(state, {payload}) {
            const newState = {...state.toJS(), shortMenu: payload};
            return fromJS(newState);
        },
    },
    [ActionTypes.API_FAIL]: {
        next(state, {error = {}}) {
            const newState = {
                ...state.toJS(),
                notification: {
                    message: error.message,
                    title: 'Error',
                    type: 'error',
                },
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.updateTask]: {
        next(state, {payload, pending}) {
            if (pending) {
                return state.merge({pending});
            }
            const {fieldId} = payload;
            const newState = {
                ...state.toJS(),
                notification: {
                    message: `${fieldId} field is updated.`,
                    title: 'Successfully saved',
                    action: ActionTypes.updateTask,
                    type: 'success',
                },
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.updateProjectField]: {
        next(state, {}) {
            const newState = {
                ...state.toJS(),
                notification: {
                    message: `Workspace is updated.`,
                    title: 'Successfully saved',
                    action: ActionTypes.updateProjectField,
                    type: 'success',
                },
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.saveFilter]: {
        next(state, {}) {
            const newState = {
                ...state.toJS(),
                notification: {
                    message: `Filter is saved.`,
                    title: 'Filter',
                    type: 'success',
                },
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.setNotification]: {
        next(state, {payload}) {
            const newState = {
                ...state.toJS(),
                notification: payload,
            };
            return fromJS(newState);
        },
    },
};

export const notification = handleActions(actions, initialState);
