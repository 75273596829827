import {get, post} from './api';
export async function searchMessages(query) {
    const {data} = await post({path: `/inbox/messages/search`, data: query});
    return data;
}

export async function getMessageThread(threadId: string) {
    const {data} = await get({path: `/inbox/threads/${threadId}`});
    return data;
}

export async function getMessageTracking(messageId: string) {
    const {data} = await get({path: `/inbox/messages/${messageId}/tracking`});
    return data;
}

export async function syncMessages() {
    const {data} = await post({path: `/inbox/messages/sync`, data: {}});
    return data;
}

export async function getAppData(appId) {
    const {data} = await get({path: `/apps/${appId}`});
    return {data, appId};
}
