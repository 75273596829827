import {ActionTypes} from '../constants/action-types';
import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';

const initialState = fromJS({
    pending: true,
    SALE: [],
    COMPANY: [],
    CONTACT: [],
});

export const actions = {
    [ActionTypes.getLabels]: {
        next(state, data) {
            const {payload, error, pending} = data;
            if (error) {
                return state.merge({error, pending});
            }
            if (pending) {
                return state.merge({pending});
            }
            if (payload === null) {
                return state;
            }
            const {labels, type} = payload;
            const newState = {
                ...state.toJS(),
                [type]: labels,
            };
            return fromJS(newState);
        },
    },
};

export const labelReducer = handleActions(actions, initialState);
