import {format, isValid} from 'date-fns';
import styled, {keyframes} from 'styled-components';
import {DayPicker} from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import Input from '../../../components/Input';
import DropdownLayout from '../../../components/DropdownLayout';
import {CalendarIcon} from '@heroicons/react/24/outline';
import React, {useEffect, useState} from 'react';

interface DatePickerPropsType {
    formatDate?: string;
    selected: string;
    disabled?: boolean;
    onChange: (date: string) => void;
    isGrid?: boolean;
    multiMonth?: boolean;
    allowRange?: boolean;
    placeholder?: string;
}

const cardLaneAnimation = keyframes`
 0% { transform: translate3d(0px, -48px, 0px) }
 100% { transform: translate3d(0px, 0px, 0px) }
`;
export const StyledDateContainer = styled.section`
    .rdp-day_selected {
        background-color: #2563eb;
    }
    .rdp-month {
        background-color: white;
    }
    .rdp {
        margin: 0 !important;
    }
    animation: 100ms cubic-bezier(0.1, 0.9, 0.2, 1) 0s 1 normal none running ${cardLaneAnimation};
`;

const DatePickerField = (props: DatePickerPropsType) => {
    const {selected, onChange, placeholder, disabled} = props;
    const [value, setValue] = useState(undefined);

    useEffect(() => {
        const selectedDate = selected ? new Date(selected) : undefined;
        const formattedValue = isValid(selectedDate) ? format(selectedDate, 'yyyy-MM-dd') : '';
        setValue(formattedValue);
    }, [selected]);

    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && !disabled) {
            submitValue();
        }
    };

    const submitValue = () => {
        const newDate = new Date(value);
        if (isValid(newDate) && !disabled) {
            onChange(value);
        }
    };

    const renderAct = () => {
        return (
            <div className={'flex cursor-pointer w-full align-middle'}>
                <Input
                    defaultValue={value}
                    onChange={setValue}
                    onKeyPress={handleKeyPress}
                    onBlur={submitValue}
                    placeholder={placeholder}
                />
                <CalendarIcon className={'w-6 absolute right-2 top-1'} />
            </div>
        );
    };
    return (
        <DropdownLayout activator={renderAct()} positionClassName={'left-0 origin-top-left'}>
            <StyledDateContainer className={'w-64'}>
                <DayPicker
                    mode="single"
                    disabled={disabled}
                    onSelect={(value: any) => {
                        if (!disabled) {
                            onChange(format(value, 'yyyy-MM-dd'));
                        }
                    }}
                    selected={value}
                />
            </StyledDateContainer>
        </DropdownLayout>
    );
};

export default DatePickerField;
