import React, {useEffect, useRef, useState} from 'react';
import {addDays, addMinutes, differenceInMinutes, format, getDayOfYear, isValid} from 'date-fns';
import {fromJS} from 'immutable';
import {searchActivities} from '../api/ProjectApi';
import {renderActivityIcon} from './ActivityForm';
import ActivityResizable from './ActivityResizable';
import ItemIcon from '../grid/ItemIcon';
import {ActivityStatus} from './ActivityIndicator';

export const TOTAL_MIN_PER_DAY = 1440;
export const MIN_PER_ROW = 5;

const today = new Date();

export const buildTimeValues = (minStep = 15, labelTimeFormat = 'hh:mm aaa') => {
    const timeSeries = [];
    const todayString = format(today, 'yyyy-MM-dd');
    const total = TOTAL_MIN_PER_DAY / minStep;
    let start = new Date(`${todayString}T00:00:00`);
    for (let index = 1; index <= total; index++) {
        const item = {
            label: format(start, labelTimeFormat),
            value: format(start, 'HH:mm:ss'),
            distanceToNow: differenceInMinutes(start, today),
            start,
        };
        timeSeries.push(item);
        start = addMinutes(start, minStep);
    }

    return timeSeries;
};

const isOverdueActivity = (activity) => {
    const start = new Date(
        `${activity.getIn(['event', 'data', 'start'])}T${activity.getIn([
            'event',
            'data',
            'startTime',
        ])}`
    );
    const startInDay = isValid(start) ? getDayOfYear(start) : 0;
    const todayInDay = getDayOfYear(today);
    return startInDay < todayInDay;
};

const getColor = (activity, filter) => {
    const eventId = activity.get('id') || 'new';
    let color = eventId == 'new' ? 'gray' : 'blue';
    const eventStatus = activity.getIn(['event', 'data', 'done']) || false;
    if (eventStatus) {
        color = 'blue';
    } else if (isOverdueActivity(activity)) {
        color = 'red';
    } else if (filter == 'Tomorrow') {
        color = 'gray';
    }
    return color;
};

export const ActivityEvent = ({
    filter,
    activity,
    onRenderEvent,
    onClickItem,
    onClickEvent,
    onClickMarkAsDone,
    viewType,
}) => {
    const eventId = activity.get('id') || 'new';
    const isNewEvent = eventId == 'new';
    const startOfDay = new Date(`${activity.getIn(['event', 'data', 'start'])}T00:00:00`);
    const start = new Date(
        `${activity.getIn(['event', 'data', 'start'])}T${activity.getIn([
            'event',
            'data',
            'startTime',
        ])}`
    );
    if (!isValid(start)) {
        return null;
    }
    onRenderEvent();
    const end = new Date(
        `${activity.getIn(['event', 'data', 'end'])}T${activity.getIn([
            'event',
            'data',
            'endTime',
        ])}`
    );
    const item = activity.getIn(['event', 'data', 'item']) || fromJS({});
    const duration = Math.abs(differenceInMinutes(start, end));
    const spanNum = Math.round(duration / MIN_PER_ROW);
    const rowNum = Math.round(Math.abs(differenceInMinutes(startOfDay, start)) / MIN_PER_ROW);
    const summary = activity.getIn(['event', 'data', 'summary']) || '';
    const googleCalendarLink = activity.getIn(['event', 'data', 'googleCalendarLink']);

    const color = getColor(activity, filter);
    const handleEventResize = (resizeData) => {
        const newDuration = Math.round(
            (resizeData.newHeight * resizeData.duration) / resizeData.height
        );
        const newEndDate = addMinutes(resizeData.start, newDuration);
        const endTime = format(newEndDate, 'HH:mm');
    };

    const renderBaseView = () => {
        return (
            <div className={'flex items-center space-x-2 w-full'}>
                {!isNewEvent && (
                    <div className={'w-fit'}>
                        <ActivityStatus
                            activity={activity}
                            itemId={activity.get('itemId')}
                            onClickMarkAsDone={onClickMarkAsDone}
                        />
                    </div>
                )}
                <div className={'space-y-1 w-full'}>
                    <div className={`text-${color}-500 group-hover:text-${color}-700 w-full`}>
                        <div className={'space-x-2 flex items-center relative w-full'}>
                            <div className={'w-fit'}>
                                {isValid(start) ? format(start, 'hh:mm aa  -  MMM,dd') : ''}
                            </div>
                            <div
                                className={
                                    'flex space-x-1 items-center cursor-pointer text-gray-600'
                                }
                                onClick={() => {
                                    if (!isNewEvent) {
                                        onClickItem(item.toJS());
                                    }
                                }}
                            >
                                <ItemIcon item={item.toJS()} iconCss={'w-4 h-4'} />
                                <div className={'flex items-center truncate ...'}>
                                    {item.get('label')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`font-semibold text-${color}-700 space-x-1 inline-flex items-center w-full`}
                    >
                        <div className={''}>
                            {renderActivityIcon(activity.getIn(['event', 'data', 'activityType']))}
                        </div>
                        <span
                            onClick={() => {
                                onClickEvent(activity);
                            }}
                            className={
                                'max-w-32 flex cursor-pointer hover:text-blue-600 truncate ...'
                            }
                            rel="noreferrer"
                        >
                            {summary}
                        </span>
                    </div>
                </div>
            </div>
        );
    };
    const renderTimeEvent = () => {
        return (
            <li
                key={`activity_${activity.get('id')}`}
                className="relative mt-px flex"
                style={{gridRow: `${rowNum} / span ${spanNum}`}}
            >
                <ActivityResizable
                    color={color}
                    onResize={(dim) => {
                        handleEventResize({...dim, duration, start});
                    }}
                >
                    {renderBaseView()}
                </ActivityResizable>
            </li>
        );
    };
    const renderListEvent = () => {
        return (
            <div
                className={`rounded-sm bg-${color}-50 px-2 text-xs leading-5 hover:bg-${color}-100`}
            >
                {renderBaseView()}
            </div>
        );
    };
    if (viewType == 'time') {
        return renderTimeEvent();
    }
    return renderListEvent();
};

export default function DayView({
    height,
    filter = 'Today',
    itemId,
    scope = 'item',
    eventData,
    users = '',
    onChangeData = ({}: any) => {},
    onClickItem = ({}: any) => {},
    onClickEvent = ({}: any) => {},
    viewType = 'time',
}) {
    const container = useRef(null);
    const navTimelines = buildTimeValues(60, 'hh aa');
    const [activities, setActivities] = useState(fromJS([]));
    const [newActivity, setNewActivity] = useState(fromJS({}));
    const [initCurrentTime, setInitCurrentTime] = useState(false);

    const getPosition = (date) => {
        const currentMinute = date.getHours() * 60;
        const appHeight = container.current.scrollHeight;
        return (appHeight * currentMinute) / TOTAL_MIN_PER_DAY;
    };

    const goToCurrentTime = (date) => {
        // Set the container scroll position based on the current time.
        container.current.scrollTop = getPosition(date);
    };

    const loadActivities = () => {
        if (itemId && users != '') {
            searchActivities({
                itemId,
                scope: scope,
                eventActions: 'createActivity',
                users,
                ...buildFilter(),
            }).then((data) => {
                setActivities(fromJS(data));
            });
        } else {
            setActivities(fromJS([]));
        }
    };

    useEffect(() => {
        goToCurrentTime(new Date());
    }, [initCurrentTime]);

    useEffect(() => {
        goToCurrentTime(new Date());
    }, [filter, eventData]);

    const buildFilter = () => {
        if (filter == 'Today') {
            return {
                days: format(today, 'yyyy-MM-dd'),
                status: 'false',
            };
        }
        if (filter == 'Tomorrow') {
            return {
                days: `${format(addDays(today, 1), 'yyyy-MM-dd')}`,
                status: 'false',
            };
        }
        if (filter == 'To-do' || filter == 'Overdue') {
            return {
                days: '',
                status: 'false',
            };
        }
        if (filter == 'Done') {
            return {
                days: '',
                status: 'true',
            };
        }
    };

    useEffect(() => {
        loadActivities();
    }, [itemId, users, filter]);

    useEffect(() => {
        setNewActivity(
            fromJS({
                event: {
                    data: eventData,
                },
            })
        );
    }, [eventData]);

    const renderEvent = (activity) => {
        return (
            <ActivityEvent
                key={`event_${activity.get('id')}`}
                viewType={viewType}
                activity={activity}
                onClickItem={onClickItem}
                onClickEvent={onClickEvent}
                onRenderEvent={() => {
                    if (!initCurrentTime) {
                        setInitCurrentTime(true);
                    }
                }}
                filter={filter}
                onClickMarkAsDone={() => {
                    loadActivities();
                }}
            />
        );
    };

    const getEventItems = () => {
        if (filter == 'Overdue') {
            return activities.filter(isOverdueActivity);
        }
        return activities;
    };

    const renderEvents = () => {
        return getEventItems().map((activity) => renderEvent(activity));
    };

    if (viewType == 'list') {
        return (
            <div className={'space-y-2 px-2 py-4'} ref={container}>
                {getEventItems().map((activity) => renderEvent(activity))}
            </div>
        );
    }

    return (
        <div
            ref={container}
            className="flex flex-auto flex-col overflow-auto min-w-64"
            style={{height}}
        >
            <div className="flex w-full flex-auto">
                <div className="w-14 flex-none bg-white ring-1 ring-gray-100" />
                <div className="grid flex-auto grid-cols-1 grid-rows-1">
                    {/* Horizontal lines */}
                    <div
                        className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
                        style={{gridTemplateRows: 'repeat(48, minmax(3.5rem, 1fr))'}}
                    >
                        {navTimelines.map((timeline) => (
                            <>
                                <div>
                                    <div className="sticky left-0 -ml-14 -mt-2.5 w-16 pr-2 text-right text-xs leading-5 text-gray-400">
                                        {timeline.label}
                                    </div>
                                </div>
                                <div />
                            </>
                        ))}
                    </div>

                    {/* Events */}
                    <ol
                        className="col-start-1 col-end-2 row-start-1 grid grid-cols-1"
                        style={{
                            gridTemplateRows: '1.75rem repeat(288, minmax(0, 1fr)) auto',
                        }}
                    >
                        {renderEvent(newActivity)}
                        {renderEvents()}
                    </ol>
                </div>
            </div>
        </div>
    );
}
