import {ActionTypes} from '../constants/action-types';
import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';

const initialState = fromJS({
    shortMenu: false,
    hoveringDataItem: {},
    unreadNotification: 0,
});

export const actions = {
    [ActionTypes.setShortMenu]: {
        next(state, {payload}) {
            const newState = {...state.toJS(), shortMenu: payload};
            return fromJS(newState);
        },
    },
    [ActionTypes.hoverDataItem]: {
        next(state, {payload}) {
            const newState = {...state.toJS(), hoveringDataItem: payload};
            return fromJS(newState);
        },
    },
    [ActionTypes.countUnreadNotification]: {
        next(state, data) {
            const {payload, error, pending} = data;
            if (error) {
                return state.merge({error, pending});
            }
            if (pending) {
                return state.merge({pending});
            }
            if (payload === null) {
                return state;
            }
            const newState = {
                ...state.toJS(),
                pending: false,
                unreadNotification: payload,
            };
            return fromJS(newState);
        },
    },
};

export const layoutReducer = handleActions(actions, initialState);
