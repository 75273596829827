import {RuleItemTypes, RuleOperators} from '../../constants/action-types';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import DropdownLayout from '../../components/DropdownLayout';
import SlackChannelPicker from './SlackChannelPicker';
import {
    CircleStackIcon,
    CloudArrowUpIcon,
    CurrencyDollarIcon,
    UserIcon,
} from '@heroicons/react/24/outline';
import {getApiBaseUrl, isJiraContext} from '../../api/api';
import RuleFieldMapper from './RuleFieldMapper';
import {fromJS} from 'immutable';
import MailChimpAudiencePicker from './MailChimpAudiencePicker';
import Contact2SaleMapper from './Contact2SaleMapper';

type EventProps = {
    rule: any;
    onChange: (value: any) => void;
    value?: any;
    itemType?: string;
    inputType?: string;
    placeholder?: string;
    error?: boolean;
    readonly?: boolean;
    isNewRule?: boolean;
};

export const RuleValidation = (rule) => {
    const isJira = isJiraContext();
    switch (rule.value) {
        case 'status_changes':
        case 'assign_person':
        case 'update_column_from_to':
            return () => {
                const {data}: any = rule;
                if (data.from.value && data.to.value) {
                    return (
                        data.from.value !== '' &&
                        data.to.value !== '' &&
                        data.from.value !== data.to.value
                    );
                }
                return false;
            };
        case 'stage_changes':
        case 'person_assigned':
        case 'sale_moved_to_funnel':
        case 'move_sale_to_funnel':
        case 'change_status':
            return () => {
                const {data}: any = rule;
                if (data.to.value) {
                    return data.to.value !== '';
                }
                return false;
            };
        case 'clear_assignees':
            return () => {
                const {data}: any = rule;
                if (data.from.value) {
                    return data.from.value !== '';
                }
                return false;
            };
        case 'send_to_slack_channel':
            return () => {
                const {data}: any = rule;
                return data.channelId !== '';
            };
        case 'column_changes':
        case 'contact_column_changes':
            return () => {
                const {data}: any = rule;
                if (data.to.value) {
                    return data.to.value !== '';
                }
                return false;
            };
        case 'update_column_to':
            return () => {
                const {data}: any = rule;
                if (data.to.value) {
                    return data.fieldId !== '' && data.to.value !== '';
                }
                return false;
            };
        case 'mailchimp_sync_contact':
        case 'contact2mailchimp_sync_contact':
            return () => {
                const {data}: any = rule;
                if (data.listId) {
                    return data.listId !== '' && data.listName !== '';
                }
                return false;
            };
        case 'convert_contact_to_new_sale':
            return () => {
                const {data}: any = rule;
                if (data.mappings) {
                    const saleMappings = data.mappings['SALE'] || [];
                    if (saleMappings.length > 0) {
                        if (saleMappings[0].source.value) {
                            return true;
                        }
                    }
                }
                return false;
            };
        case 'convert_issue_to_new_sale':
        case 'convert_customer_to_contact':
            return () => isJira;

        case '':
            return () => false;
        default:
            return () => true;
    }
};

const events = [
    {
        value: 'status_changes',
        label: 'status changes',
        data: {
            type: 'SALE',
            inputType: 'SALE',
            outputType: 'SALE',
            fieldId: 'status',
            operator: RuleOperators.UPDATED_FIELD,
            description: 'When Status changes',
            from: {},
            to: {},
        },
    },
    {
        value: 'stage_changes',
        label: 'stage changes',
        data: {
            type: 'SALE',
            inputType: 'SALE',
            outputType: 'SALE',
            fieldId: 'stage',
            operator: RuleOperators.UPDATED_FIELD,
            description: 'When Stage changes',
            to: {},
        },
    },
    {
        value: 'person_assigned',
        label: 'person assigned',
        data: {
            type: 'SALE',
            inputType: 'SALE',
            outputType: 'SALE',
            fieldId: 'assignees',
            operator: RuleOperators.UPDATED_FIELD,
            description: 'When person is assigned',
            to: {},
        },
    },
    {
        value: 'sale_created',
        label: 'sale created',
        data: {
            type: 'SALE',
            inputType: 'SALE',
            outputType: 'SALE',
            operator: RuleOperators.NEW_SALE,
            description: 'When a sale is created',
        },
    },
    {
        value: 'contact_created',
        label: 'contact created',
        data: {
            type: 'CONTACT',
            inputType: 'CONTACT',
            outputType: 'CONTACT',
            operator: RuleOperators.NEW_CONTACT,
            description: 'When a contact is created',
        },
    },
    {
        value: 'contact_column_changes',
        label: 'contact field changes',
        data: {
            type: 'CONTACT',
            inputType: 'CONTACT',
            outputType: 'CONTACT',
            operator: RuleOperators.UPDATED_FIELD,
            fieldPicker: true,
            fieldId: '',
            to: {},
            description: 'When contact field changes',
        },
    },
    {
        value: 'column_changes',
        label: 'column changes',
        data: {
            type: 'SALE',
            inputType: 'SALE',
            outputType: 'SALE',
            operator: RuleOperators.UPDATED_FIELD,
            fieldPicker: true,
            fieldId: '',
            to: {},
            description: 'When columns changes',
        },
    },
    {
        value: 'sale_moved_to_funnel',
        label: 'sale moved to funnel',
        data: {
            type: 'SALE',
            inputType: 'SALE',
            outputType: 'SALE',
            fieldId: 'bucketId',
            operator: RuleOperators.UPDATED_FIELD,
            description: 'When a sale is moved to funnel',
            to: {},
        },
    },
    {
        value: 'jira:issue_created',
        label: 'issue created',
        data: {
            type: 'JIRA',
            inputType: 'JIRA_ISSUE',
            outputType: 'SALE',
            operator: RuleOperators.NEW_ISSUE,
            jql: '',
            description: 'When an issue is created',
        },
    },
    {
        value: 'user_created',
        label: 'customer created',
        data: {
            type: 'JIRA',
            inputType: 'JIRA_CUSTOMER',
            outputType: 'CONTACT',
            operator: RuleOperators.NEW_CONTACT,
            description: 'When a customer is created',
        },
    },
    {
        value: 'user_updated',
        label: 'customer updated',
        data: {
            type: 'JIRA',
            inputType: 'JIRA_CUSTOMER',
            outputType: 'CONTACT',
            operator: RuleOperators.UPDATED_CONTACT,
            description: 'When a customer is updated',
        },
    },
    {
        value: 'jsm_request',
        label: 'JSM Request',
        data: {
            type: 'JSM',
            inputType: 'JSM_REQUEST',
            outputType: 'SALE',
            description: 'When a new request from JSM',
        },
    },
    {
        value: 'hubspot_incoming_webhook',
        label: 'Incoming Hubspot Webhook',
        data: {
            type: 'HUBSPOT',
            inputType: 'HUBSPOT_CONTACT',
            outputType: 'SALE',
            webhookConnected: false,
            webhookPayload: {},
            operator: RuleOperators.UPDATED_CONTACT,
            description: 'When a webhook from Hubspot ',
        },
    },
];

const actions = [
    {
        value: 'move_sale_to_funnel',
        label: 'move sale to funnel',
        data: {
            type: 'SALE',
            inputType: 'SALE',
            outputType: 'SALE',
            operator: RuleOperators.SET_FIELD,
            fieldId: 'bucketId',
            description: 'Then set Funnel',
            to: {},
        },
    },
    {
        value: 'change_status',
        label: 'change status',
        data: {
            type: 'SALE',
            inputType: 'SALE',
            outputType: 'SALE',
            operator: RuleOperators.SET_FIELD,
            fieldId: 'status',
            description: 'Then set Status',
            to: {},
        },
    },
    {
        value: 'assign_person',
        label: 'assign person',
        data: {
            type: 'SALE',
            inputType: 'SALE',
            outputType: 'SALE',
            operator: RuleOperators.SET_FIELD,
            fieldId: 'assignees',
            description: 'Then Assign (replace)',
            from: {},
            to: {},
        },
    },
    {
        value: 'clear_assignees',
        label: 'clear assignees',
        data: {
            type: 'SALE',
            inputType: 'SALE',
            outputType: 'SALE',
            operator: RuleOperators.SET_FIELD,
            fieldId: 'assignees',
            description: 'Then unassign an assignee',
            from: {},
        },
    },
    {
        value: 'update_column_to',
        label: 'update column to',
        data: {
            type: 'SALE',
            inputType: 'SALE',
            outputType: 'SALE',
            operator: RuleOperators.SET_FIELD,
            fieldPicker: true,
            fieldId: '',
            description: 'Set column to a value',
            to: {},
        },
    },
    {
        value: 'update_column_from_to',
        label: 'update column from a value to something',
        data: {
            type: 'SALE',
            inputType: 'SALE',
            outputType: 'SALE',
            operator: RuleOperators.SET_FIELD,
            fieldPicker: true,
            fieldId: '',
            description: 'Set column from a value to something',
            from: {},
            to: {},
        },
    },
    {
        value: 'send_to_slack_channel',
        label: 'Send to Slack channel',
        data: {
            type: 'SLACK',
            inputType: 'SALE',
            outputType: 'SALE',
            operator: RuleOperators.SEND_TO_SLACK_CHANNEL,
            channelId: '',
            channelName: '',
            description: 'Send to Slack channel',
            message: '',
        },
    },
    {
        value: 'convert_contact_to_new_sale',
        label: 'convert to a sale',
        data: {
            type: 'CONTACT',
            inputType: 'CONTACT',
            outputType: 'SALE',
            mappings: {
                sale: [],
                contact: [],
                account: [],
            },
            operator: RuleOperators.CONTACT_2_NEW_SALE,
            description: 'Then convert a contact to a sale',
        },
    },
    {
        value: 'convert_issue_to_new_sale',
        label: 'convert to a sale',
        data: {
            type: 'JIRA',
            inputType: 'JIRA_ISSUE',
            outputType: 'SALE',
            operator: RuleOperators.ISSUE_2_NEW_SALE,
            description: 'Then convert an Issue to a sale',
        },
    },
    {
        value: 'convert_customer_to_contact',
        label: 'convert to a contact',
        data: {
            type: 'JIRA',
            inputType: 'JIRA_CUSTOMER',
            outputType: 'CONTACT',
            operator: RuleOperators.JIRA_CUSTOMER_2_NEW_CONTACT,
            description: 'Then convert a customer to a contact',
        },
    },
    {
        value: 'convert_hubspot_contact_to_sale',
        label: 'convert to a contact',
        data: {
            type: 'HUBSPOT',
            inputType: 'HUBSPOT_CONTACT',
            outputType: 'SALE',
            mappings: {
                sale: [],
                contact: [],
                account: [],
            },
            operator: RuleOperators.HUBSPOT_2_NEW_SALE,
            description: 'Then convert a Hubspot contact to sale/contact/account',
        },
    },
    {
        value: 'convert_jsm_request_to_sale',
        label: 'convert to a JSM request',
        data: {
            type: 'JSM',
            inputType: 'JSM_REQUEST',
            outputType: 'SALE',
            mappings: {
                sale: [],
                contact: [],
                account: [],
            },
            operator: RuleOperators.HUBSPOT_2_NEW_SALE,
            description: 'Then convert a JSM request to sale/contact/account',
        },
    },
    {
        value: 'mailchimp_sync_contact',
        label: 'Sync contact to Mailchimp',
        data: {
            type: 'MAILCHIMP',
            inputType: 'SALE',
            outputType: 'SALE',
            operator: RuleOperators.MAILCHIMP_SYNC_EMAILS,
            workflowId: '',
            workflowName: '',
            description: 'Then sync contact to Mailchimp to list #',
        },
    },
    {
        value: 'contact2mailchimp_sync_contact',
        label: 'Sync contact to Mailchimp',
        data: {
            type: 'MAILCHIMP',
            inputType: 'CONTACT',
            outputType: 'CONTACT',
            operator: RuleOperators.MAILCHIMP_SYNC_EMAILS,
            workflowId: '',
            workflowName: '',
            description: 'Then sync contact to Mailchimp to list #',
        },
    },
];

const isManagedByApp = (app) => {
    switch (app) {
        case 'convert_jsm_request_to_sale':
        case 'convert_hubspot_contact_to_sale':
        case 'jsm_request':
        case 'hubspot_incoming_webhook':
            return true;
        default:
            return false;
    }
};

function RuleItemPicker({
    rule = fromJS({}),
    onChange,
    value = {
        label: '',
        value: '',
        data: {
            operator: '',
            fieldId: '',
        },
    },
    readonly = false,
    isNewRule = false,
    itemType = RuleItemTypes.EVENT,
    inputType = '',
    placeholder,
}: EventProps) {
    const [ruleItem, setRuleItem] = useState<any>(value);

    const fields = useSelector((state: any) => state.projectReducer.getIn(['project', 'fields']));
    const ruleReducer = useSelector((state: any) => state.ruleReducer);
    const supportedFieldTypes = [
        'string',
        'bool',
        'boolean',
        'double',
        'guid',
        'datetime',
        'stringArray',
    ];

    useEffect(() => {
        const {value, data} = ruleItem;
        if (value !== '' && data.fieldId !== '') {
            onChange(ruleItem);
        }
    }, [ruleItem]);

    const isSupportedFields = (item, data) => {
        return item.get('formType') == data.type && supportedFieldTypes.includes(item.get('type'));
    };
    const renderFieldPicker = (data) => {
        if (data.type == 'SLACK') {
            return (
                <div>
                    <SlackChannelPicker
                        onChange={(channel) => {
                            setRuleItem({
                                ...ruleItem,
                                data: {
                                    ...ruleItem.data,
                                    channelId: channel.value,
                                    channelName: channel.label,
                                },
                            });
                        }}
                        channelName={data.channelName}
                    />
                </div>
            );
        }
        if (data.type == 'MAILCHIMP') {
            return (
                <div>
                    <MailChimpAudiencePicker
                        onChange={(audienceList) => {
                            setRuleItem({
                                ...ruleItem,
                                data: {
                                    ...ruleItem.data,
                                    listId: audienceList.value,
                                    listName: audienceList.label,
                                },
                            });
                        }}
                        defaultName={data.listName}
                    />
                </div>
            );
        }
        if (data.type == 'CONTACT' && itemType == RuleItemTypes.ACTION) {
            return (
                <Contact2SaleMapper
                    ruleMappings={ruleItem.data.mappings}
                    onChange={(mappings) => {
                        setRuleItem({
                            ...ruleItem,
                            data: {
                                ...ruleItem.data,
                                mappings,
                            },
                        });
                    }}
                />
            );
        }
        if (data.fieldPicker) {
            const availableFields: any = fields
                .filter((item) => isSupportedFields(item, data))
                .map((item) => ({label: item.get('label'), value: item.get('id')}))
                .toJS();
            const selectedValue = availableFields.find((item) => item.value == data.fieldId) || {
                label: 'field',
            };
            const activator = (
                <div
                    className={
                        'decoration-1 underline cursor-pointer text-blue-400 font-bold flex space-x-2'
                    }
                >
                    <div>{selectedValue?.label}</div>
                </div>
            );
            return (
                <>
                    <div className={'px-2'}>in</div>
                    <div className={'align-middle'}>
                        <DropdownLayout
                            activator={activator}
                            fullWidth={false}
                            positionClassName={'left-0 origin-top-left'}
                        >
                            <ul className={'w-fit'}>
                                {availableFields.map((item, index) => (
                                    <li
                                        className={
                                            'px-3 py-2 hover:bg-blue-400 hover:text-white cursor-pointer'
                                        }
                                        key={`field_${item.key}_${index}`}
                                        title={item.label}
                                        onClick={() => {
                                            setRuleItem({
                                                ...ruleItem,
                                                data: {
                                                    ...ruleItem.data,
                                                    fieldId: item.value,
                                                },
                                            });
                                        }}
                                    >
                                        <div className={'truncate ...'}>{item.label}</div>
                                    </li>
                                ))}
                            </ul>
                        </DropdownLayout>
                    </div>
                </>
            );
        }
    };

    const renderFieldType = (data, fieldId, placeholder) => {
        const fieldValues = ruleReducer.get(data.fieldId);
        if (fieldValues) {
            const values = ruleReducer.get(data.fieldId).toJS();
            const activator = (
                <div className={'flex'}>
                    <div className={'pr-2'}> {placeholder}</div>
                    <div
                        className={'decoration-1 underline cursor-pointer text-blue-400 font-bold'}
                    >
                        {data[fieldId]?.label || 'something'}
                    </div>
                </div>
            );
            return (
                <div className={'align-middle pl-2'}>
                    <DropdownLayout
                        activator={activator}
                        fullWidth={false}
                        positionClassName={'left-0 origin-top-left'}
                    >
                        <ul className={''}>
                            {values.map((item, index) => (
                                <li
                                    className={
                                        'px-3 py-2 hover:bg-blue-400 hover:text-white cursor-pointer'
                                    }
                                    key={`${fieldId}_${item.key}_${index}`}
                                    onClick={() => {
                                        setRuleItem({
                                            ...ruleItem,
                                            data: {
                                                ...ruleItem.data,
                                                [fieldId]: item,
                                            },
                                        });
                                    }}
                                >
                                    {item.label}
                                </li>
                            ))}
                        </ul>
                    </DropdownLayout>
                </div>
            );
        }
        return (
            <div className={'px-2'}>
                <input
                    readOnly={readonly}
                    className={
                        'w-24 px-2 block rounded-md border-0 py-1.5 text-gray-900 shadow-xs ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:ring-inset focus:ring-blue-400'
                    }
                    value={data[fieldId].value}
                    placeholder={placeholder}
                    onChange={(e) => {
                        setRuleItem({
                            ...ruleItem,
                            data: {
                                ...ruleItem.data,
                                [fieldId]: {
                                    value: e.target.value,
                                },
                            },
                        });
                    }}
                />
            </div>
        );
    };

    const renderFieldValuePicker = ({data, placeholder, fromOrTo}) => {
        if (data[fromOrTo] != undefined && data.fieldId != '') {
            return <>{renderFieldType(data, fromOrTo, placeholder)}</>;
        }
    };

    const renderRuleItem = () => {
        const {data} = ruleItem;
        return (
            <div className={'flex align-middle'}>
                {renderFieldPicker(data)}
                {renderFieldValuePicker({data, placeholder: 'from', fromOrTo: 'from'})}
                {renderFieldValuePicker({data, placeholder: 'to', fromOrTo: 'to'})}
            </div>
        );
    };
    const isEventRule = itemType == RuleItemTypes.EVENT;
    const ruleItems: any = isEventRule ? events : actions;

    const renderAppIcon = ({data = {}}: any) => {
        const appUrl = window.appUrl || '';
        if (data.type == 'SLACK') {
            return <img src={`${appUrl}/static/images/slack.svg`} className={'w-6 rounded-full'} />;
        }
        if (data.type == 'MAILCHIMP') {
            return (
                <img src={`${appUrl}/static/images/mailchimp.svg`} className={'w-6 rounded-full'} />
            );
        }
        if (data.type == 'JIRA' || data.type == 'JSM') {
            return <img src={`${appUrl}/static/images/jsm.svg`} className={'w-6 rounded-full'} />;
        }
        if (data.type == 'HUBSPOT') {
            return <img src={`${appUrl}/static/images/hubspot.png`} className={'w-6'} />;
        }
        if (data.type == 'GOOGLE') {
            if (itemType == RuleItemTypes.EVENT) {
                return <img src={`${appUrl}/static/images/gmail.png`} className={'w-6'} />;
            }
            return <CircleStackIcon className={'w-6'} />;
        }
        if (data.type == 'CSV') {
            if (itemType == RuleItemTypes.EVENT) {
                return <CloudArrowUpIcon className={'w-6'} />;
            }
            return <CircleStackIcon className={'w-6'} />;
        }
        if (data.type == 'SALE') {
            if (itemType == RuleItemTypes.EVENT) {
                return <CurrencyDollarIcon className={'w-6'} />;
            }
            return <CircleStackIcon className={'w-6'} />;
        }
        if (data.type == 'CONTACT') {
            if (itemType == RuleItemTypes.EVENT) {
                return <UserIcon className={'w-6'} />;
            }
            return <CircleStackIcon className={'w-6'} />;
        }
    };

    const renderHubspotWebhookEvent = (ruleItem: any) => {
        if (readonly) {
            return null;
        }
        if (ruleItem.value == 'hubspot_incoming_webhook') {
            const statusLabel = ruleItem.data.webhookConnected ? 'Connected' : 'Not Connected';
            const ruleId = rule.get('id') || '';
            return (
                <div>
                    <div className={'flex space-x-2'}>
                        <div className={'font-semibold'}>Webhook URL:</div>
                        <div>{`${getApiBaseUrl()}/api/webhooks/${ruleId}`}</div>
                    </div>
                    <div className={'flex space-x-2'}>
                        <div className={'font-semibold'}>Status:</div>
                        <div>{statusLabel}</div>
                    </div>
                </div>
            );
        }
        return null;
    };

    const renderHubspotWebhookAction = (ruleItem) => {
        if (readonly || isNewRule) {
            return null;
        }
        if (ruleItem.value == 'convert_hubspot_contact_to_sale') {
            const webhookPayload =
                rule.getIn(['startEvent', 'data', 'webhookPayload', 'properties']) || fromJS({});

            const webhookMappings =
                fromJS(ruleItem).getIn(['data', 'mappings']) ||
                fromJS({SALE: [], CONTACT: [], COMPANY: []});

            const hubspotFields = Object.keys(webhookPayload.toJS()).map((fieldId) => ({
                label: fieldId,
                value: fieldId,
            }));
            return (
                <RuleFieldMapper
                    readonly={true}
                    fieldMappings={webhookMappings.toJS()}
                    sourceOptions={hubspotFields}
                    onChangeMappings={(mappings) => {
                        setRuleItem({
                            ...ruleItem,
                            data: {
                                ...ruleItem.data,
                                mappings: mappings,
                            },
                        });
                    }}
                />
            );
        }
    };

    const eventActivator = (
        <div className={`decoration-1 underline cursor-pointer flex space-x-2`}>
            {renderAppIcon(ruleItem)}
            <div>{ruleItem.data!.description || placeholder}</div>
        </div>
    );

    if (readonly) {
        return eventActivator;
    }

    const getRuleItems = () => {
        return ruleItems.filter((item) => !isManagedByApp(item.value));
    };

    return (
        <div>
            <div className={'flex w-full'}>
                <div className={'w-fit'}>
                    <DropdownLayout
                        activator={eventActivator}
                        fullWidth={false}
                        positionClassName={'left-0 origin-top-left'}
                    >
                        <ul className={''}>
                            {getRuleItems().map((item, index) => {
                                const isSupported = item.data.inputType == inputType || isEventRule;
                                const itemCSS = isSupported && !readonly ? '' : 'text-gray-300';
                                const tipMessage = isSupported
                                    ? ''
                                    : 'This action is not supported.';
                                return (
                                    <li
                                        title={tipMessage}
                                        className={`px-3 py-2 hover:bg-blue-400 hover:text-white cursor-pointer ${itemCSS}`}
                                        key={`${itemType}_${item.key}_${index}`}
                                        onClick={() => {
                                            if (isSupported && !readonly) {
                                                setRuleItem(item);
                                            }
                                        }}
                                    >
                                        <div className={'flex space-x-2'}>
                                            {renderAppIcon(item)}
                                            <div className={'w-60 truncate'}>{item.label}</div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </DropdownLayout>
                    {renderHubspotWebhookEvent(ruleItem)}
                    {renderHubspotWebhookAction(ruleItem)}
                </div>
                {renderRuleItem()}
            </div>
        </div>
    );
}

export default RuleItemPicker;
