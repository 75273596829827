import {useEffect, useState} from 'react';
import {getSecurity} from '../api/WordpressApi';

const TermOfUse = () => {
    const [data, setData] = useState<any>({});
    useEffect(() => {
        getSecurity().then(setData);
    }, []);
    return (
        <div className={'relative pt-20'}>
            <div className={'mx-auto max-w-4xl'}>
                <h2 className="text-2xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                    Terms of Use
                </h2>
                <div className="py-4" dangerouslySetInnerHTML={{__html: data.content}}></div>
            </div>
        </div>
    );
};

export default TermOfUse;
