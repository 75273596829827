'use client';
import Header from './Header';
import Footer from './Footer';
import Home from './Home';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import React from 'react';
import Login from './Login';
import Privacy from './Privacy';
import TermOfUse from './TermOfUse';
import AtlassianEULA from './AtlassianEULA';
import BlogPosts from './Blogs';

function WebsiteAppLayout({children}) {
    return (
        <div className="bg-white">
            <Header />
            <main className="isolate">{children}</main>
            <Footer />
        </div>
    );
}

const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path={'/'}
                    element={
                        <WebsiteAppLayout>
                            <Home />
                        </WebsiteAppLayout>
                    }
                ></Route>
                <Route
                    path={'/app/privacy'}
                    element={
                        <WebsiteAppLayout>
                            <Privacy />
                        </WebsiteAppLayout>
                    }
                ></Route>
                <Route
                    path={'/app/term-of-service'}
                    element={
                        <WebsiteAppLayout>
                            <TermOfUse />
                        </WebsiteAppLayout>
                    }
                ></Route>
                <Route
                    path={'/app/atlassian-add-ons-eula'}
                    element={
                        <WebsiteAppLayout>
                            <AtlassianEULA />
                        </WebsiteAppLayout>
                    }
                ></Route>
                <Route
                    path={'/blogs'}
                    element={
                        <WebsiteAppLayout>
                            <BlogPosts />
                        </WebsiteAppLayout>
                    }
                ></Route>
                <Route
                    path={'/blogs/:blogId'}
                    element={
                        <WebsiteAppLayout>
                            <BlogPosts />
                        </WebsiteAppLayout>
                    }
                ></Route>
                <Route path={'/app/login'} element={<Login />}></Route>
            </Routes>
        </BrowserRouter>
    );
};
export default AppRoutes;
