import {ActionTypes} from '../constants/action-types';
import {createAction} from 'redux-actions';
import {saveLabel, deleteLabel, getLabels, updateLabel} from '../api/LabelApi';

const api = {
    [ActionTypes.saveLabel]: (label) => saveLabel(label),
    [ActionTypes.updateLabel]: (labelId, label) => updateLabel(label, label),
    [ActionTypes.deleteLabel]: (labelId) => deleteLabel(labelId),
    [ActionTypes.getLabels]: (projectId, type) => getLabels(projectId, type),
};

const actions: any = {...api};

Object.keys(actions).forEach((type) => {
    actions[type] = createAction(type, actions[type]);
});

export default actions;
