import styled from 'styled-components';
import AsyncSelect from 'react-select/async';

import {searchUsers, getUser} from '../../api/UserApi';
import Avatar from '../../components/Avatar';
import {useEffect, useState} from 'react';

const UserWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    padding: 4px;
    display: flex;
    gap: 6px;
    width: 100%;
`;

const SelectUser = ({onChange, userId}: any) => {
    const [user, setUser] = useState(undefined);
    const loadUsers = async (inputValue: string) => {
        const users = await searchUsers(inputValue);
        const options = users.map((user) => {
            const {accountId, avatarUrl, displayName} = user;
            return {value: accountId, label: displayName, avatarUrl, accountId};
        });

        return options;
    };

    useEffect(() => {
        if (userId) {
            getUser(userId).then(({data}: any) => {
                const {accountId, avatarUrl, displayName} = data;
                setUser({value: accountId, label: displayName, avatarUrl, accountId});
            });
        }
    }, [userId]);

    const promiseOptions = (inputValue: string) =>
        new Promise<any>((resolve) => {
            setTimeout(() => {
                resolve(loadUsers(inputValue));
            }, 500);
        });

    const customOptionLabel = (option: any) => {
        return (
            <UserWrapper key={option.value}>
                <Avatar avatarUrl={option.avatarUrl} />
                <span>{option.label}</span>
            </UserWrapper>
        );
    };

    return (
        <AsyncSelect
            cacheOptions
            isClearable
            isSearchable
            menuPlacement={'auto'}
            loadOptions={promiseOptions}
            formatOptionLabel={customOptionLabel}
            value={user}
            onChange={(value) => {
                setUser(value);
                onChange(value);
            }}
        />
    );
};

export default SelectUser;
