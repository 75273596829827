import {ActionTypes} from '../constants/action-types';
import {createAction} from 'redux-actions';
import {countActivities, createItemLogActivity, searchActivities} from '../api/ProjectApi';

const api = {
    [ActionTypes.createActivity]: (taskId, data) => createItemLogActivity(taskId, data),
    [ActionTypes.showActivityDialog]: (data) => ({data}),
    [ActionTypes.changeActivityData]: (fieldId, fieldValue) => ({fieldId, fieldValue}),
    [ActionTypes.searchActivities]: (query) => searchActivities(query),
    [ActionTypes.countMyDueOverActivities]: (query) => countActivities(query),
    [ActionTypes.hideActivityDialog]: () => ({}),
};

const actions: any = {...api};

Object.keys(actions).forEach((type) => {
    actions[type] = createAction(type, actions[type]);
});

export default actions;
