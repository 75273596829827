import {get, put} from './api';

export const countUnreadNotifications = async (userId: string): Promise<any> => {
    const response = await get({path: `/notifications/${userId}/count-unread`});
    return response.data;
};

export const getNotifications = async (userId: string): Promise<any> => {
    const response = await get({path: `/notifications/${userId}`});
    return response.data;
};

export const markAsRead = async (userId: string, notificationId: string): Promise<any> => {
    const response = await put({path: `/notifications/${userId}/read/${notificationId}`});
    return response.data;
};
export const markAsReadAll = async (userId: string): Promise<any> => {
    const response = await put({path: `/notifications/${userId}/read-all`});
    return response.data;
};
