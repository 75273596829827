const footerNavigation = {
    solutions: [
        {name: 'Sales', href: '#'},
        {name: 'Hiring', href: '#'},
        {name: 'Business Development', href: '#'},
        {name: 'Support', href: '#'},
        {name: 'Product Development', href: '#'},
        {name: 'Fundraising', href: '#'},
    ],
    support: [
        {
            name: 'Submit ticket',
            href: 'https://jsoftconnector.atlassian.net/servicedesk/customer/portal/1',
        },
        {
            name: 'Documentation',
            href:
                'https://jsoftconnector.atlassian.net/wiki/spaces/JS/pages/2707816463/Sale+CRM+for+JIRA+Cloud',
        },
        {name: 'Guides', href: 'https://jsoftconnector.atlassian.net/wiki/spaces/JS/overview'},
    ],
    company: [
        {name: 'About', href: 'https://jsoftware.io/'},
        {
            name: 'Blog',
            href: '/blogs',
        },
        {name: 'Jobs', href: '#'},
    ],
    legal: [
        {
            name: 'Terms of service',
            href: '/app/term-of-service',
        },
        {
            name: 'Privacy policy',
            href: '/app/privacy',
        },
        {
            name: 'Atlassian Add-ons EULA',
            href: '/app/atlassian-add-ons-eula',
        },
    ],
};
const Footer = () => {
    return (
        <footer className="relative mx-auto mt-32 max-w-7xl px-6 lg:px-8">
            <div className="border-t border-gray-900/10 py-16 sm:py-24 lg:py-32">
                <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                    <img alt="Company name" src="/static/images/logo_gray.png" className="h-9" />
                    <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 className="text-sm/6 font-semibold text-gray-900">Solutions</h3>
                                <ul role="list" className="mt-6 space-y-4">
                                    {footerNavigation.solutions.map((item) => (
                                        <li key={item.name}>
                                            <a
                                                href={item.href}
                                                className="text-sm/6 text-gray-600 hover:text-gray-900"
                                            >
                                                {item.name}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="mt-10 md:mt-0">
                                <h3 className="text-sm/6 font-semibold text-gray-900">Support</h3>
                                <ul role="list" className="mt-6 space-y-4">
                                    {footerNavigation.support.map((item) => (
                                        <li key={item.name}>
                                            <a
                                                href={item.href}
                                                className="text-sm/6 text-gray-600 hover:text-gray-900"
                                            >
                                                {item.name}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 className="text-sm/6 font-semibold text-gray-900">Company</h3>
                                <ul role="list" className="mt-6 space-y-4">
                                    {footerNavigation.company.map((item) => (
                                        <li key={item.name}>
                                            <a
                                                href={item.href}
                                                className="text-sm/6 text-gray-600 hover:text-gray-900"
                                            >
                                                {item.name}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="mt-10 md:mt-0">
                                <h3 className="text-sm/6 font-semibold text-gray-900">Legal</h3>
                                <ul role="list" className="mt-6 space-y-4">
                                    {footerNavigation.legal.map((item) => (
                                        <li key={item.name}>
                                            <a
                                                href={item.href}
                                                className="text-sm/6 text-gray-600 hover:text-gray-900"
                                            >
                                                {item.name}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
