import axios, {AxiosPromise, AxiosRequestConfig} from 'axios';

export function getMetaContent(name: string) {
    const metaTag = document.getElementsByTagName('meta').namedItem(name);
    if (metaTag) {
        return metaTag.getAttribute('content');
    }
    return '';
}

export function requests(config: AxiosRequestConfig): AxiosPromise {
    if (axios.isMock) {
        return (axios as unknown) as AxiosPromise;
    }
    return axios(config);
}
