const baseFilters = () => {
    return {
        page: 0,
        pageSize: 50,
    };
};

export const everyoneFilter = () => {
    return {
        id: 'everyone',
        name: 'Everyone',
        data: {
            ...baseFilters(),
            filters: {},
            types: ['SALE', 'CONTACT', 'COMPANY', 'ACTIVITY'],
        },
    };
};

export const userFilter = ({displayName, accountId}) => {
    return {
        id: accountId,
        name: displayName,
        data: {
            ...baseFilters(),
            filters: {
                assignees: {operator: 'is', values: [accountId]},
            },
            types: ['SALE', 'CONTACT', 'COMPANY', 'ACTIVITY'],
        },
    };
};

const allOpenDeals = () => {
    return {
        id: 'all_open_deals',
        name: 'All open deals',
        data: {
            ...baseFilters(),
            filters: {status: {operator: 'is', values: ['Open']}},
            types: ['SALE'],
        },
    };
};

const allClosedDeals = () => {
    return {
        id: 'all_closed_deals',
        name: 'All Closed deals',
        data: {
            ...baseFilters(),
            filters: {status: {operator: 'is', values: ['Closed']}},
            types: ['SALE'],
        },
    };
};

const allLostDeals = () => {
    return {
        id: 'all_lost_deals',
        name: 'All Lost deals',
        data: {
            ...baseFilters(),
            filters: {stage: {operator: 'is', values: ['Lost']}},
            types: ['SALE'],
        },
    };
};

const allWonDeals = () => {
    return {
        id: 'all_won_deals',
        name: 'All Won deals',
        data: {
            ...baseFilters(),
            filters: {stage: {operator: 'is', values: ['Won']}},
            types: ['SALE'],
        },
    };
};

export const getSystemFilters = () => {
    return [allOpenDeals(), allClosedDeals(), allLostDeals(), allWonDeals()];
};
