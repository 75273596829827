import {fromJS} from 'immutable';
export const simpleReducer = (key, additions = {}) => ({
    next(state, {payload, error, pending}) {
        if (error) {
            return state.merge({error, pending});
        }
        if (pending) {
            return state.merge({pending});
        }
        if (payload) {
            return state.merge({pending, error, ...additions, [key]: fromJS(payload)});
        }
        return state.merge({pending, error, ...additions});
    },
});
