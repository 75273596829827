import {get, post, remove} from './api';
import {SubmitRuleForm} from './NetTypes';

export const getGoals = async (project: string): Promise<any> => {
    const response = await get({path: `/goals?projectId=${project}`});
    return response.data;
};

export const saveGoal = async (rule: SubmitRuleForm): Promise<any> => {
    const response = await post({path: `/goals`, data: rule});
    return response.data;
};

export const getGoal = async (ruleId: string): Promise<any> => {
    const response = await get({path: `/goals/${ruleId}`});
    return response.data;
};

export const deleteGoal = async (ruleId: string): Promise<any> => {
    const response = await remove({path: `/goals/${ruleId}`});
    return response.data;
};
