import {get, post, put, remove} from './api';
import {Rule, SubmitRuleForm} from './NetTypes';

export const getRules = async (project: string): Promise<Rule[]> => {
    const response = await get({path: `/rules?projectId=${project}`});
    return response.data;
};

export const saveRule = async (rule: SubmitRuleForm): Promise<Rule> => {
    const response = await post({path: `/rules`, data: rule});
    return response.data;
};

export const getRule = async (ruleId: string): Promise<any> => {
    const response = await get({path: `/rules/${ruleId}`});
    return response.data;
};

export const deleteRule = async (ruleId: string): Promise<any> => {
    const response = await remove({path: `/rules/${ruleId}`});
    return response.data;
};

export const enableRule = async (ruleId: string): Promise<any> => {
    const response = await put({path: `/rules/${ruleId}/enable`, data: {}});
    return response.data;
};
export const getRuleLogs = async (ruleId: string): Promise<any> => {
    const response = await get({path: `/rules/${ruleId}/logs`});
    return response.data;
};
