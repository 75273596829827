import {ActionTypes} from '../constants/action-types';
import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';

const initialState = fromJS({
    content: [],
    message: {},
    showMessage: false,
    inboxApp: {},
    pending: false,
});
export const actions = {
    [ActionTypes.selectInboxApp]: {
        next(state, data) {
            const {payload, error, pending} = data;
            if (error) {
                return state.merge({error, pending});
            }
            if (pending) {
                return state.merge({pending});
            }
            if (payload === null) {
                return state;
            }
            const newState = {
                ...state.toJS(),
                pending: false,
                inboxApp: payload.data,
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.searchMessages]: {
        next(state, data) {
            const {payload, error, pending} = data;
            if (error) {
                return state.merge({error, pending});
            }
            if (pending) {
                return state.merge({pending});
            }
            if (payload === null) {
                return state;
            }
            const newState = {
                ...state.toJS(),
                ...payload,
                pending: false,
                showMessage: false,
            };
            return fromJS(newState);
        },
    },
    [ActionTypes.showMessageView]: {
        next(state, {payload}) {
            const newState = {...state.toJS(), message: payload, showMessage: true};
            return fromJS(newState);
        },
    },
    [ActionTypes.hideMessageView]: {
        next(state, data) {
            const newState = {...state.toJS(), message: {}, showMessage: false};
            return fromJS(newState);
        },
    },
};

export const inboxReducer = handleActions(actions, initialState);
