import {ActionTypes} from '../constants/action-types';
import {createAction} from 'redux-actions';
import {deleteGoal, getGoal, getGoals, saveGoal} from '../api/GoalApi';

const api = {
    [ActionTypes.getGoals]: (projectId) => getGoals(projectId),
    [ActionTypes.saveGoal]: (goal) => saveGoal(goal),
    [ActionTypes.deleteGoal]: (goalId) => deleteGoal(goalId),
    [ActionTypes.getGoal]: (goalId) => getGoal(goalId),
    [ActionTypes.setCurrentGoal]: (goal) => goal,
    [ActionTypes.resetGoalForm]: () => {},
};

const actions: any = {...api};

Object.keys(actions).forEach((type) => {
    actions[type] = createAction(type, actions[type]);
});

export default actions;
