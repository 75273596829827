import {deleteItemLog, downloadFileLogActivity, searchActivities} from '../api/ProjectApi';
import {fromJS} from 'immutable';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {format, formatDistanceToNow, isValid} from 'date-fns';
import Tab from '../main/component/Tab';
import {
    ArrowTrendingUpIcon,
    BuildingOffice2Icon,
    CurrencyDollarIcon,
    DocumentIcon,
    EnvelopeIcon,
    FolderIcon,
    PaperClipIcon,
    PencilIcon,
    PlusIcon,
    UsersIcon,
} from '@heroicons/react/24/outline';
import {SmallLoading} from '../main/Loading';
import ActivityAction from '../actions/ActivityAction';
import {ActivityTypes, ContentTypes} from '../constants/action-types';
import {openLink} from '../api/AppLinkApi';
import JiraTaskIcon from '../JiraTaskIcon';
import ItemLinkView from '../main/ItemLinkView';
import Collapse from '../board/components/TaskDetail/Collapse';
import ActivityList from './ActivityList';

export const formatDatetime = (dateNum) => {
    const date = new Date(dateNum * 1000);
    if (isValid(date)) {
        return formatDistanceToNow(date);
    }
    return '';
};

const getItem = (activityItem: any) => {
    return activityItem.getIn(['event', 'data', 'item']) || fromJS({});
};

export const toActivityEventData = (activityItem: any) => {
    const item = getItem(activityItem);
    const eventData = activityItem.getIn(['event', 'data']) || fromJS({});
    const assignees = eventData.get('assignees') || [];
    const attendees = eventData.get('attendees') || [];
    const description =
        activityItem.getIn(['event', 'comment']) ||
        activityItem.getIn(['event', 'data', 'description']);
    return {
        item: item.toJS(),
        assignees: assignees.join(','),
        attendees: attendees.join(','),
        organizer: eventData.get('organizer'),
        startTime: eventData.get('startTime'),
        start: eventData.get('start'),
        endTime: eventData.get('endTime'),
        end: eventData.get('end'),
        summary: eventData.get('summary'),
        activityType: eventData.get('activityType'),
        activityId: activityItem.get('id'),
        description,
        privateDescription: eventData.get('privateDescription'),
    };
};

export const ActivityTabs = {
    ACTIVITY: {
        key: 'createActivity',
        value: 'Activity',
        icon: <ArrowTrendingUpIcon className={'w-4'} />,
        groups: [ContentTypes.SALE, ContentTypes.CONTACT, ContentTypes.COMPANY],
    },
    NOTE: {
        key: 'commented',
        value: 'Notes',
        icon: <DocumentIcon className={'w-4'} />,
        groups: [ContentTypes.SALE, ContentTypes.CONTACT, ContentTypes.COMPANY],
    },
    EMAIL: {
        key: 'sendEmail',
        value: 'Emails',
        icon: <EnvelopeIcon className={'w-4'} />,
        groups: [ContentTypes.SALE, ContentTypes.CONTACT, ContentTypes.COMPANY],
    },
    TEAM: {
        key: 'team',
        value: 'Teams',
        icon: <UsersIcon className={'w-4'} />,
        groups: [ContentTypes.COMPANY],
    },
    CONTACT: {
        key: 'contact',
        value: 'Contacts',
        icon: <UsersIcon className={'w-4'} />,
        groups: [ContentTypes.SALE],
    },
    ACCOUNT: {
        key: 'account',
        value: 'Accounts',
        icon: <BuildingOffice2Icon className={'w-4'} />,
        groups: [ContentTypes.SALE],
    },
    DEAL: {
        key: 'deal',
        value: 'Deals',
        icon: <CurrencyDollarIcon className={'w-4'} />,
        groups: [ContentTypes.CONTACT, ContentTypes.COMPANY],
    },
    ISSUE: {
        key: 'issue',
        value: 'Issues',
        icon: <JiraTaskIcon className={'w-4'} />,
        groups: [ContentTypes.SALE, ContentTypes.CONTACT, ContentTypes.COMPANY],
    },
    FILE: {
        key: 'uploadFile',
        value: 'Files',
        icon: <FolderIcon className={'w-4'} />,
        groups: [ContentTypes.SALE, ContentTypes.CONTACT, ContentTypes.COMPANY],
    },
};

type ItemActivity = {
    id: number;
    date: string; // "YYYY-MM-DD"
    activity: string;
};

export const toGroupActivities = (activities) => {
    // Get today's date and start of the current week (Monday)
    const today = new Date();
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay()); // Adjust to Monday

    // Grouping logic
    const thisWeekActivities: Record<string, ItemActivity[]> = {};
    const lastActivities: Record<string, ItemActivity[]> = {};

    activities.forEach((activity) => {
        const activityDateString = format(
            new Date(activity.getIn(['event', 'createdAt']) * 1000),
            'yyyy-MM-dd'
        );
        const activityDate = new Date(activityDateString);
        if (activityDate >= startOfWeek) {
            // Group this week's activities by date
            const dateKey = activityDateString;
            if (!thisWeekActivities[dateKey]) thisWeekActivities[dateKey] = [];
            thisWeekActivities[dateKey].push(activity.toJS());
        } else {
            // Group older activities by month
            const monthKey = activityDateString.slice(0, 7); // "YYYY-MM"
            if (!lastActivities[monthKey]) lastActivities[monthKey] = [];
            lastActivities[monthKey].push(activity.toJS());
        }
    });
    return fromJS({
        thisWeek: thisWeekActivities,
        lastActivity: lastActivities,
    });
};

const ItemActivityLogs = ({
    item = fromJS({}),
    itemId,
    itemType,
    projectId,
    scope = 'item',
    showHeader = true,
    activityAction = ActivityTabs.ACTIVITY.key,
    maxHeight = 0,
}) => {
    const dispatch = useDispatch();
    const members = useSelector((state: any) => state.projectReducer.getIn(['project', 'members']));
    const activityChangedData = useSelector((state: any) =>
        state.activityReducer.get('activityChangedData')
    );
    const [itemLogs, setItemLogs] = useState(fromJS([]));
    const [activeTab, setActiveTab] = useState({
        action: activityAction,
        loading: false,
    });

    useEffect(() => {
        setActiveTab({action: activityAction, loading: false});
    }, [activityAction]);

    const loadItemLogs = () => {
        setActiveTab({
            ...activeTab,
            loading: true,
        });
        searchActivities({itemId, scope}).then((logs) => {
            setItemLogs(fromJS(logs));
            setActiveTab({
                ...activeTab,
                loading: false,
            });
        });
    };

    useEffect(() => {
        if (itemId && activityChangedData) {
            loadItemLogs();
        } else {
            setItemLogs(fromJS([]));
        }
    }, [itemId, activityChangedData]);

    const handDeleteLog = (logId) => {
        deleteItemLog(itemId, logId).then(loadItemLogs);
    };

    const handleViewLog = (activityItem: any) => {
        const eventType = activityItem.getIn(['event', 'type']);
        if (eventType == 'file') {
            const fileName = activityItem.getIn(['event', 'data', 'fileName']);
            downloadFileLogActivity(itemId, fileName).then(openLink);
        } else {
            const data = toActivityEventData(activityItem);
            dispatch(ActivityAction.showActivityDialog(data));
        }
    };

    const filteredLog = (log) => {
        if (activeTab.action === ActivityTabs.ACTIVITY.key) {
            return true;
        }
        return log.getIn(['event', 'action']) === activeTab.action;
    };

    const renderGroupActivities = () => {
        const filteredLogs = itemLogs.filter((log) => filteredLog(log));
        const groups = toGroupActivities(filteredLogs);
        const thisWeekActivities = groups.get('thisWeek');
        const lastActivities = groups.get('lastActivity');
        const thisWeekItems = [];
        thisWeekActivities.map((activities, date) => {
            thisWeekItems.push(
                <div key={date} className={'w-full'}>
                    <ActivityList
                        activities={activities}
                        dispatch={dispatch}
                        loadItemLogs={loadItemLogs}
                        handDeleteLog={handDeleteLog}
                        handleViewLog={handleViewLog}
                        members={members}
                    />
                </div>
            );
        });

        const lastItems = [];
        lastActivities.map((activities, date) => {
            const dateLabel = format(new Date(date), 'MMMM yyyy');
            if (activities.size > 0) {
                lastItems.push(
                    <Collapse title={dateLabel} expand={true}>
                        <ActivityList
                            activities={activities}
                            dispatch={dispatch}
                            loadItemLogs={loadItemLogs}
                            handDeleteLog={handDeleteLog}
                            handleViewLog={handleViewLog}
                            members={members}
                        />
                    </Collapse>
                );
            }
        });
        return (
            <div className={'w-full'}>
                {thisWeekActivities.size > 0 && (
                    <Collapse title={'This week'} expand={true}>
                        {thisWeekItems}
                    </Collapse>
                )}
                {lastItems.length > 0 && lastItems}
            </div>
        );
    };

    const buildDataKey = () => {
        switch (activeTab.action) {
            case ActivityTabs.ISSUE.key:
                return 'ISSUE';
            case ActivityTabs.TEAM.key:
            case ActivityTabs.CONTACT.key:
                return 'CONTACT';
            case ActivityTabs.DEAL.key:
                return 'SALE';
            default:
                return '';
        }
    };

    const handleAddNew = () => {
        if (activeTab.action === ActivityTabs.ACTIVITY.key) {
            dispatch(
                ActivityAction.showActivityDialog({
                    item: {
                        value: item.get('id'),
                        label: item.getIn(['data', 'name', 'value']),
                        type: item.get('type'),
                    },
                    attendees: item.getIn(['data', 'email', 'value']),
                    contactId: item.getIn(['data', 'contact', 'value', 'value']),
                    activityType: ActivityTypes.meeting,
                })
            );
        } else if (activeTab.action === ActivityTabs.NOTE.key) {
            dispatch(
                ActivityAction.showActivityDialog({
                    item: {
                        value: item.get('id'),
                        label: item.getIn(['data', 'name', 'value']),
                        type: item.get('type'),
                    },
                    attendees: item.getIn(['data', 'email', 'value']),
                    contactId: item.getIn(['data', 'contact', 'value', 'value']),
                    activityType: 'commented',
                })
            );
        } else if (activeTab.action === ActivityTabs.EMAIL.key) {
            dispatch(
                ActivityAction.showActivityDialog({
                    item: {
                        value: item.get('id'),
                        label: item.getIn(['data', 'name', 'value']),
                        type: item.get('type'),
                    },
                    attendees: item.getIn(['data', 'email', 'value']),
                    contactId: item.getIn(['data', 'contact', 'value', 'value']),
                    activityType: 'sendEmail',
                })
            );
        } else if (activeTab.action === ActivityTabs.FILE.key) {
            dispatch(
                ActivityAction.showActivityDialog({
                    item: {
                        value: item.get('id'),
                        label: item.getIn(['data', 'name', 'value']),
                        type: item.get('type'),
                    },
                    activityType: 'uploadFile',
                })
            );
        }
    };

    const buildAddNewLabel = () => {
        if (activeTab.action === ActivityTabs.ACTIVITY.key) {
            return (
                <>
                    <PlusIcon className={'w-4 hover:text-blue-600'} />
                    <div className={'w-fit'}>{'Add activity'}</div>
                </>
            );
        } else if (activeTab.action === ActivityTabs.NOTE.key) {
            return (
                <>
                    <PencilIcon className={'w-4 hover:text-blue-600'} />
                    <div className={'w-fit'}>{'Add note'}</div>
                </>
            );
        } else if (activeTab.action === ActivityTabs.EMAIL.key) {
            return (
                <>
                    <EnvelopeIcon className={'w-4 hover:text-blue-600'} />
                    <div className={'w-fit'}>{'Send email'}</div>
                </>
            );
        } else if (activeTab.action === ActivityTabs.FILE.key) {
            return (
                <>
                    <PaperClipIcon className={'w-4 hover:text-blue-600'} />
                    <div className={'w-fit'}>{'Upload file'}</div>
                </>
            );
        }
    };
    const renderActivityLogs = () => {
        if (activeTab.loading) {
            return (
                <div className={'w-full p-6'}>
                    <SmallLoading />
                </div>
            );
        }

        const dataKey = buildDataKey();
        if (dataKey !== '') {
            return (
                <ItemLinkView
                    projectId={projectId}
                    dataKey={dataKey}
                    loading={false}
                    objectId={itemId}
                    objectType={itemType}
                />
            );
        }
        return (
            <>
                <div className={'flex space-x-2 py-2 border-b border-gray-200 w-full'}>
                    <div className={'capitalize font-medium w-full'}>list</div>
                    <div className={'cursor-pointer flex space-x-2 items-center'}>
                        <div
                            className={
                                'flex items-center align-middle space-x-1 hover:text-blue-600 w-28 rounded-md pl-2 h-8'
                            }
                            onClick={handleAddNew}
                        >
                            {buildAddNewLabel()}
                        </div>
                    </div>
                </div>
                <div
                    className="w-full overflow-y-auto flex space-x-2"
                    style={{maxHeight: maxHeight}}
                >
                    {renderGroupActivities()}
                </div>
            </>
        );
    };

    const tabItems = () => {
        return Object.values(ActivityTabs).filter((item) => item.groups.includes(itemType));
    };

    const renderHeader = () => {
        if (showHeader) {
            return (
                <Tab
                    items={tabItems()}
                    value={activeTab.action}
                    onTabChange={(tab) => {
                        setActiveTab({action: tab, loading: false});
                    }}
                    fontSize={14}
                />
            );
        }
    };
    return (
        <>
            {renderHeader()}
            {renderActivityLogs()}
        </>
    );
};
export default ItemActivityLogs;
