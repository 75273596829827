import {ActionTypes} from '../constants/action-types';
import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';

const initialState = fromJS({
    tenantInfo: {},
    pending: true,
});

export const actions = {
    [ActionTypes.getBuildInfo]: {
        next(state, {payload, error, pending}) {
            if (error || payload == null || payload === undefined) {
                return state.merge({error, pending});
            }
            if (pending) {
                return state.merge({pending});
            }
            const newState = {
                ...state.toJS(),
                tenantInfo: payload,
            };
            return fromJS(newState);
        },
    },
};

export const tenant = handleActions(actions, initialState);
