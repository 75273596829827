import {useState, useEffect} from 'react';
import styled from 'styled-components';
import UserAddIcon from '../../icons/UserAdd';
import XMaskIcon from '../../icons/XMark';
import Avatar from '../../components/Avatar';
import {useSelector} from 'react-redux';
import {fromJS} from 'immutable';
import DropdownLayoutV2 from '../../components/DropdownLayoutV2';

const UserItem = styled.div`
    padding: 4px;
    display: flex;
    align-items: center;
    position: relative;
    &:hover {
        background: #edebe9;
    }

    div {
        color: rgb(51, 51, 51);
        font-weight: 400;
        font-size: 14px;
        padding: 0px 4px;
        height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: flex;
        align-items: center;
    }
`;

const Label = styled.div`
    font-weight: 700;
    font-size: 12px;
    color: #323130;
    padding: 8px;
`;

const ButtonClear = styled.div`
    position: absolute;
    right: 6px;
`;

const ButtonUser = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #edebe9;

        svg {
            color: #2563eb;
        }
    }
`;

export const InputSearch = styled.input`
    border: 1px solid #d1d5db;
    height: 32px;
    width: 100%;
    outline: none;
    padding: 5px;

    &:focus {
        border: 1px solid #2563eb;
    }
`;

const AssignTo = (props: any) => {
    const {
        assignees = fromJS([]),
        onChange,
        sizeIcon,
        label = true,
        border = false,
        positionRight = false,
        isLeftIcon = true,
        disabled = false,
    } = props;

    const [assignedUsers, setAssignedUsers] = useState([]);
    const [userList, setUserList] = useState([]);
    const members = useSelector((state: any) => state.projectReducer.getIn(['project', 'members']));

    const selectUser = (user: any) => {
        if (!assignedUsers.includes(user)) {
            assignedUsers.push(user);
            setAssignedUsers(assignedUsers);
            onChange(assignedUsers);
        }
    };

    const removeUser = (userId: string) => {
        const selectedUsers = assignedUsers.filter((item: any) => item.accountId !== userId);
        setAssignedUsers(selectedUsers);
        onChange(selectedUsers);
    };

    const isAssignedUser = (userId) => {
        const index = assignedUsers.findIndex((item) => item.accountId == userId);
        return index != -1;
    };

    const onSearch = (e: any) => {
        const {value = ''} = e.target;
        const users = members.filter((user: any) => user.get('displayName').includes(value));
        setUserList(users.toJS());
    };

    useEffect(() => {
        if (assignees.size > 0) {
            const users = members.filter((user) => assignees.includes(user.get('accountId')));
            setAssignedUsers(users.toJS());
        }
        setUserList(members.toJS());
    }, [members, assignees]);

    const renderAssignedUsers = () => {
        if (assignedUsers.length === 0) {
            return null;
        }
        return (
            <>
                <Label>Assign To</Label>
                {assignedUsers.map((item: any) => {
                    const {accountId, displayName, avatarUrl} = item;
                    return (
                        <UserItem key={accountId}>
                            <Avatar avatarUrl={avatarUrl} />
                            <div>{displayName}</div>
                            <ButtonClear
                                className="btn-clear"
                                onClick={() => removeUser(accountId)}
                            >
                                <XMaskIcon size={18} color="#31752f" />
                            </ButtonClear>
                        </UserItem>
                    );
                })}
            </>
        );
    };

    const renderSearchedUsers = () => {
        const users = userList.filter((user) => !isAssignedUser(user.accountId));
        if (users.length === 0) {
            return null;
        }
        return (
            <>
                <Label>All members</Label>
                {users.map((user: any) => {
                    const {accountId, displayName, avatarUrl} = user;
                    return (
                        <UserItem
                            className="user-item"
                            key={accountId}
                            onClick={() => selectUser(user)}
                        >
                            <Avatar avatarUrl={avatarUrl} />
                            <div>{displayName}</div>
                        </UserItem>
                    );
                })}
            </>
        );
    };

    const renderUsers = () => {
        if (assignedUsers.length == 1) {
            const user = assignedUsers[0];
            return (
                <a href="#" className="group block shrink-0">
                    <div className="flex items-center">
                        <div>
                            <img
                                className="inline-block h-6 w-6 rounded-full"
                                src={user.avatarUrl}
                                alt=""
                            />
                        </div>
                        <div className="ml-2">
                            <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                                {user.displayName}
                            </p>
                        </div>
                    </div>
                </a>
            );
        }
        return (
            <div className={'flex justify-center space-x-1'}>
                {assignedUsers.map((item: any) => (
                    <Avatar
                        key={`user_${item.accountId}`}
                        avatarUrl={item.avatarUrl}
                        displayName={item.displayName}
                    />
                ))}
            </div>
        );
    };

    const renderPlaceholder = () => {
        if (assignedUsers && assignedUsers.length > 0) {
            return renderUsers();
        }
        return (
            <>
                {isLeftIcon && <UserAddIcon size={sizeIcon || 22} />}
                <span className={'pl-1'}> {!!label && 'Assign to'}</span>
                {!isLeftIcon && (
                    <ButtonUser className="btn-user">
                        <UserAddIcon size={sizeIcon || 22} />
                    </ButtonUser>
                )}
            </>
        );
    };
    const align = positionRight ? 'justify-end' : 'justify-start';
    const borderCSS = border ? 'border border-gray-300' : '';
    const activator = (
        <div
            className={`btn-show-list-user w-full cursor-pointer flex ${align} ${borderCSS} h-9 rounded-sm items-center`}
        >
            {renderPlaceholder()}
        </div>
    );
    if (disabled) {
        return activator;
    }
    return (
        <>
            <DropdownLayoutV2 activator={activator}>
                <div className={'w-full p-2 text-left'}>
                    <InputSearch onChange={onSearch} placeholder={'Keywords'} />
                    {renderAssignedUsers()}
                    {renderSearchedUsers()}
                </div>
            </DropdownLayoutV2>
        </>
    );
};

export default AssignTo;
