import {ActionTypes} from '../constants/action-types';
import {createAction} from 'redux-actions';
import {getDashboard, saveDashboard} from '../api/DashboardApi';

const api = {
    [ActionTypes.addWidget]: (data) => {
        return data;
    },
    [ActionTypes.showWidgetSetting]: (data) => {
        return data;
    },
    [ActionTypes.updateWidgetSetting]: (data) => {
        return data;
    },
    [ActionTypes.updateWidgetLayout]: (data) => {
        return data;
    },
    [ActionTypes.hideWidgetSetting]: () => {
        return {};
    },
    [ActionTypes.deleteWidget]: (widgetId) => {
        return {widgetId};
    },
    [ActionTypes.getDashboard]: (workspaceId) => getDashboard(workspaceId),
    [ActionTypes.saveDashboard]: (workspaceId, dashboard) => saveDashboard(workspaceId, dashboard),
};

const actions: any = {...api};

Object.keys(actions).forEach((type) => {
    actions[type] = createAction(type, actions[type]);
});

export default actions;
